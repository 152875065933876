const state = {
    sticker: {
        columns: [{
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }],
        originalcolumns: [{
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }],
        extracolumns: [{
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }],
        mergecolumns: [{
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }, {
            rows: ['', '', '', '', '', '', ''],
        }],
        tempAHex: [],  //temporary added to be deleted on cancel 
        tempRHex: [],  //temporary removed to be deleted on save
        refextracol : 0,
        refextrarow : 0,
        hasChanged:false,
    }
}

const getters = {
    getTile: (state) => (col, row) => {
        //console.log("getTile",col,row,state.sticker);
        return state.sticker.columns[col - 1].rows[row - 1];
    },

    getMergeTile: (state) => (col, row) => {
        //console.log("getTile",col,row,state.sticker);
        return state.sticker.mergecolumns[col - 1].rows[row - 1];
    },

    getExtraTile: (state) => (col, row) => {
        //console.log("getTile",col,row,state.sticker);
        return state.sticker.extracolumns[col - 1].rows[row - 1];
    },

    getSticker(state) {
        return state.sticker;
    },

    getHexToDeleteOnCancel(state) {
        // console.log('TEMP hexagons to delete on cancel',state.sticker.tempAHex)
        return state.sticker.tempAHex;
    },

    getHexToDeleteOnSave(state) {
        // console.log('TEMP hexagons to delete on save',state.sticker.tempRHex)
        return state.sticker.tempRHex;
    },

    testExtraFits: (state,getters) => (col,row) => {
        const ofs = getters.getColumnOffsets;
        const dcol = col - state.refextracol;
        const drow = row - state.refextrarow;
        const doff = ofs[col] - ofs[state.refextracol];
        // console.log("Test if fits: ",col,'/',row);
        let c1 = 0, r1 = 0, maxrow = 7;
        let errorflag = false;
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                state.sticker.mergecolumns[c].rows[r] = '';
            }
        }
        for (let c = 0; c < 7; c++) {
          for (let r = 0; r < 7; r++) {
            const e = state.sticker.extracolumns[c].rows[r];
            if (e !== '') {
              c1 = c + dcol;
              r1 = r + drow - (ofs[c1] - ofs[c] - doff) / 2;
              maxrow = 7 - ofs[c1];
              // console.log('src=', c, '/', r, ' dst=', c1, '/', r1, ' base offset ', doff, ' current offset ', ofs[c1] - ofs[c], ' correction ', (ofs[c1] - ofs[c] - doff) / 2);
  
              if ((c1 < 7) && (r1 < maxrow) && (state.sticker.columns[c1].rows[r1] === '')) {
                state.sticker.mergecolumns[c + dcol].rows[r + drow - (ofs[c + dcol] - ofs[c] - doff) / 2] = e;
              } else {
                errorflag = true;
              }
            }
          }
        }
        if (errorflag) {
            for (let c = 0; c < 7; c++) {
                for (let r = 0; r < 7; r++) {
                    state.sticker.mergecolumns[c].rows[r] = '';
                }
            }
            // console.log("Sticker does not fit ",state.sticker.mergecolumns);
            return false;
        } else {
          return true;
        }
  
    },

    hasStickerChanged(state) {
        state.sticker.hasChanged = false;
        // console.log('Compare sticker ',state.sticker.columns,state.sticker.originalcolumns)
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                if (state.sticker.columns[c].rows[r] !== state.sticker.originalcolumns[c].rows[r]) state.sticker.hasChanged = true;
            }
        }
        return state.sticker.hasChanged;
    }

}

const mutations = {
    setTile(state, data) {
        // console.log('Set tile:', data);
        let c = 0;
        let r = 0;
        if ('col' in data) c = data.col;
        if ('row' in data) r = data.row;
        if ((c > 0) && (c < 8) && (r > 0) && (r < 8)) {
            state.sticker.columns[c - 1].rows[r - 1] = data.src;
        }
        state.sticker.hasChanged = !state.sticker.hasChanged;
    },
    setMergeTile(state, data) {
        // console.log('Set merge tile:', data);
        let c = 0;
        let r = 0;
        if ('col' in data) c = data.col;
        if ('row' in data) r = data.row;
        if ((c > 0) && (c < 8) && (r > 0) && (r < 8)) {
            state.sticker.mergecolumns[c - 1].rows[r - 1] = data.src;
        }
    },
    setStickerColumns(state, data) {
        // console.log('Set columns: ', data);
        state.sticker.columns = data;
        state.sticker.hasChanged = !state.sticker.hasChanged;
    },
    clearSticker(state) {
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                state.sticker.columns[c].rows[r] = '';
                state.sticker.originalcolumns[c].rows[r] = '';
            }
        }
        state.sticker.hasChanged = !state.sticker.hasChanged;
    },
    currentStickerOriginal(state) {
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                state.sticker.originalcolumns[c].rows[r] = state.sticker.columns[c].rows[r];
            }
        }
        state.sticker.hasChanged = !state.sticker.hasChanged;
    },

    addTempAHex(state, uuid) {
        // console.log("TEMP new add hexagon ", uuid);
        state.sticker.tempAHex.push(uuid);  
    },

    clearTempAHex(state) {
        // console.log("TEMP clear add hexagons ");
        state.sticker.tempAHex.length = 0;
    },
    addTempRHex(state, uuid) {
        // console.log("TEMP new remove hexagon ", uuid);
        state.sticker.tempRHex.push(uuid);  
    },

    clearTempRHex(state) {
        // console.log("TEMP clear remove hexagons ");
        state.sticker.tempRHex.length = 0;
    },

    fillExtra(state, columns) {
        let foundFirst = false;
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                state.sticker.extracolumns[c].rows[r] = columns[c].rows[r];
                if ((columns[c].rows[r] !== '') && (!foundFirst)) {
                    state.refextracol = c;
                    state.refextrarow = r;
                    foundFirst = true;
                }
            }
        }
    },
    
    setReferenceTile(state, reference) {
        state.refextracol = reference.column;
        state.refextrarow = reference.row;
    },

}


export default {
    state,
    getters,
    mutations,
}
