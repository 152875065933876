import { render, staticRenderFns } from "./AdminImages.vue?vue&type=template&id=25353ba8&scoped=true&"
import script from "./AdminImages.vue?vue&type=script&lang=js&"
export * from "./AdminImages.vue?vue&type=script&lang=js&"
import style0 from "./AdminImages.vue?vue&type=style&index=0&id=25353ba8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25353ba8",
  null
  
)

export default component.exports