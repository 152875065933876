import Vue from 'vue'
// import vuetify from '@/plugins/vuetify'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import vuetify from './plugins/vuetify'
import VueDragDrop from 'vue-drag-drop';
// import vue2Dropzone from 'vue2-dropzone';
// import 'vue2-dropzone/dist/vue2Dropzone.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {library} from '@fortawesome/fontawesome-svg-core'
// import { faCloudUpload } from '@fortawesome/free-solid-svg-icons'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
// import axios from './plugins/axios' //'axios'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import router from './router'
// import GoogleSignInButton from 'vue-google-signin-button-directive'
import VueGapi from 'vue-gapi'
// import interceptorsSetup from './helpers/interceptors/axios-interceptor'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const API_URL = process.env.API_URL || '/api/v1/';
// const API_URL = process.env.API_URL || 'http://localhost:1323/api/v1/';
// const API_URL = process.env.API_URL || 'http://192.168.149.150:1323/api/v1/';
// const API_URL = process.env.API_URL || 'https://demo.dynactionize.at/api/v1/';
const GOOGLE_CLIENTID = process.env.GOOGLE_CLIENTID || '1064701531461-12g9cja7tg5rijeanj4222qqf66siavs.apps.googleusercontent.com';

Vue.use(Toast);

Vue.use(VueGapi, {
    clientId: GOOGLE_CLIENTID,
    scope: 'https://www.googleapis.com/auth/userinfo.email',
})
// library.add(faCloudUpload);
// library.add(faUserSecret)
library.add(fas)

Vue.config.productionTip = false

// const API_URL = process.env.API_URL || 'http://localhost:1323/api/v1/';
// const API_URL = process.env.API_URL || '/api/v1/';

// interceptorsSetup();

axios.defaults.showLoader = true;

export const HTTP = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
    }
})

HTTP.interceptors.request.use(
    (request) => {
        const user = store.getters.currentUser;

        if (user) {
            const token = user.jwt

            // console.log('axios.interceptors', token);

            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
            }
        }

        request.config = {
            showToast: false, // may be overwritten in next line
            ...(request.config || {}),
            start: Date.now(),

        };

        return request;
    }, function (err) {
        return Promise.reject(err);
    });

HTTP.interceptors.response.use(
    (response) => {
        // all 2xx/3xx responses will end here
        // const now = Date.now();
        const request = response.config;
        // console.log(response, request);

        // console.info(`Api Call ${request.url} took ${now - request.config.start}ms`);

        if (request.config.requestToastId) {
            this.$toast.dismiss(request.config.requestToastId);
        }

        if (request.config.showToast && request.config.responseToast) {
            this.$toast(request.config.responseToast.title);
        }
        return response;
    },
    (error) => {
        // all 4xx/5xx responses will end here
        // console.log('response(4xx/5xx)', error.response.status)

        if (error.response.status === 401) {// && (error.response.status <= 499)) {
            // console.log('response(4xx/5xx) ==> logout');
            // this.$router.replace('/logout');
            // document.location('/logout');
            delete localStorage.token;
            delete localStorage.confirmed;
            store.dispatch('logout');
            router.push('/');
            // window.location.href = "logout";
            // this.$router.push('/logout');
        }
        return Promise.reject(error);
    }
);

Vue.use(VueAxios, HTTP)

// Vue.use(VueAxios, axios);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(Buefy)
Vue.use(VueDragDrop);
// Vue.use(vue2Dropzone);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
    // vuetify,

    // vuetify
    axios,
    router,
    store,
    // GoogleSignInButton,
    render: h => h(App)
}).$mount('#app')
