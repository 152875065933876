"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FontColor = void 0;
var core_1 = require("@tiptap/core");
require("@tiptap/extension-text-style");
exports.FontColor = core_1.Extension.create({
    name: 'fontColor',
    defaultOptions: {
        types: ['textStyle'],
    },
    addGlobalAttributes: function () {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontColor: {
                        default: null,
                        renderHTML: function (attributes) {
                            if (!attributes.fontColor) {
                                return {};
                            }
                            return {
                                style: "color: " + attributes.fontColor,
                            };
                        },
                        parseHTML: function (element) {
                            console.log('FOO', element.style);
                            return {
                                fontColor: element.style.color.replace(/['"]+/g, ''),
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands: function () {
        return {
            setFontColor: function (fontColor) { return function (_a) {
                var chain = _a.chain;
                return chain()
                    .setMark('textStyle', { fontColor: fontColor })
                    .setMark('textStyle', { textDecorationColor: fontColor })
                    .run();
            }; },
            unsetFontColor: function () { return function (_a) {
                var chain = _a.chain;
                return chain()
                    .setMark('textStyle', { fontColor: null })
                    .setMark('textStyle', { textDecorationColor: null })
                    .removeEmptyTextStyle()
                    .run();
            }; },
        };
    },
});

