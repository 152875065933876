const state = {
    hexagon: {
        textfields: [{
            content: '<p>Field1</p>',
        }, {
            content: 'Field2',
        }, {
            content: 'Field3',
        }, {
            content: 'Field4',
        }],
        images: [{
            x: -360,
            y: -204,
            scaleX: 0.38,
            scaleY: 0.38,
            visible: false,
            src: "restricted/theme/christmas.jpg",
        }, {
            x: -260,
            y: -269.5,
            scaleX: 0.45,
            scaleY: 0.45,
            visible: false,
            src: "restricted/userimage/ad5ff7c6-25e0-48b3-9cdf-14550c76d268"  //"e27795fc-645b-451c-88f6-6ab49a3fa278"
        }],
        textmode: 0,
        bgColor: 4,
        bgOpacity: 100,
        colorScheme: "corporate",
    },
    originalhexagon: {
        textfields: [{
            content: '<p>Field1</p>',
        }, {
            content: 'Field2',
        }, {
            content: 'Field3',
        }, {
            content: 'Field4',
        }],
        images: [{
            x: -360,
            y: -204,
            scaleX: 0.38,
            scaleY: 0.38,
            visible: false,
            src: "restricted/theme/christmas.jpg",
        }, {
            x: -260,
            y: -269.5,
            scaleX: 0.45,
            scaleY: 0.45,
            visible: false,
            src: "restricted/userimage/ad5ff7c6-25e0-48b3-9cdf-14550c76d268"  //"e27795fc-645b-451c-88f6-6ab49a3fa278"
        }],
        textmode: 0,
        bgColor: 4,
        bgOpacity: 100,
        colorScheme: "corporate",
    }
}

// const state = {}

const getters = {
    // getText1(state) {
    //     return state.hexagon.textfields[0].text;
    // },

    getData(state) {
        return state.hexagon
    },

    getHexagonChanged(state) {
        // console.log('Compare: ', state.hexagon, state.originalhexagon)
        return (
            (state.hexagon.textfields[0].content !== state.originalhexagon.textfields[0].content) ||
            (state.hexagon.textfields[1].content !== state.originalhexagon.textfields[1].content) ||
            (state.hexagon.textfields[2].content !== state.originalhexagon.textfields[2].content) ||
            (state.hexagon.textfields[3].content !== state.originalhexagon.textfields[3].content) ||
            (state.hexagon.images[0].x !== state.originalhexagon.images[0].x) ||
            (state.hexagon.images[0].y !== state.originalhexagon.images[0].y) ||
            (state.hexagon.images[0].scaleX !== state.originalhexagon.images[0].scaleX) ||
            (state.hexagon.images[0].scaleY !== state.originalhexagon.images[0].scaleY) ||
            (state.hexagon.images[0].visible !== state.originalhexagon.images[0].visible) ||
            (state.hexagon.images[0].src !== state.originalhexagon.images[0].src) ||
            (state.hexagon.images[1].x !== state.originalhexagon.images[1].x) ||
            (state.hexagon.images[1].y !== state.originalhexagon.images[1].y) ||
            (state.hexagon.images[1].scaleX !== state.originalhexagon.images[1].scaleX) ||
            (state.hexagon.images[1].scaleY !== state.originalhexagon.images[1].scaleY) ||
            (state.hexagon.images[1].visible !== state.originalhexagon.images[1].visible) ||
            (state.hexagon.images[1].src !== state.originalhexagon.images[1].src) ||
            (state.hexagon.textmode !== state.originalhexagon.textmode) ||
            (state.hexagon.bgColor !== state.originalhexagon.bgColor) ||
            (state.hexagon.bgOpacity !== state.originalhexagon.bgOpacity) ||
            (state.hexagon.colorScheme !== state.originalhexagon.colorScheme)
        )
    },

    // getBackgroundColor(state) {
    //     return state.hexagon.bgColor;
    // },
    //
    // getBackgroundOpacity(state) {
    //     return state.hexagon.bgOpacity;
    // },
}

const mutations = {
    setEditData(state, data) {
        // console.log('Set hexagon editor data:', data);
        if ('which' in data) {
            if ((data.which > 0) && (data.which < 5)) {
                if ('content' in data) state.hexagon.textfields[data.which - 1].content = data.content;
            }
        }
    },
    setImageData(state, data) {
        // console.log('Set hexagon image data:', data);
        if ('which' in data) {
            if ((data.which > 0) && (data.which < 3)) {
                if ('x' in data) state.hexagon.images[data.which - 1].x = data.x;
                if ('y' in data) state.hexagon.images[data.which - 1].y = data.y;
                if ('scaleX' in data) state.hexagon.images[data.which - 1].scaleX = data.scaleX;
                if ('scaleY' in data) state.hexagon.images[data.which - 1].scaleY = data.scaleY;
                if ('visible' in data) state.hexagon.images[data.which - 1].visible = data.visible;
                if ('src' in data) state.hexagon.images[data.which - 1].src = data.src;
            }
        }
    },
    setBackground(state, data) {
        // console.log('mutation: Set background:', data);
        if ('bgColor' in data) state.hexagon.bgColor = data.bgColor;
        if ('bgOpacity' in data) state.hexagon.bgOpacity = data.bgOpacity;
    },
    setTextmode(state, mode) {
        // console.log('Set textmode', mode);
        state.hexagon.textmode = mode;
    },
    setAll(state, data) {
        // console.log('Set all: ', data);
        state.hexagon = data
    },
    setDefault(state) {
        state.hexagon = {
            textfields: [{content: '*'}, {content: '*'}, {content: '*'}, {content: '*'}],
            images: [{x: 0, y: 0, scaleX: 1, scaleY: 1, visible: false, src: ""},
                {x: 0, y: 0, scaleX: 1, scaleY: 1, visible: false, src: ""}],
            textmode: 0, bgColor: 4, bgOpacity: 100, colorScheme: "corporate"
        }
    },

    setOriginalHexagon(state) {
        state.originalhexagon.textfields[0].content = state.hexagon.textfields[0].content;
        state.originalhexagon.textfields[1].content = state.hexagon.textfields[1].content;
        state.originalhexagon.textfields[2].content = state.hexagon.textfields[2].content;
        state.originalhexagon.textfields[3].content = state.hexagon.textfields[3].content;
        state.originalhexagon.images[0].x = state.hexagon.images[0].x;
        state.originalhexagon.images[0].y = state.hexagon.images[0].y;
        state.originalhexagon.images[0].scaleX = state.hexagon.images[0].scaleX;
        state.originalhexagon.images[0].scaleY = state.hexagon.images[0].scaleY;
        state.originalhexagon.images[0].visible = state.hexagon.images[0].visible;
        state.originalhexagon.images[0].src = state.hexagon.images[0].src;
        state.originalhexagon.images[1].x = state.hexagon.images[1].x;
        state.originalhexagon.images[1].y = state.hexagon.images[1].y;
        state.originalhexagon.images[1].scaleX = state.hexagon.images[1].scaleX;
        state.originalhexagon.images[1].scaleY = state.hexagon.images[1].scaleY;
        state.originalhexagon.images[1].visible = state.hexagon.images[1].visible;
        state.originalhexagon.images[1].src = state.hexagon.images[1].src;
        state.originalhexagon.textmode = state.hexagon.textmode;
        state.originalhexagon.bgColor = state.hexagon.bgColor;
        state.originalhexagon.bgOpacity = state.hexagon.bgOpacity;
        state.originalhexagon.colorScheme = state.hexagon.colorScheme;
    },

    setColorScheme(state, scheme) {
        // console.log('mutation: changeColorScheme', scheme.colorScheme);
        state.hexagon.colorScheme = scheme.colorScheme;
    }
}


export default {
    state,
    getters,
    mutations,
}
