<template>
  <b-container flex class="acknowledge-container d-flex align-items-center justify-content-center full-height">
    <b-card class="confirm-card">
      <!--        <span class="confirm-head roche-blue font-rochesans-bold">Datenschutzhinweis</span>-->
      <!--        <p class="confirm-message font-rochesans-light">Bitte beachten Sie bei Eingaben in Freitextfelder sowie beim-->
      <!--          Upload von Bildern die Regelungen zu Nebenwirkungsverdachtsmeldungen sowie Datenschutz, Heilmittelwerbegesetz-->
      <!--          und Antikorruption.-->
      <!--          Freitextfelder dürfen NICHT für Produktaussagen genutzt werden. Durch Bilduploads bzw. Verwendung privater-->
      <!--          Bilder bestätigen Sie, 1. Rechteinhaber(in) an diesen Bildern zu sein, 2. keine Bildrechte Dritter zu-->
      <!--          verletzen und stimmen 3. der Nutzung dieser Daten im Rahmen der Verarbeitung seitens dieses Programms zu.-->
      <!--          Sie können Ihre selbstbearbeiteten Bilder und Sticker jederzeit durch Verschieben auf das Papierkorb-Symbol-->
      <!--          löschen. Durch Ausloggen werden diese Daten dauerhaft gelöscht.</p>-->
      <!--        &lt;!&ndash;    <b-button @click="handleConfirm">Ich habe verstanden...</b-button>&ndash;&gt;-->
      <!--        <b-button class="confirm-button roche-blue-bg font-rochesans-medium" @click="handleConfirm">Ich habe den-->
      <!--          Datenschutzhinweis zur Kenntnis genommen und willige ein.-->
      <!--        </b-button>-->

      <span class="confirm-head roche-blue font-rochesans-bold">Benutzungshinweis</span>
      <p class="confirm-message font-rochesans-light">
        Die Nutzung von “Roche Greetys” erfolgt auf freiwilliger Basis. Bitte lesen Sie den nachfolgenden
        Benutzungshinweis sorgfältig durch.
      </p>
      <p class="confirm-message font-rochesans-light">
      <ul>
        <li>Bei der Nutzung von “Roche Greetys” und der durch die Anwendung generierten Materialien sind bestehende
          Urheberrechte (bspw. Bildrechte) sowie Persönlichkeitsrechte zu wahren und die Anforderungen des
          Heilmittelwerbegesetzes einzuhalten.
        </li>
        <li>
          Bitte sehen Sie von der Verwendung von Fotos, die andere Personen als Sie selbst zeigen, ab. Falls die von
          Ihnen hochgeladenen Daten andere Personen zeigen, haben Sie sicherzustellen, dass die Einwilligung der
          Personen hierzu dokumentiert vorliegt.
        </li>
        <li> “Roche Greetys” speichert und verarbeitet die von Ihnen eingegeben Daten, Bilder und persönlichen Sticker
          zum Zweck der Generierung von Bildelementen auf Basis Ihrer Einwilligung. Ein Widerruf der Einwilligung ist
          jederzeit durch das Verschieben der Elemente auf das Papierkorbsymbol möglich. Dauerhaft werden Daten im
          Papierkorb durch Ausloggen gelöscht.
        </li>
        <li> Zur Erleichterung der Handhabung und Reduzierung rechtlicher Risiken ist die Nutzung von Freitextfeldern
          innerhalb “Roche Greetys” für produkt- oder indikationsbezogene Aussagen oder für Informationen zu Studien
          nicht erlaubt.
        </li>
        <li> Die Anwendung darf ausschließlich im Kontext Ihrer Beschäftigung bei der Roche Pharma AG genutzt werden.
          Ein Gebrauch für private Zwecke ist unzulässig.

        </li>
      </ul>
      </p>

      <div class="d-flex justify-content-between">
<!--        <span>-->
        <b-checkbox class="confirm-checkbox roche-blue-bg font-rochesans-medium" id="confirm-checkbox"
                    v-model="acceptStatusCheckbox"
                    name="confirm-checkbox"
                    value="accepted"
                    unchecked-value="not_accepted"></b-checkbox>
<!--        </span>-->
<!--        <span>-->
          <b-button class="confirm-button roche-blue-bg font-rochesans-medium" :disabled='acceptStatusCheckbox !== "accepted"' @click="handleConfirm">Ich möchte “Roche
        Greetys” entsprechend nutzen und willige in die Verarbeitung meiner personenbezogener Daten (inkl. Bilder) ein.
      </b-button>
<!--        </span>-->
      </div>

    </b-card>
    <div class="logo-area">
      <b-img class="roche-logo" alt="Roche Logo" :src="require('@/assets/roche_logo_blue.svg')"/>
    </div>
  </b-container>
</template>

<script>

import {HTTP} from '@/main';

export default {
  name: "Acknowledge",
  data() {
    return {
      acceptStatusCheckbox: "not_accepted"
    }
  },
  methods: {
    handleConfirm() {

      if (this.acceptStatusCheckbox !== "accepted") {
        return
      }

      // this.$router.replace('/main')
      // console.log('handleConfirm', 'jwt', localStorage.token);


      // const config = {url: 'restricted/confirm', method: "post", responseType: "json"};
      HTTP.post(
          'restricted/confirm',
          {},
          {responseType: "json"}
      ).then(() => {
        localStorage.confirmed = true;
        this.$store.dispatch('confirm');
        this.$router.replace('/main');
      }).catch(() => {
        localStorage.confirmed = false;
        this.$router.replace('/logout');
      })
    }
  }
}
</script>

<style scoped>
.confirm-head {
  margin-top: 100px;
  font-size: 2em;
}

.confirm-message {
  font-size: 1.5em;
}

.confirm-checkbox {
  background-color: white;
  padding: 50px 30px 30px 40px;
  font-size: 1.5em;
  /*transform: scale(5);*/
}

.confirm-button {
  color: white;
  margin: 0px;
  padding: 10px 20px 10px 20px;
  /*font-weight: bolder;*/
  background-color: #0b41cd;
  font-size: 1.5em;
  /*width: 280px;*/
}

.confirm-button:hover {
  background-color: #022366;
}

.full-height {
  height: 100vh;
}

.confirm-card {
  border-radius: 10px;
  padding: 20px;
}

.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 110px;
  padding-top: 25px;
  width: 100%;
  position: absolute;
  left: 60px;
  top: 36px;
}

.roche-logo {
  height: 57px;
  background-position-y: bottom;
  background-size: contain;
  display: block;
  background-position-x: 0px;
  min-width: 83px;
}

@media screen and (max-height: 800px) {
  .roche-logo {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .acknowledge-container {
    /*margin-top: 40px;*/
    /*bottom: 60px;*/
    /*padding-top: 40px;*/
    /*position: absolute;*/
    /*top: 60px;*/
  }

  .confirm-button {
    font-size: 1em;
  }

  .confirm-message {
    font-size: 1em;
  }

  .confirm-head {
    margin-top: 40px;
    font-size: 1.5em;
  }
}
</style>