<template>
  <div>
    <div class="imageArea">
      <!--      <div class="imageAreaHeadline">{{ header }}</div>-->
      <image-upload class="image-upload" @upload-uuid="handleUploaded" @uploadStarted="uploadStarted" v-if="this.upload"
                    :url="uploadurl"></image-upload>
      <div class="flex-container">
        <div class="list-placeholder" v-if="images && images.length === 0"></div>
        <div class="img-container" v-else v-for="img in images" :key="img.id">
          <div class="centered" @click="handleImageClick(img.uuid)"></div>
          <ajax-image class="gridImage"
                      :imagesselectable="imagesselectable"
                      :imagesdraggable="imagesdraggable"
                      :imagelayer="imagelayer"
                      :image="img.uuid"
                      :class="{gridImageBG: renderBG}"
                      :imageselected="img.selected"
                      @wasFetched="imageFetched"
                      @imageDoubleClick="handleDoubleClick"
                      @imageClick="handleImageClick"
          ></ajax-image>
          <!--                      @imageClick="handleImageClick"-->

          <div class="select-overlay" v-if="img.selected" @click="handleImageClick(img.uuid)">
            <img :src="require('@/assets/checkmark2.svg')" alt="select overlay">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/main";
import AjaxImage from "@/components/AjaxImage";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "AjaxImageGrid",
  components: {
    AjaxImage,
    ImageUpload
  },
  props: {
    group: {
      default: 0,
      type: Number,
      required: true,
    },
    area: {
      default: 0,
      type: Number,
      required: true,
    },
    // header: {
    //   default: '',
    //   type: String,
    //   required: true,
    // },
    url: {
      default: '',
      type: String,
      required: true,
    },
    upload: {
      default: false,
      type: Boolean,
      required: true,
    },
    uploadurl: {
      default: '',
      type: String,
      required: true,
    },
    downloadurl: {
      default: '',
      type: String,
      required: true,
    },
    reloadflag: {
      default: false,
      type: Boolean,
      required: true,
    },
    reloadfolder: {
      default: '',
      type: String,
      required: true,
    },
    withbackground: {
      default: false,
      type: Boolean,
      required: true,
    },
    imagelayer: {
      default: 0,
      type: Number,
      required: true,
    },
    imagesdraggable: {
      default: true,
      type: Boolean,
      required: true,
    },
    imagesselectable: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      images: [],
      renderBG: false,
    }
  },
  methods: {
    handleDoubleClick(imageUUID) {
      this.$emit("imageDoubleClick", {url: imageUUID, area: this.area, group: this.group});
    },

    addImage(imageUUID) {
      // console.log('appendImage', imageUUID);

      const newImage = {
        id: this.images.length,
        uuid: imageUUID,
        selected: false,
      }

      if (!this.images) {
        this.images = new Array(newImage);
      } else {
        this.images.push(newImage);
      }

      // console.log("set do scroll for", imageUUID)
      this.doScroll = true;
    },

    removeImage(imageUUID) {
      // console.log('removeImage', imageUUID);

      for (let i = this.images.length - 1; i >= 0; i--) {

        if (this.images[i].uuid === imageUUID) {
          this.images.splice(i, 1);
        }
      }

      // console.log('removeImage', imageUUID, this.images);
    },

    imageFetched() {
      // console.log("[AjaxImageGrid::imageFetched]");
      if (this.doScroll) {
        this.$emit("scrollInFocus", true);
        this.doScroll = false;
      }
    },

    loadImages() {
      let imageURL = this.url;

      // console.log('[loadImages] url:', imageURL);


      if (!imageURL && "" !== imageURL) {
        return
      }

      HTTP.get(
          imageURL
      ).then((response) => {
        // console.log('fetch images from ' + imageURL, response.data);
        this.images = [];
        for (let i = 0; i < response.data.length; i++) {
          this.images.push({
            id: i,
            uuid: response.data[i],
            selected: false,
          })
        }
      }).catch(() => {
            console.log('failed loading the images from "' + imageURL + '"');
          }
      )
    },

    handleUploaded(imageUUID) {
      // console.log('handleUploaded', this.downloadurl + '/' + imageUUID);
      this.addImage(this.downloadurl + '/' + imageUUID);
      // console.log('handleUploaded handleUploaded AjaxImageGrid');
      this.$emit("uploadFinished");
    },

    uploadStarted() {
      this.$emit("uploadStarted");
      // console.log('uploadStarted uploadStarted AjaxImageGrid');
    },

    handleImageClick(imageUUID) {
      if (!this.imagesselectable) {
        return
      }
      // console.log('[handleImageClick]', imageUUID)

      for (let i = 0; i < this.images.length; i++) {

        if (this.images[i].uuid === imageUUID) {
          this.images[i].selected = !this.images[i].selected;
        }
      }

      this.$emit('imageSelected', imageUUID);
    },

    handleImageDeselect(imageUUID) {
      this.handleImageClick(imageUUID)

      // this.$emit('imageDeselected', imageUUID);
    },

  },

  mounted() {
    if (this.withbackground) {
      // console.log("AjaxGrind show BG in this group", this.url, this.withbackground);
      this.renderBG = true;
    }
    this.loadImages();
  },

  watch: {
    reloadflag: function (val, old) {
      if ((old !== val) && (this.reloadfolder === this.url)) {
        // console.log('We do reload');
        this.loadImages();
      }
    },
    withbackground: function (val, old) {
      if (old !== val) {
        this.renderBG = val;
      }
    },
  },
}
</script>

<style scoped>
.flex-container {
  padding: 0;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /*margin-top: 20px;*/
  /*padding-top: 20px;*/
  /*background-color: orange;*/
}

.img-container {
  height: 120px;
  width: 166px;
  text-align: center;
  margin: 5px;
  /*background-color: #007bff;*/
  /*border: 1px solid #4d3590;*/
}

.img-container .centered {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.img-container img {
  vertical-align: middle;
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
}

.gridImage {
  max-width: 160px;
  max-height: 160px;
  vertical-align: middle;
  /*border: 1px solid #4d3590;*/
}

.imageArea {
  position: relative;
  top: 0px;
}

/*.imageAreaHeadline {*/
/*  vertical-align: middle;*/
/*  !*text-align: center;*!*/
/*  !*background-color: lightgrey;*!*/
/*  padding-left: 30px;*/
/*  font-family: RocheSansLight;*/
/*  background-color: #f8f8f8;*/
/*  color: rgb(84, 79, 79);*/
/*  font-size: 16px;*/
/*  line-height: 1.3;*/
/*  font-weight: 100;*/
/*  text-transform: none;*/
/*  font-style: normal;*/
/*  text-decoration: none;*/
/*  letter-spacing: 0;*/
/*}*/

.image-upload {
  /*position: absolute;*/
  height: 30px;
  width: 385px;
  background-color: white;
  /*z-index: 2;*/
  /*background-color: goldenrod;*/
  /*margin-bottom: 20px;*/
  margin-bottom: 5px;
}

.list-placeholder {
  /*background-color: #4d3590;*/
  display: block;
  height: 110px;
  content: none;
}

.gridImageBG {
  /*background-color: #c2bab5;*/
  /*border: 5px solid #4d3590;*/

  clip-path: polygon(22.5% 0%, 77.5% 0%, 100% 50%, 77.5% 100%, 22.5% 100%, 0% 50%);
  background: repeating-linear-gradient(
      -49deg,
      #c2bab5,
      #c2bab5 10px,
      #bbb8b7 10px,
      #bbb8b7 20px
  );

  /*border: 1px solid hotpink;*/
}

.select-overlay {
  /*max-height: 100px;*/
  z-index: 5;
  /*background-color: #4d3590;*/
  /*position: absolute;*/
  /*top: 70px;*/
  height: 60px;
  /*width: 160px;*/
  vertical-align: middle;
  /*clip-path: polygon(22.5% 0%, 77.5% 0%, 100% 50%, 77.5% 100%, 22.5% 100%, 0% 50%);*/
  margin-top: -90px;
  clip-path: polygon(22.5% 0%, 77.5% 0%, 100% 50%, 77.5% 100%, 22.5% 100%, 0% 50%);
}

</style>