import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import confirmed from './confirmed'
import hexagon from './hexagon'
import sticker from './sticker'
import configuration from './configuration'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        confirmed,
        hexagon,
        sticker,
        configuration,
    }
})
