<template>
  <!--  <div>-->
  <!--    <drag :transfer-data="{ uuid: imageUUID}">-->
  <!--  <div class="image-container">-->
  <img ref="ajax-image" class="ajax-image" :src="blobUrl" @load="loaded" :draggable="imagesdraggable" alt="ajax-image"
       @dragstart="startDrag" @dblclick="handleDoubleClick" @click="handleClick" :class="{imageSelected: imageselected}"/>
  <!--     <div class="select-overlay" v-if="selected">-->
  <!--      <img :src="require('@/assets/checkmark2.svg')" alt="select overlay" @click="handleClick">-->
  <!--    </div>-->
  <!--  </div>-->
  <!--    </drag>-->
  <!--  </div>-->
</template>

<script>
import {HTTP} from "@/main";

export default {
  name: "AjaxImage",
  props: {
    image: {
      type: String,
      required: true,
    },
    imagelayer: {
      type: Number,
      required: true,
      default: 0,
    },
    imagesdraggable: {
      default: true,
      type: Boolean,
      required: true,
    },
    imagesselectable: {
      default: false,
      type: Boolean,
      required: true,
    },
    imageselected: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      blobUrl: "",
      imageUUID: "",
      isSelected: false,
    }
  },
  methods: {
    loaded() {
      // console.log('clean OBJ');
      if (this.blobUrl) URL.revokeObjectURL(this.blobUrl)
      // console.log('[img::loaded] emit!');
      this.$emit("wasFetched", true);
    },

    async load(src) {
      const config = {url: src, method: "get", responseType: "blob"}
      const response = await HTTP.request(config)
      // console.log('response: ', response.data);
      return response.data
    },

    handleDoubleClick() {
      if (!this.imagesdraggable) {
        return
      }
      this.$emit("imageDoubleClick", this.imageUUID);
    },

    handleClick() {
      if (!this.imagesselectable) {
        return
      }

      this.isSelected = !this.isSelected;

      this.$emit("imageClick", this.imageUUID);
    },

    findClickedHexagon(ofsX, ofsY) {
      let imgWidth = 1;
      let imgHeight = 1;

      if (this && this.$refs && this.$refs.axjxImage) {
        imgWidth = this.$refs.axjxImage.width ? this.$refs.axjxImage.width : 0;
        imgHeight = this.$refs.axjxImage.height ? this.$refs.axjxImage.height : 0;
      }
      // console.log('FCH image: ', imgWidth, ' x ', imgHeight);
      const sticker = this.$store.getters.getUsedSize(1, true);
      // console.log('FCH sticker: ', sticker);
      const scaleX = Math.round(sticker.width / imgWidth);
      const scaleY = Math.round(sticker.height / imgHeight);
      // console.log('FCH scale: ', scaleX, '/', scaleY);
      const ox = ofsX * scaleX + sticker.x;
      const oy = ofsY * scaleY + sticker.y;
      // console.log('FCH clickpoint: ', ox, ' x ', oy);
      const co = this.$store.getters.getColumnOffsets;
      let result = false;
      let c = 1;
      let r = 1;
      while ((c < 8) && (!result)) {
        r = 1;
        while ((r < (8 - co[c - 1])) && (!result)) {
          let pos = this.$store.getters.getHexagonPos(c, r, 1);
          //console.log('FCH pos ',pos,' col=',c,' row=',r);
          result = ((ox >= pos.x) && (ox <= (pos.x + pos.width)) && (oy >= pos.y) && (oy <= (pos.y + pos.height)));
          if (!result) r++;
        }
        if (!result) c++;
      }
      // console.log('FCH result: ', result, ' col=', c, ' row=', r);
      if (result) this.$store.commit("setReferenceTile", {column: c - 1, row: r - 1});
      return result;
    },

    async startDrag(evt) {
      // console.log('IMAGE: startDrag', event, item);

      if (!this.imagesdraggable) {
        return
      }

      // console.log("start drag", this.imageUUID);
      evt.dataTransfer.setData('uuid', this.imageUUID);
      // console.log("AjaxImage startDrag ImageLayer:", this.imagelayer)
      window.whatIsDragged = this.imageUUID.split('/')[1];
      window.imageLayer = this.imagelayer
      evt.dataTransfer.setData('imageLayer', this.imagelayer.toString());
      // console.log("[AjaxImage] Image startDrag", window.whatIsDragged, window.imageLayer);
      if (window.whatIsDragged === 'usersticker') {
        const config = {url: this.imageUUID.split('.')[0] + '.json', method: "get", responseType: "json"};
        const response = await HTTP.request(config);
        // console.log("blob: ", response.data);
        this.$store.commit('fillExtra', response.data);
        if (!this.findClickedHexagon(evt.offsetX, evt.offsetY)) {
          // console.log("FCH do not allow drag");
          evt.preventDefault();
        }
      }

    }
  },
  watch: {
    // src: {
    //   immediate: true,
    //   handler(src) {
    //     console.log('[src] loadHandler:', src);
    //     if (!src) return
    //     this.load(src).then(blob => {
    //       this.blobUrl = URL.createObjectURL(blob)
    //     })
    //   },
    // },
    image: {
      immediate: true,
      handler(src) {
        // console.log('[image] loadHandler:', src);
        if (!src) return
        this.imageUUID = src;
        this.load(src).then(blob => {
          this.blobUrl = URL.createObjectURL(blob);
//          src = this.blobUrl;
//          URL.revokeObjectURL(this.blobUrl)
        })
      }
    }
  },
}
</script>

<style scoped>
/*.ajax-image {*/
/*  max-width: 200px;*/
/*  max-height: 160px;*/
/*  vertical-align: middle;*/
/*  z-index: 1;*/
/*}*/

/*.select-overlay {*/
/*  max-width: 200px;*/
/*  max-height: 160px;*/
/*  vertical-align: middle;*/
/*  z-index: 5;*/
/*  !*position: absolute;*!*/
/*  height: 160px;*/
/*  width: 200px;*/
/*}*/

.imageSelected {
  opacity: 0.3;
  /*border: 1px solid black;*/
  /*clip-path: polygon(22.5% 0%, 77.5% 0%, 100% 50%, 77.5% 100%, 22.5% 100%, 0% 50%);*/
}

</style>