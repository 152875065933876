<template>
  <popup-modal ref="popup">
    <!--    <h2 style="margin-top: 0">{{ title }}</h2>-->
    <!--    <p>{{ message }}</p>-->
    <!--    <div class="id-form">-->
    <!--      <label class="id-label" for="value">{{ label }}</label>-->
    <!--      <input class="id-input" type="text" v-model="value" placeholder="test"/>-->
    <!--      <br><br>-->
    <!--    </div>-->
    <!--    <div class="btns">-->
    <!--      <v-btn v-show="showCancel" @click="cancel">{{ cancelButton }}</v-btn>-->
    <!--      <v-btn v-show="showOk" @click="confirm">&nbsp;</v-btn>-->
    <!--    <v-btn >&nbsp;</v-btn>-->
    <!--    </div>-->

    <b-card class="confirm-card">
      <div class="dialog-head roche-blue font-rochesans-bold">{{ title }}</div>
      <p class="dialog-message font-rochesans-light">
        {{ message }}</p>
      <!--      <div class="id-form">-->
      <!--        <label class="id-label" for="namevalue">{{ label }}</label>-->
      <!--        <input class="id-input" name="value" type="text" v-model="namevalue" placeholder="test"/>-->
      <!--        <br><br>-->
      <!--        <label class="id-label" for="format">{{ formatlabel }}</label>-->
      <!--        <select v-model="format">-->
      <!--          <option v-for="formatitem in formats" :key="formatitem" :selected="formatitem === format ? true : false">{{formatitem}}</option>-->
      <!--        </select>-->
      <!--        <br><br>-->
      <!--      </div>-->

      <b-container fluid class="form-container font-rochesans">
        <!--        <span>{{label}}</span><b-form-input v-model="namevalue" placeholder="MeinSticker"></b-form-input>-->
        <b-row class="my-1">
          <b-col sm="3">
            <label for="input-name" class="dialog-label">{{ label }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input id="input-name" size="sm" v-model="namevalue" placeholder="MeinSticker" class="dialog-input"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="3">
            <label for="input-format" class="dialog-label">{{ formatlabel }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-select id="input-format" size="sm" v-model="format" :options="formats" class="dialog-input">
<!--              <b-form-select-option v-for="formatitem in formats" :key="formatitem" :selected="formatitem === format ? true : false">{{ formatitem }}-->
<!--              </b-form-select-option>-->
            </b-form-select>
          </b-col>
        </b-row>
      </b-container>
<!--      <br>-->
      <div class="btns">
        <b-button class="bttn-cancel roche-btn roche-blue-bg font-rochesans-medium" @click="cancel">
          {{ cancelButton }}
        </b-button>
        <v-btn v-show="showOk" class="hidden-bttn"></v-btn>
        <b-button class="bttn-confirm roche-btn roche-blue-bg font-rochesans" @click="confirm">
          {{ okButton }}
        </b-button>
      </div>
    </b-card>


  </popup-modal>
</template>

<script>
import PopupModal from "@/components/PopupModal.vue";

export default {
  name: "InputDialog",

  components: {PopupModal},

  data: () => ({
    // Parameters that change depending on the type of dialog
    title: undefined,
    message: undefined,
    okButton: undefined,
    cancelButton: undefined,
    showCancel: true,
    showOk: true,
    label: '',
    namevalue: '',
    formatlabel: '',
    format: '',
    formats: [],

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.showOk) this.showOk = opts.showOk == "true";
      if (opts.showCancel) this.showCancel = opts.showCancel == "true";
      if (opts.label) this.label = opts.label;
      if (opts.namevalue) this.namevalue = opts.namevalue;
      if (opts.formatlabel) this.formatlabel = opts.formatlabel;
      if (opts.format) this.value = opts.format;
      if (opts.formats) this.formats = opts.formats

      this.$refs.popup.open();

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.$refs.popup.close();
      this.resolvePromise({name: this.namevalue, format: this.format});
    },

    cancel() {
      this.$refs.popup.close();
      this.resolvePromise({});
    },

    handleMyRefs(refs) {
      console.log(refs);
    }
  },
};
</script>

<style scoped>
.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.id-form {
  width: 100%;
}

.id-label {
  padding-right: 10px;
}

.id-input {
  padding: 3px;
  border-bottom: 1px solid gray;
}

.roche-btn {
  /*padding: 5px;*/
  padding: 8px 20px 10px 20px;
  margin: 0px;
  font-size: 1em;
  display: flex;
  text-align: center;
  text-decoration: none;
  height: 40px;
  color: white;
}

.roche-btn:hover {
  /*color: black;*/
  background-color: #BDE3FF;
  color: #2d3232;
}

.roche-btn:visited {
  border: 0px;
}

.roche-btn:focus {
  outline: none;
}

.dialog-head {
  font-size: 1.5em;
}

.dialog-message {
  font-size: 1em;
}

.bttn-cancel {
  background-color: #0b41cd;
}

.bttn-confirm {
  /*background-color: #022366;*/
  background-color: #0b41cd;
}

.hidden-bttn {
  width: 0px;
  height: 0px;
  opacity: 0;
}

.form-container {
  /*background-color: #4d3590;*/
  margin: 0px;
  padding: 0px;
}

.dialog-label {
  padding-top: 3px;
}

.dialog-input {
  border: 0px;
  border-bottom: 1px solid #2d3232;
  border-radius: 0;
}

</style>