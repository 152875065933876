
import Vue from 'vue'
import Router from 'vue-router'
import GoogleLogin from '@/components/GoogleLogin'
import Logout from '@/components/Logout'
import EditorMain from '@/components/EditorMain'
import Acknowledge from '@/components/Acknowledge'
import Administration from "@/components/Administration";
// import MyTiles from '@/components/MyTiles'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'LoginGoogle',
            component: GoogleLogin
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: Acknowledge
        },
        {
            path: '/main',
            name: 'main',
            component: EditorMain
        },
        {
            path: '/admin',
            name: 'admin',
            component: Administration
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout
        },
        // {
        //     path: '/test',
        //     name: 'MyTiles',
        //     component: MyTiles
        // }
    ]
})
