import JwtDecode from 'jwt-decode'

export default class User {
    static from(token) {
        try {
            // console.log('### received JWT token: ', token)
            let obj = JwtDecode(token)
            // console.log('### decoded object', obj)
            obj.token = token;
            return new User(obj)
        } catch (_) {
            return null
        }
    }

    constructor({sub, name, email, token, admin}) {
        this.jwt = token
        this.id = sub
        this.name = name
        this.email = email
        this.admin = admin
    }

}