<template>
  <button class="font-imago toolButton">
    <div class="txt">
      <slot>Button</slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "ToolButton",
  props: {
    onClick: {
      type: Function,
      required: false,
    },
  }
}
</script>

<style scoped>
.toolButton {
  width: 130px;
  height: 50px;
  border-radius: 12px;
  border: 1px solid rgba(0, 102, 204, 1);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: rgba(0, 102, 204, 1);
  font-size: 18px;
  /*clear: both;*/
  margin-top: 5px;
}

.toolButton:hover {
  color: white;
  background-color: rgba(0, 102, 204, 1);
}

.txt {
  position: relative;
  top: 3px;
}
</style>