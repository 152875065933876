<template>
  <div ref="editormain" id="editormain" class="appspace">
    <!--    <b-container flex class="info-container d-flex align-items-center justify-content-center full-height"-->
    <!--                         style="z-index: 1000000;">-->
    <!--                   v-if="blockInteraction">-->

    <!--    </b-container>-->
    <div class="accordion-container" ref="accordionContainer">
      <div class="logo-area">
        <b-img class="roche-logo" alt="Roche Logo" :src="require('@/assets/roche_logo_blue.svg')"
               @click="handleRevert"/>
        <!--                <span v-if="showSticker">STICKER::{{ editMode }}</span>-->
        <!--                <span v-if="!showSticker">HEXAGON::{{ editMode }}</span>-->
        <!--        <span v-if="!showSticker">HEXAGON::{{ pixelRatio }}</span>-->
      </div>
      <left-accordion
          ref="accordion"
          :reload="doReload"
          @imageDoubleClick="handleDoubleClick"
          @newHexagon="handleNewHexagon"
          @uploadStarted="handleUploadStarted"
          @uploadFinished="handleUploadFinished"
      ></left-accordion>
    </div>
    <div class="topbar" ref="topbar">
      <b-row>
        <div ref="burger" class="burgerPlacement">


          <b-dropdown ref="settings" right no-caret variant="outline-light" text-decoration-none class="dropdown-icon">
            <template v-slot:button-content>
              <b-img class="roche-settings" alt="settings" :src="require('@/assets/settings.svg')"/>
            </template>
            <!--              <template v-slot:default>-->
            <!--              </template>-->
            <b-dropdown-text link v-if="showSticker">
              <!--              <template v-slot:default>-->
              <b-link @click="ddHandleSave" class="roche-dd-link"
                      active-class="roche-dd-link-active">
                Sticker-Komposition&nbsp;speichern
              </b-link>
              <!--              </template>-->
              <!--                Sticker-Komposition&nbsp;speichern-->
            </b-dropdown-text>
            <b-dropdown-text v-if="showSticker">
              <b-link class="roche-dd-link" @click="ddHandleExportClicked">
                Sticker-Komposition&nbsp;exportieren
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="showSticker">
              <b-link class="roche-dd-link" @click="ddClearSticker">
                Zurücksetzen
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="showSticker">
              <b-link class="roche-dd-link" @click="ddHandlePreview">
                Vorschau
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker && (editMode !== 1)">
              <b-link class="roche-dd-link" @click="ddHandleSave">
                Speichern
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker && (editMode !== 1)">
              <b-link class="roche-dd-link" @click="ddHandleSaveAndBack">
                Speichern&nbsp;und&nbsp;zur&uuml;ck
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker && (editMode !== 1)">
              <b-link class="roche-dd-link" @click="ddHandleSaveAs">
                Als&nbsp;Einzelsticker&nbsp;speichern
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker && (editMode === 1)">
              <b-link class="roche-dd-link" @click="ddHandleSave">
                &Auml;nderungen&nbsp;speichern
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker && (editMode === 1)">
              <b-link class="roche-dd-link" @click="ddHandleSaveAs">
                &Auml;nderungen&nbsp;als&nbsp;Kopie&nbsp;speichern
              </b-link>
            </b-dropdown-text>
            <b-dropdown-text v-if="!showSticker">
              <b-link class="roche-dd-link" @click="ddHandleRevert">
                &Auml;nderungen&nbsp;verwerfen
              </b-link>
            </b-dropdown-text>

<!--            show admin Link START-->
            <b-dropdown-divider v-if="isAdmin" class="dropdown-divider"></b-dropdown-divider>
            <b-dropdown-text  v-if="isAdmin">
              <b-link logout @click="handleAdmin" class="roche-dd-link">Administration</b-link>
            </b-dropdown-text>
            <!--            show admin Link STOP-->

            <b-dropdown-divider class="dropdown-divider"></b-dropdown-divider>
            <b-dropdown-text>
              <b-link logout @click="handleLogout" class="roche-dd-link">Abmelden</b-link>
            </b-dropdown-text>
            <!--              <b-dropdown-item logout @click="handleLogout" class="roche-dd-link">Abmelden</b-dropdown-item>-->
          </b-dropdown>


        </div>
      </b-row>
      <b-row>
        <b-img class="roche-greetys" alt="RocheGreetys" :src="require('@/assets/lg_rochegreetys_1130_h36.png')"/>
      </b-row>


      <b-row>
        <div class="tool_area" ref="toolArea">
          <div class="tool_bar" ref="toolBar">
            <div class="spacer"></div>
            <b-link v-if="showSticker" class="roche-btn" @click="handleSave">
              Sticker-Komposition&nbsp;speichern
            </b-link>
            <div v-if="showSticker" class="spacer"></div>
            <b-link class="roche-btn" v-if="showSticker" @click="handleExportClicked">
              Sticker-Komposition&nbsp;exportieren
            </b-link>
            <div v-if="showSticker" class="spacer"></div>
            <b-link class="roche-btn" v-if="showSticker" @click="clearSticker">
              Zurücksetzen
            </b-link>
            <div v-if="showSticker" class="spacer"></div>
            <b-link class="roche-btn" v-if="showSticker" @click="handlePreview">
              Vorschau
            </b-link>

            <!--            // Buttons for HexagonEditor-->
            <b-link v-if="!showSticker && (editMode !== 1)" class="roche-btn"
                    @click="handleSave">Speichern
            </b-link>
            <div v-if="!showSticker" class="spacer"></div>
            <b-link class="roche-btn" v-if="!showSticker && (editMode !== 1)"
                    @click="handleSaveAndBack">Speichern&nbsp;und&nbsp;zurück
            </b-link>
            <div class="spacer"></div>
            <b-link class="roche-btn" v-if="!showSticker && (editMode !== 1)"
                    @click="handleSaveAs">Als&nbsp;Einzelsticker&nbsp;speichern
            </b-link>
            <b-link class="roche-btn" v-if="!showSticker && (editMode === 1)"
                    @click="handleSave">&Auml;nderungen&nbsp;speichern
            </b-link>
            <b-link class="roche-btn" v-if="!showSticker && (editMode === 1)"
                    @click="handleSaveAs">&Auml;nderungen&nbsp;als&nbsp;Kopie&nbsp;speichern
            </b-link>

            <!--            <v-btn class="roche-btn"  v-else-if="stickerUUID != ''"-->
            <!--                   @click="handleSaveAs">Kopie speichern-->
            <!--            </v-btn>-->
            <div v-if="stickerUUID !== '' || !showSticker" class="spacer"></div>

            <b-link v-if="!showSticker" class="roche-btn"
                    @click="handleRevert">&Auml;nderungen&nbsp;verwerfen
            </b-link>

          </div>
        </div>
      </b-row>
    </div>
    <div ref="contentArea" class="content-area" @loadJSON="handleLoadJSON">
      <div ref="stickerView" v-if="showSticker" class="sticker-view">
        <div ref="stickerTiles" class="sticker-position">
          <sticker
              ref="sticker"
              @showEditor="handleShowEditor"
              @loadSticker="handleLoadSticker"
          ></sticker>
        </div>
        <!--        <div ref="trashcan" class="trashcan"></div>-->
        <div ref="trashcan" class="editor-trashcan">
          <trash
              @deleteImage="confirmedDeleteImage"
              @removeFromSticker="removeFromSticker"
          />
        </div>
      </div>
      <div v-else ref="editorView" class="editor-view">
        <hexagon-editor
            ref="hexagonEditor"
            showBackground
            showPortrait
            showText
        />
      </div>
    </div>
    <!--    <div class="tempStage" id="tempstage"></div>-->
    <div ref="blockEvents" class="blockEvents" v-if="blockInteraction">
      <!--            <div class="blockMessage">-->
      <!--              Bitte warten.<br/>Ihre Änderungen werden gespeichert-->
      <!--            </div>-->

      <b-card class="info-card" v-if="blockInteraction && saveInteraction">
        <div class="info-message roche-blue font-rochesans-bold">Bitte warten.<br/>Ihre Änderungen werden gespeichert
        </div>
      </b-card>

      <b-card class="info-card" v-if="blockInteraction && uploadInteraction">
        <div class="info-message roche-blue font-rochesans-bold">Bitte warten.<br/>Ihre Bilddatei wird hochgeladen
        </div>
      </b-card>

    </div>

    <!--    </div>-->
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <input-dialog ref="inputDialog"></input-dialog>
  </div>
</template>


<script>
import LeftAccordion from "@/components/StickerAccordion";
// import Konva from "konva";
import Sticker from "@/components/MyTiles";
import HexagonEditor from "@/components/HexagonEditor";
// import HamburgerButton from "@/components/HamburgerButton";
import Trash from "@/components/Trash";
import {HTTP} from "@/main";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import InputDialog from "@/components/InputDialog.vue";
// import router from "@/router";
//import axios from "axios";

export default {
  components: {
    Trash,
    LeftAccordion,
    Sticker,
    HexagonEditor,
    // HamburgerButton,
    ConfirmDialog,
    InputDialog,
  },
  data() {
    return {
      isAdmin: false,
      ddVisible: false,
      saveInProgress: false,
      editMode: 0, //0 = edit Sticker Hexagon, 1 = edit User Hexagon, 2 = edit Sticker Hexagon, save User Hexagon 3 = edit User Hexagon no revert
      blockInteraction: false,
      saveInteraction: false,
      uploadInteraction: false,
      isPreview: false,
      stickerUUID: "",
      activeEditUUID: "",
      doReload: "",
      showSticker: true,
      self: null,
      accordionContainer: null,
      topbar: null,
      logo: null,
      burger: null,
      contentArea: null,
      stickerTiles: null,
      stickerView: null,
      trashcan: null,
      editorView: null,
      editorPos: null,
      toolArea: null,
      activeTileCol: 0,
      activeTileRow: 0,
      stage: null,
      canvas: null,
      configTempStage: {
        width: 3340,
        height: 3020,
      },
      configStickerImage: {
        width: 520,
        height: 400,
      },
      links: [
        {
          id: 1,
          name: "Speichern",
          handler: this.handleSave,
        },
        {
          id: 2,
          name: "Exportieren",
          handler: this.handleExportClicked,
        },
        {
          id: 3,
          name: "Abmelden",
          handler: this.handleLogout,
        },
        // {
        //   id: 4,
        //   name: 'MyImages',
        //   handler: this.handleMyImages,
        //
        // }
      ],
    };
  },
  methods: {
    handleDoubleClick(data) {
      // console.log("Image doubleclicked: ", data);
      if (data.area === 1 && data.group === 2) {
        if (this.$store.getters.getHexagonChanged) {
          this.$refs.confirmDialog.show({
            title: "Sticker wirklich verwerfen?",
            message: "Achtung! Alle nicht gespeicherten Änderungen gehen verloren.",
            okButton: "Ja, verwerfen",
            cancelButton: "Zurück",
            showCancel: "true",
            showOk: "true",
          }).then((ok) => {
            if (ok) {
              // console.log("user hexagon", data.url);
              this.editMode = 1;
              this.activeEditUUID = data.url.split(".")[0];
              this.handleLoadJSON();
            }
          });
        } else {
          // console.log("user hexagon", data.url);
          this.editMode = 1;
          this.activeEditUUID = data.url.split(".")[0];
          this.handleLoadJSON();
        }
        return;
      }
      if (data.area === 1 && data.group === 1) {
        // console.log("user sticker", data.url);
        if (this.$store.getters.hasStickerChanged) {
          this.$refs.confirmDialog
              .show({
                title: "Sticker öffnen",
                message:
                    "Es ist ein Sticker mit Änderungen gerade geöffnet. Wenn Sie fortfahren werden alle Änderungen gelöscht!",
                okButton: "Fortfahren",
                cancelButton: "Abbrechen",
                showCancel: "true",
                showOk: "true",
              })
              .then((ok) => {
                if (ok) {
                  const uuid = data.url.split("/")[2].split(".")[0];
                  this.loadStickerJson(uuid, -1, -1);
                }
              });
        } else {
          const uuid = data.url.split("/")[2].split(".")[0];
          this.loadStickerJson(uuid, -1, -1);
        }
      }
    },

    handleWindowResize() {
      // console.log("[MAIN] handleWindowResize", e);
      // this.resizeContentArea();

      // console.log(this.self);
      this.doResizeApp();
    },

    doResizeApp() {
      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;

      // if (viewportWidth < 777) {
      //   window.resizeTo(777, viewportHeight);
      //   return;
      // }

      this.self.style.width = viewportWidth - 60 + "px";
      this.self.style.height = viewportHeight + "px";

      // this.accordionContainer.style.height = viewportHeight - 500 + "px";

      this.topbar.style.width = viewportWidth - 520 + "px";

      // this.logo.style.left = viewportWidth - 520 - 247 - 70 + "px";

      this.burger.style.left = viewportWidth - 520 - 40 + "px";
      // this.logoutBtn.style.left = viewportWidth - 460 - 80 + "px";

      let contentWidth = viewportWidth - 420;
      let contentHeight = viewportHeight - 120;
      this.contentArea.style.width = contentWidth + "px";
      this.contentArea.style.height = contentHeight + "px";

      // console.log("contentArea", this.contentArea.style.width, this.contentArea.style.height);

      this.stickerView.style.width = contentWidth - 40 + "px";
      this.stickerView.style.height = contentHeight - 80 + "px";
      // console.log("Width=", contentWidth, " height=", contentHeight);

      // console.log("this.trashcan", this.trashcan);

      this.trashcan.style.left = contentWidth - 142 + "px";

      let trashcanTop = contentHeight - 108;

      if (trashcanTop < 0) {
        trashcanTop = 0;
      }

      this.trashcan.style.top = trashcanTop + "px";

      // let stickerWidth = 958;
      // let stickerHeight = 618;

      const stickerWidthBorder = 520;
      const stickerHeightBorder = 220;

      let stickerWidth = viewportWidth - stickerWidthBorder;
      let stickerHeight = viewportHeight - stickerHeightBorder;

      // console.log(viewportWidth, viewportHeight, "sticker", stickerLeft, stickerTop, stickerWidth, stickerHeight);

      let stickerLeft = parseInt((viewportWidth - stickerWidthBorder - stickerWidth) / 2);
      let stickerTop = parseInt((viewportHeight - stickerHeightBorder - stickerHeight) / 2);

      if (stickerLeft < 0) {
        stickerLeft = 0;
      }

      if (stickerTop < 0) {
        stickerTop = 0;
      }

      if (stickerWidth > contentWidth) {
        stickerWidth = contentWidth;
      }

      if (stickerHeight > contentHeight) {
        stickerHeight = contentHeight;
      }

      this.stickerTiles.style.left = stickerLeft + "px";
      this.stickerTiles.style.top = stickerTop + "px";
      this.stickerTiles.style.width = stickerWidth + "px";
      this.stickerTiles.style.height = stickerHeight + "px";

      // console.log("sticker", stickerLeft, stickerTop, stickerWidth, stickerHeight);
      if (this.$refs.sticker) {
        this.$refs.sticker.doResize();
      }

      if (!this.editorView) {
        this.editorView = this.$refs.editorView;
      }

      if (!this.editorView) {
        return;
      }

      this.editorView.style.width = contentWidth + "px";
      this.editorView.style.height = contentHeight + "px";
    },
    async serverLoadJson(src) {
      const config = {url: src, method: "get", responseType: "json"};
      const response = await HTTP.request(config);
      // console.log("blob: ", response.data);
      return response.data;
    },

    loadJson(url) {
      // console.log("JSON url = ", url);
      this.serverLoadJson(url)
          .then((json) => {
            // console.log("JSON data: ", json);
            //const data = JSON.parse(json);
            // console.log("JSON data incoming colorScheme:", json.colorScheme);
            if (json) this.$store.commit("setAll", json);
            // console.log("saved to $store", json);
            this.$store.commit("setOriginalHexagon");

            if (!this.showSticker) {
               this.$refs.hexagonEditor.reloadHexagon();
            } else {
              // console.log("hide Sticker");
              this.showSticker = false;
            }
          })
          .catch((err) => {
            console.log("load error: ", err);
          });
    },

    handleLoadJSON() {
      if (this.activeEditUUID !== "") {
        this.loadJson(this.activeEditUUID + ".json");
      } else {
        this.$store.commit("setOriginalHexagon");
      }
      // console.log("[handleLoadJSON::showSticker]", this.showSticker, " -> ", false);
      // this.showSticker = false;
    },

    handleShowEditor(value) {
      // console.log("[App] show Editor", value);
      this.activeTileCol = value.col;
      this.activeTileRow = value.row;
      this.editMode = 0;
      if (value.uuid === "" || value.uuid === "new") {
        //empty hexagon
        this.activeEditUUID = "";
        if (value.uuid === "") this.$store.commit("setDefault");
        this.showSticker = !value.show;
      } else if (value.uuid.split("/")[1] === "userstickerhexagon") {
        //user hexagon
        // console.log("user hexagon", value.uuid);
        this.activeEditUUID = value.uuid.split(".")[0];
        this.handleLoadJSON();
        //this.showSticker = !value.show;
      }
    },


    copyUserStickerHexagon(srcfile, col, row) {
      const config = {
        url: srcfile.split(".")[0] + "/userstickerhexagon",
        method: "post",
        responseType: "text",
      };
      // console.log("copy ", srcfile, " on ", col, "/", row);
      HTTP.request(config)
          .then((response) => {
            // console.log("new Id: ", response.data);
            let newFile =
                "restricted/userstickerhexagon/" +
                response.data.stickerHexagon +
                ".png";
            this.$store.commit("setMergeTile", {
              col: col,
              row: row,
              src: newFile,
            });
            this.$store.commit("setTile", {col: col, row: row, src: newFile});
            this.$store.commit("addTempAHex", newFile);
          })
          .catch((err) => {
            console.log("copy error: ", err);
          });
    },

    loadStickerJson(uuid, col, row) {
      // console.log(
      //     "StickerJSON url = ",
      //     "restricted/usersticker/" + uuid + ".json"
      // );
      this.serverLoadJson("restricted/usersticker/" + uuid + ".json")
          .then((json) => {
            // console.log("StickerJSON data: ", json);
            if (col < 0) {
              if (json) this.$store.commit("setStickerColumns", json);
              this.$store.commit("currentStickerOriginal");
              this.stickerUUID = uuid;
            } else {
              //this.$store.commit("fillExtra", json);
              //this.$store.commit("clearMergeColumns");
              if (this.$store.getters.testExtraFits(col, row)) {
                // console.log("Merge was successful");
                for (let c = 1; c < 8; c++) {
                  for (let r = 1; r < 8; r++) {
                    let tile = this.$store.getters.getMergeTile(c, r);
                    if (tile !== '') {
                      this.$store.commit("setTile", {col: c, row: r, src: tile})
                      if (tile.split("/")[1] === "userstickerhexagon")
                        this.copyUserStickerHexagon(tile, c, r);
                    }
                  }
                }
                //this.$store.commit("mergeSticker");
              }
            }

          })
          .then(() => {
            // console.log("refresh");
            if (this && this.$refs && this.$refs.sticker) {
              if (this.$refs.sticker.stickerFits) {
                this.$refs.sticker.stickerFits = false;
              }
              this.$refs.sticker.refreshSticker();
            }
          })
          .catch((err) => {
            console.log("load error: ", err);
          });
    },

    handleLoadSticker(data) {
      //this.clearSticker();
      // console.log("Load Sticker: ", data.uuid);
      this.loadStickerJson(data.uuid, data.col - 1, data.row - 1);
    },

    handleLogout() {
      this.$refs.settings.hide();
      if (this.showSticker) {
        this.clearSticker();
      }
      this.$router.replace("/logout");
    },

    cleanTempHexagons() {
      const toDelete = this.$store.getters.getHexToDeleteOnSave;
      for (let i = 0; i < toDelete.length; i++) {
        this.deleteImage(toDelete[i].split(".")[0]);
      }
      this.$store.commit("clearTempAHex");
      this.$store.commit("clearTempRHex");
    },

    ddHandleSave() {
      this.$refs.settings.hide();
      this.handleSave();
    },

    handleSave() {
      if (this.showSticker) {
        //current sticker will be saved and is the new original for changecheck
        this.$store.commit("currentStickerOriginal");
        // console.log("handle Sticker Save ", this.stickerUUID);
        const used = this.$store.getters.getUsedSize(1, false);
        const columns = this.$store.getters.getSticker.columns;
        const form = new FormData();
        // form.append("file", dataURL);
        form.append("dimensions", JSON.stringify(used));
        form.append("json", JSON.stringify(columns));
        if (this.stickerUUID !== "") {
          //console.log("Update ", this.stickerUUID);
          //const uuid = this.stickerUUID.split("/")[2];
          // console.log("update Sticker", this.stickerUUID);
          this.blockInteraction = true;
          this.saveInteraction = true;
          HTTP.patch("restricted/usersticker" + "/" + this.stickerUUID, form, {
            headers: {"Content-Type": "multipart/form-data"},
          })
              // .then((response) => {
                // console.log("update:", response);
              .then(() => {
                this.$refs.accordion.handleDeleteImage(
                    "restricted/usersticker/" + this.stickerUUID + ".png",
                    1,
                    1
                );
                this.$refs.accordion.reloadImages("restricted/userstickers", 1);
                this.blockInteraction = false;
                this.saveInteraction = false;
                this.saveInProgress = false;
                this.cleanTempHexagons();
              })
              .catch((error) => {
                console.log("upload failed", error);
                this.blockInteraction = false;
                this.saveInProgress = false;
              });
        } else {
          this.blockInteraction = true;
          this.saveInteraction = true;
          HTTP.post("restricted/usersticker", form, {
            headers: {"Content-Type": "multipart/form-data"},
          })
              .then((response) => {
                // console.log("save:", response);
                this.stickerUUID = response.data.sticker;
                this.$refs.accordion.reloadImages("restricted/userstickers", 1);
                this.cleanTempHexagons();
                this.blockInteraction = false;
                this.saveInProgress = false;
              })
              .catch((error) => {
                console.log("upload failed", error);
                this.blockInteraction = false;
                this.saveInProgress = false;
              });
        }
      } else {
        // console.log("handle Hexagon Save");
        this.saveInProgress = true;
        this.$refs.hexagonEditor.exportImage().then((file) => {
          const form = new FormData();
          const hexagonDimension = {
            w: this.$store.state.hexagonWidth,
            h: this.$store.state.hexagonHeight,
          };

          // const json = JSON.stringify(this.$store.getters.getData);
          // console.log("Save Image", file, hexagonDimension, json, JSON.stringify(hexagonDimension));
          form.append("file", file);
          form.append("json", JSON.stringify(this.$store.getters.getData));
          form.append("dimensions", JSON.stringify(hexagonDimension));
          var folder = "userstickerhexagon";
          if ((this.editMode === 1) || (this.editMode === 2)) folder = "userhexagon";
          if (this.activeEditUUID !== "") {
            // console.log("Update ", this.activeEditUUID);
            const uuid = this.activeEditUUID.split("/")[2];
            // console.log("update Hexagon", uuid);
            this.blockInteraction = true;
            this.saveInteraction = true;
            HTTP.patch("restricted/" + folder + "/" + uuid, form, {
              headers: {"Content-Type": "multipart/form-data"},
            })
                // .then((response) => {
                .then(() => {
                  // console.log("update:", response);
                  this.$refs.accordion.handleDeleteImage(
                      this.activeEditUUID + ".png",
                      1,
                      2
                  );
                  if ((this.editMode === 1) || (this.editMode === 2))
                    this.$refs.accordion.reloadImages(
                        "restricted/userhexagons",
                        0
                    );

                  this.$store.commit("setOriginalHexagon");
                  // if (this.editMode !== 0) {
                  if (this.editMode === 3) {
                    this.handleRevert();
                  }
                  this.blockInteraction = false;
                  this.saveInProgress = false;
                })
                .catch((error) => {
                  console.log("upload failed", error);
                  this.blockInteraction = false;
                  this.saveInProgress = false;
                });
          } else {
            this.blockInteraction = true;
            this.saveInteraction = true;
            HTTP.post("restricted/" + folder, form, {
              headers: {"Content-Type": "multipart/form-data"},
            })
                .then((response) => {
                  const imageuuid =
                      "restricted/" + folder + "/" + response.data.hexagon + ".png";
                  // console.log("save:", response, imageuuid, this.editMode);

                  if (this.editMode !== 2) this.activeEditUUID = imageuuid.split('.')[0];

                  if ((this.activeTileCol > 0) && (this.activeTileRow) && (this.editMode !== 2)) {
                    this.$store.commit("setTile", {
                      col: this.activeTileCol,
                      row: this.activeTileRow,
                      src: imageuuid,
                    });
                  }

                  // console.log("Edit Mode : ", this.editMode);
                  if ((this.editMode === 1) || (this.editMode === 2))
                    this.$refs.accordion.reloadImages(
                        "restricted/userhexagons",
                        0
                    );
                  if ((this.editMode === 0) || (this.editMode === 3))
                    this.$store.commit("addTempAHex", imageuuid);
                  if (this.editMode !== 2) {
                    this.$store.commit("setOriginalHexagon");
                  }
                  // if ((this.editMode === 3) || (this.editMode === 1))
                  // if ((this.editMode === 3) || (this.editMode === 1)) {
                  // if ((this.editMode !== 1) && (this.editMode !== 3)){
                  if (this.editMode === 3) {
                    this.handleRevert();
                  }
                  if ((this.editMode === 2) || (this.editMode === 3)) {
                    this.editMode = 0;
                  }

                  // if (this.editMode === 1) {
                  //   this.showSticker = false;
                  // }

                  this.blockInteraction = false;
                  this.saveInProgress = false;
                })
                .catch((error) => {
                  console.log("upload failed", error);
                  this.blockInteraction = false;
                  this.saveInProgress = false;
                });
          }
        }).catch((error) => {
          console.log("save failed", error);
          this.saveInProgress = false;
        });
        // console.log("handle Hexagon Save FN-exit");
      }
    },

    ddHandleSaveAs() {
      this.$refs.settings.hide();
      this.handleSaveAs();
    },

    handleSaveAs() {
      if (this.showSticker) {
        this.stickerUUID = "";
      } else {
        this.activeEditUUID = "";
        if (this.editMode === 0) this.editMode = 2;
      }
      this.handleSave();
    },

    ddHandleSaveAndBack() {
      this.$refs.settings.hide();
      this.handleSaveAndBack();
    },

    handleSaveAndBack() {
      // const json = JSON.stringify(this.$store.getters.getData);
      // console.log("handleSaveAndBack", json);

      this.editMode = 3;
      this.handleSave();
    },

    ddClearSticker() {
      this.$refs.settings.hide();
      this.clearSticker();
    },

    clearSticker() {
      // console.log("Check if sticker has changed");
      const change = this.$store.getters.hasStickerChanged;
      // console.log("Got has changed = ", change);
      if (change !== 0) {
        this.clearStickerConfirmed();
      } else {
        this.doClearSticker();
      }
    },

    doClearSticker() {
      this.$refs.settings.hide();

      this.stickerUUID = "";
      this.$store.commit("clearSticker");
      const toDelete = this.$store.getters.getHexToDeleteOnCancel;
      for (let i = 0; i < toDelete.length; i++) {
        this.deleteImage(toDelete[i].split(".")[0]);
      }
      this.$store.commit("clearTempAHex");
      this.$store.commit("clearTempRHex");
      // console.log("clearSticker");
      this.$refs.sticker.refreshSticker();
    },

    async clearStickerConfirmed() {
      // console.log("Dialog");
      const ok = await this.$refs.confirmDialog.show({
        title: "Kacheln löschen",
        message: "Wollen Sie die Kacheln wirklich löschen?",
        okButton: "Ja, löschen",
        cancelButton: "Nein",
        showCancel: "true",
        showOk: "true",
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.doClearSticker();
      }
    },

    ddHandleRevert() {
      this.$refs.settings.hide();
      this.handleRevert();
    },

    handleRevert() {
      // console.log("Edit Mode on revert: ", this.editMode);
      if (this.$store.getters.getHexagonChanged) {
        this.handleRevertConfirmed();
      } else {
        // console.log("[handleRevert]: activate showSticker", this.editMode);
        this.showSticker = true;
        this.activeTileCol = 0;
        this.activeTileRow = 0;
        this.doResizeApp();
      }
      // }
    },

    async handleRevertConfirmed() {
      // console.log("Dialog");
      const ok = await this.$refs.confirmDialog.show({
        title: "Sticker wirklich verwerfen?",
        message: "Achtung! Alle nicht gespeicherten Änderungen gehen verloren.",
        okButton: "Ja, verwerfen",
        cancelButton: "Zurück",
        showCancel: "true",
        showOk: "true",
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.showSticker = true;
        this.activeTileCol = 0;
        this.activeTileRow = 0;
        this.doResizeApp();
      }
    },

    ddHandleExportClicked() {
      this.$refs.settings.hide();
      this.handleExportClicked();
    },

    handleExportClicked() {
      if (this.stickerUUID === 0 || this.$store.getters.hasStickerChanged) {
        this.showExportHint();
      } else {
        this.handleExport();
      }
    },

    async showExportHint() {
      const ok = await this.$refs.confirmDialog.show({
        title: "Sticker exportieren",
        message:
            "Zum Exportieren muss der Sticker geöffnet werden und alle Änderungen müssen gespeichert sein.",
        okButton: "OK",
        cancelButton: "",
        showCancel: "false",
        showOk: "true",
      });
      return ok;
    },

    async handleExport() {

      let defaultExportFormat = this.$store.getters.getDefaultExportFormat;
      let exportFormats = this.$store.getters.getExportFormats;

      let exportDefinition = await this.$refs.inputDialog.show({
        message: "Geben Sie einen Namen und die Ausgabegröße für die Datei ein.",
        title: "Export Sticker",
        label: "Dateiname",
        cancelButton: "Abbrechen",
        okButton: "Exportieren",
        namevalue: "MeinSticker",
        formatlabel: "Größe",
        format: defaultExportFormat,
        formats: exportFormats,
      });
      if (exportDefinition !== {}) {
        let filename = exportDefinition.name.toString();
        let format = exportDefinition.format;
        const p = filename.lastIndexOf(".");
        if (p >= 0) filename = filename.substring(0, p);
        filename = filename + ".png";
        console.log(filename, " - ", this.stickerUUID);
        this.blockInteraction = true;
        this.saveInteraction = true;
        const used = this.$store.getters.getUsedSize(1, false);
        const defs = this.$store.getters.getExportDefinition;
        const json = {dimensions: used, exportDefinition: defs};
        // console.log("Export parameters: ", json);
        HTTP.post("restricted/export/usersticker/" + this.stickerUUID + "?format="+format, json, {
          headers: {"Content-Type": "application/json"},
          responseType: "blob",
        })
            .then((response) => {
              //const blob = new Blob([response.data],{type:'image/png'});
              const blobUrl = URL.createObjectURL(response.data);
              this.downloadURI(blobUrl, filename);
              this.blockInteraction = false;
            })
            .catch((err) => {
              console.log("export error: ", err);
              this.blockInteraction = false;
            });
      }
    },

    downloadURI(uri, name) {
      // console.log("Download Uri", uri, name);
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },

    async confirmedDeleteImage(uuid) {
      const typ = uuid.split('/')[1];
      let obj = 'Objekt';
      let art = 'das';
      if (typ === "usersticker") {
        obj = "Sticker";
        art = "den ";
      }
      if (typ === "userhexagon") {
        obj = "Einzelsticker";
        art = "den ";
      }
      if (typ === "userimage") {
        obj = "Bild";
        art = "das ";
      }
      const ok = await this.$refs.confirmDialog.show({
        title: obj + " löschen",
        message:
            "Wollen Sie " + art + obj + " wirklich löschen?",
        okButton: "JA",
        cancelButton: "NEIN",
        showCancel: "true",
        showOk: "true",
      });
      if (ok) this.deleteImage(uuid);

    },

    deleteImage(uuid) {
      // console.log("DeleteImage", uuid);
      const type = uuid.split("/")[1];
      /*
      if (type == "usersticker") {
        this.serverLoadJson(uuid + '.json')
            .then((columns) => {
              for (let c = 0; c < 7; c++) {
                for (let r = 0; r < 7; r++) {
                  const uri = columns[c].rows[r];
                  if (uri && (uri.split('/')[1]=='userstickerhexagon')) {
                    console.log("delete userstickerhexagon ",uri)
                    HTTP.delete(uri.split('.')[0]);
                  }
                }
                
              }
            }) .then(() => {
              HTTP.delete(uuid).then(() => {
                this.$refs.accordion.handleDeleteImage(uuid + '.png', 1, 1);
                if (this.stickerUUID == uuid.split('/')[2]) this.clearSticker();
              })
            })
            .catch((err) => {
              console.log("load error: ", err);
            });
      } else {*/
      HTTP.delete(uuid)
          .then(() => {
            // console.log("image deleted", uuid);
            if (type === "userhexagon")
              this.$refs.accordion.handleDeleteImage(uuid + ".png", 1, 2);
            if (type === "userimage")
              this.$refs.accordion.handleDeleteImage(uuid + ".png", 3, 1);
            if (type === "usersticker") {
              this.$refs.accordion.handleDeleteImage(uuid + ".png", 1, 1);
              if (this.stickerUUID === uuid.split("/")[2]) this.clearSticker();
            }
          })
          .catch((err) => {
            console.log("delete failed", err);
          });
      //}
    },

    removeFromSticker(data) {
      // console.log("Remove from sticker ", data);
      const uuid = this.$store.getters.getTile(data.col, data.row);
      if (uuid.split("/")[1] === "userstickerhexagon") {
        this.$store.commit("addTempRHex", uuid);
      }
      this.$store.commit("setTile", {col: data.col, row: data.row, src: ""});
      this.$refs.sticker.refreshSticker();
    },
    /*
    async loadStickerBlob(src) {
      const config = { url: src, method: "get", responseType: "blob" };
      const response = await HTTP.request(config);
      console.log("blob: ", response.data);
      return response.data;
    },

    async loadStickerImage(url, image) {
      console.log('get image ',url)
      const blob = await this.loadStickerBlob(url);
      console.log('gotimage',image);
      image.src = URL.createObjectURL(blob);
      if (blob) URL.revokeObjectURL(blob);
    },
*/
    ddHandlePreview() {
      this.$refs.settings.hide();
      this.handlePreview();
    },

    handlePreview() {
      this.isPreview = !this.isPreview;
      this.$refs.sticker.isPreview = this.isPreview;
      this.$refs.sticker.hideUnusedTiles(this.isPreview);
      if (this.isPreview) {
        this.$refs.sticker.setScaleAndOffset(true);
      } else {
        this.$refs.sticker.setScaleAndOffset(false);
      }
    },
    loadConfigJson() {
      // console.log("Load configuration");
      HTTP.get("restricted/config", {}, {responseType: "json"})
          .then((response) => {
            // console.log("got config", response.data);
            this.$store.commit("setConfiguration", response.data);
          })
          .catch(() => {
            console.log("Error loading configuration");
          });
    },

    handleNewHexagon() {
      console.log('show Hexagon editor');
      this.editMode = 1;
      this.$store.commit("setDefault");
      this.showSticker = false;
      // this.handleShowEditor({
      //   show: true,
      //   uuid: "",
      //   col: 0,
      //   row: 0,
      // });
    },

    handleUploadStarted() {
      console.log('uploadStarted handleUploadStarted EditorMain');
      this.uploadInteraction = true;
      this.blockInteraction = true;
    },


    handleUploadFinished() {
      console.log('uploadStarted handleUploadFinished EditorMain');
      this.uploadInteraction = false;
      this.blockInteraction = false;
    },

    handleAdmin() {
      // alert('ADMIN');
      this.$router.push('/admin');
    }

  },
  beforeMount() {
    this.loadConfigJson();
  },
  created() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  updated() {
    // console.log("UPDATED");
    if (this.saveInProgress) {
      // console.log("SKIP UPDATE as save is in Progress");
      return
    }
    if (this.showSticker) {
      // console.log("NO Load JSON sticker!");
      this.stickerTiles = this.$refs.stickerTiles;
      this.stickerView = this.$refs.stickerView;
      this.trashcan = this.$refs.trashcan;
    } else {
      this.editorView = this.$refs.editorView;
      this.toolArea = this.$refs.toolArea;
      // console.log("Load JSON");
      this.handleLoadJSON();
    }
    this.doResizeApp();
  },
  mounted() {
    this.self = this.$refs.editormain;
    this.accordionContainer = this.$refs.accordionContainer;
    this.topbar = this.$refs.topbar;
    // this.logo = this.$refs.logo;
    this.burger = this.$refs.burger;
    // this.logoutBtn = this.$refs.logoutBtn;
    this.contentArea = this.$refs.contentArea;
    this.stickerTiles = this.$refs.stickerTiles;
    this.stickerView = this.$refs.stickerView;
    this.trashcan = this.$refs.trashcan;
    this.editorView = this.$refs.editorView;
    this.toolArea = this.$refs.toolArea;
    // fle: disable local canvas
    // const cSize = this.$store.getters.getStickerSize(1);
    // this.stage = new Konva.Stage({
    //   container: "tempstage",
    //   width: cSize.width,
    //   height: cSize.height,
    // });
    // this.layer = new Konva.Layer();
    // this.stage.add(this.layer);
    // this.$store.commit("setCanvas", {stage: this.stage, layer: this.layer});

    this.isAdmin = this.$store.getters.getUserAdmin;

    // console.log("[init EditorMain] user is Admin:", this.isAdmin);

    this.doResizeApp();
  },
};
</script>

<style scoped>
/*.logo {*/
/*  position: absolute;*/
/*  !*top: 62px;*!*/
/*  max-width: 200px;*/
/*  top: 16px;*/
/*}*/

/*.burgerColumn {*/
/*  !*background-color: blueviolet;*!*/
/*  display: flex;*/
/*  align-items: flex-end;*/
/*  justify-content: flex-end;*/
/*  vertical-align: top;*/
/*}*/

.burgerPlacement {
  position: absolute;
  /*top: 62px;*/
  height: 50px;
  /*background-color: bisque;*/
  /*top: 22px;*/
  top: 23px;
  width: 50px;
}

.btn-right {
  position: absolute;
  /*top: 62px;*/
  /*height: 50px;*/
  /*left: 100vw;*/
}

.appspace {
  position: absolute;
  left: 0px;
  top: 0px;
  /*background-color: blueviolet;*/
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
}

.accordion-container {
  position: absolute;
  left: 60px;
  top: 0px;
  /*background-color: orange;*/
  padding: 0px;
  margin: 0px;
  width: 400px;
  z-index: 10;
  /*height: 100vh;*/
}

.topbar {
  position: absolute;
  left: 460px;
  top: 0px;
  /*background-color: yellow;*/
  padding: 0px;
  margin: 0px;
  height: 120px;
}

.content-area {
  position: absolute;
  left: 460px;
  top: 120px;
  width: 400px;
  height: 400px;
  /*background-color: aqua;*/
}

/*.sticker-position {*/
/*  position: absolute;*/
/*  !*background-color: tomato;*!*/
/*  width: 958px;*/
/*  height: 618px;*/
/*  overflow: hidden;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/

.sticker-position {
  position: absolute;
  /*background-color: tomato;*/
  /*width: 958px;*/
  /*height: 618px;*/
  overflow: hidden;
  margin: 0;
  padding: 0px;
}

.sticker-view {
  position: absolute;
  left: 0px;
  width: 0px;
  /*background-color: cornflowerblue;*/
}

.editor-view {
  position: absolute;
  left: 0px;
  width: 0px;
  /*background-color: olive;*/
  padding: 0px;
  margin: 0px;
  border: 0px solid transparent;
}

.editor-trashcan {
  position: absolute;
}

.tool_area {
  /*background-color: orange;*/
  width: calc(100% - 337px);
  content: "";
  height: 100%;
  /*border: 1px solid red;*/
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
}

@media screen and (max-width: 1700px) {
  .tool_area {
    display: none;
  }
}

.tool_bar {
  /*background-color: green;*/
  content: "";
  /*border: 1px solid blueviolet;*/
  /*bottom: 25px;*/
  position: absolute;
  display: flex;
  bottom: 60px; /* 42 , 12*/
}

.spacer {
  content: "";
  width: 10px;
  height: 20px;
  /*background-color: cornflowerblue;*/
}

.tempStage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 10px;
  height: 10px;
  background-color: rgb(255, 255, 255);
  visibility: hidden;
}

.blockEvents {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.8;
  /*z-index: 300;*/
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.blockMessage {
  background-color: yellow;
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  padding: 10px;
  font-weight: bold;
  border: 1px solid black;
}

.roche-btn {
  margin: 0px;
  padding: 10px 20px 10px 20px;
  font-size: 18px;
  display: flex;
  /*color: #2d3232;*/
  text-align: center;
  text-decoration: none;
  height: 40px;
  /*color:rgba(84, 79, 79,0.8);*/
  color: rgba(84, 79, 79, 1);
  white-space: nowrap;
}

.roche-btn:hover {
  /*background-color: #022366;*/
  color: black;
}

.roche-btn:visited {
  border: 0px;
}

.roche-btn:focus {
  outline: none;
}

.roche-dd-btn {
  background-color: white;
}

.roche-dd-btn:hover {
  background-color: orange;
}

.roche-dd-btn:focus {
  background-color: yellow;
}

.roche-dd-btn:focus-visible {
  background-color: burlywood;
}

.roche-dd-item:focus {
  color: orange;
  background-color: #4d3590;
}

.roche-dd-link {
  font-size: 18px;
  text-decoration: none;
  height: 40px;
  white-space: nowrap;
  color: rgba(84, 79, 79, 1);
}

.roche-dd-link:hover {
  color: black;
  /*background-color: #BDE3FF;*/
}

/*.roche-dd-link:focus {*/
/*  color: green;*/
/*  !*background-color: #BDE3FF;*!*/
/*}*/

dropdown {
  background-color: coral;
}

.roche-dd-link-active {
  color: black;
  background-color: orange;
}


.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  /*height: 100px;*/
  /*background-color: cadetblue;*/
}

/*.roche-logo {*/
/*  !*padding: 20px;*!*/
/*  margin-left: 00px;*/
/*  margin-top: 36px;*/
/*  !*width: 300px;*!*/
/*  height: 36px;*/
/*  !*width: 100%;*!*/
/*  !*background-image: url("/RocheLogoBlau.svg");*!*/
/*  background-position-y: center;*/
/*  background-size: contain;*/
/*  display: block;*/
/*  !*margin-left: 0px;*!*/
/*  background-position-x: 0px;*/
/*  min-width: 200px;*/
/*}*/

.roche-logout {
  width: 36px;
  height: 36px;
  /*background-image: url("/PowerButton.svg");*/
  /*background-position-y: center;*/
  /*background-size: contain;*/
  display: block;
  /*margin-top: -5px;*/
  top: 16px;
  background-color: white;
}

.dropdown-icon {
  background-color: white;
  background-image: none;
}

.dropdown-icon:focus {
  background-color: #4d3590;
  border: 2px solid green;
}

.dropdown-divider {
  border-bottom: 1px solid #2d2d2d;
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-toggle::after {
  display: none;
}

.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 110px;
  /*padding-top: 25px;*/
  width: 100%;
  position: absolute;
  left: 0px;
  top: 26px;
}

.roche-logo {
  height: 36px;
  background-position-y: bottom;
  background-size: contain;
  display: block;
  background-position-x: 0px;
  min-width: 83px;
}

.roche-logo:hover {
  cursor: pointer;
}

.info-message {
  font-size: 1.5em;
  background-color: white;
  /*display: block;*/
  opacity: 1;
  /*justify-content: space-around;*/
  /*padding-top: 50%;*/
  /*margin-bottom: 50%;*/
  /*height: 100%;*/
  /*background-color: #4d3590;*/
  flex: 1;
  padding-top: 50vh;
}

.info-card {
  z-index: 600;
  /*top: 50vh;*/
  /*left: 50vw;*/
  /*position: absolute;*/
  /*background-color: #0b41cd;*/
  opacity: 1;
  position: absolute;
  /*left: */
  /*left: 20vw;*/
  /*top: 20%;*/
  /*height: 60%;*/
  /*width: calc(100vw - 35px);*/
  left: 0px;
  width: 100vw;
  height: 100vh;
  top: 0px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  display: flow;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  /*justify-content: space-around;*/
  display: -webkit-flex;
  flex-direction: column;
}

.info-container {
  z-index: 600;
  background-color: greenyellow;
  opacity: 1;
  height: 100vh;
  z-index: 400;
}

.roche-settings {
  margin-top: -6px;
  height: 36px
}

.roche-greetys {
  margin-top: 26px;
  height: 36px;
  position: absolute;
  left: calc(100vw - 830px);
  /*left: 20px;*/
  float: right;
  /*background-color: #4d3590;*/
  /*background-color: #4d3590;*/
  /*padding-right: 20px;*/

}

@media screen and (max-width: 600px) {
  .roche-greetys {
    display: none;
    /*left: -314px;*/
  }

  .burgerPlacement {
    left: 50px;
    /*background-color: #4d3590;*/
    position: absolute;
    z-index: 10;
  }

}

.settings-dd-card {
  display: block;
}

</style>
