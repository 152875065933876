<template>
  <div class="stickerAccordion" v-if="accordions">
    <div class="logo-area">
      <!--              <b-img class="roche-logo" alt="Roche Logo" :src="require('@/assets/roche_logo_blue.svg')"/>-->
    </div>
<!--    <b-image alt="Plus Sign" :src="require('@/assets/roche_logo_blue.svg')" class="image-group-add-icon"></b-image>-->
<!--    <div class="accordion" role="tablist" v-if="accordions">-->
      <div no-body
          v-for="accordion in accordions"
          :key="accordion.id"
          :id="'accordion_tile_'+accordion.id"
          class="RocheSansMedium stickerAccordionTile mb-1">

        <accordion-button v-bind:id="'accordion_bttn_'+accordion.id" :ref="accordion.id" :onClick="handleClick"
                          :isOpen="accordion.open">{{ accordion.name }}
        </accordion-button>

<!--              <b-card-header header-tag="header"-->
<!--                             role="tab"-->
<!--                             class="sticker-group-header p-1"-->
<!--                             @click="handleStickerClick(accordion.id)">-->
<!--                {{ accordion.name }}-->
<!--              </b-card-header>-->

<!--        <b-collapse :ref="'accordion_collapse_'+accordion.id" :id="'accordion_collapse_'+accordion.id"-->
<!--                    accordion="sticker-accordion" role="tab">-->

          <div ref="contentArea" class="contentArea" v-if="accordion.groups" v-bind:id="'accordion_area_'+accordion.id">
            <div role="tablist" v-if="accordion.groups.length > 1">
              <b-card no-body
                      class="image-group mb-1"
                      v-for="imageGroup in accordion.groups"
                      :id="'collapse_group_'+accordion.id+'_'+imageGroup.id"
                      :key="imageGroup.id">
                <b-card-header header-tag="header"
                               role="tab"
                               class="image-group-header"
                               >
                                <b-link block @click="handleGroupClick(accordion.id, imageGroup.id)" class="image-group-link">
                  {{ imageGroup.name }}
                                </b-link>
<!--                  <b-link v-if="imageGroup.allow_design" block @click="handleNewDesign()" class="image-group-link-design">-->
<!--                    +-->
<!--                  </b-link>-->
                  <b-image v-if="imageGroup.allow_design" @click="handleNewDesign()" :src="require('@/assets/plus_sign.svg')" class="image-group-add-icon"></b-image>
                </b-card-header>
                <b-collapse :ref="'accordion_group_collapse_'+accordion.id+'_'+imageGroup.id"
                            :id="'accordion_group_collapse_'+accordion.id+'_'+imageGroup.id"
                            accordion="image-group-accordion" role="tab">
                  <ajax-image-grid @scrollInFocus="handleScrollInFocus" @imageDoubleClick="handleDoubleClick"
                                   @uploadStarted="handleUploadStarted"
                                   @uploadFinished="handleUploadFinished"
                                   :area=accordion.id
                                   :group=imageGroup.id
                                   :id="'accordion_image_grid_'+accordion.id+'_'+imageGroup.id"
                                   :url=imageGroup.url
                                   :upload=imageGroup.allowUpload
                                   :uploadurl=imageGroup.upload_url
                                   :downloadurl=imageGroup.download_url
                                   :reloadfolder="reloadFolder"
                                   :reloadflag="reloadFlag"
                                   :withbackground=imageGroup.show_background
                                   :imagelayer=imageGroup.image_layer
                                   :ref="'imagegrid_'+accordion.id+'_'+imageGroup.id">
                  </ajax-image-grid>
                </b-collapse>
              </b-card>
            </div>
            <ajax-image-grid v-else
                             v-for="imageGroup in accordion.groups"
                             :key="imageGroup.id"
                             @scrollInFocus="handleScrollInFocus"
                             @imageDoubleClick="handleDoubleClick"
                             @uploadStarted="handleUploadStarted"
                             @uploadFinished="handleUploadFinished"
                             :area=accordion.id
                             :group=imageGroup.id
                             :id="'accordion_image_grid_'+accordion.id+'_'+imageGroup.id"
                             :url=imageGroup.url
                             :upload=imageGroup.allowUpload
                             :uploadurl=imageGroup.upload_url
                             :downloadurl=imageGroup.download_url
                             :reloadfolder="reloadFolder"
                             :reloadflag="reloadFlag"
                             :withbackground=imageGroup.show_background
                             :imagelayer=imageGroup.image_layer
                             :ref="'imagegrid_'+accordion.id+'_'+imageGroup.id">
            </ajax-image-grid>
          </div>
<!--        </b-collapse>-->
      </div>
    </div>
<!--  </div>-->
</template>

<script>
// import ImageGrid from "@/components/ImageGrid";
import AccordionButton from '@/components/AccordionButton';
import AjaxImageGrid from '@/components/AjaxImageGrid';
import {HTTP} from "@/main";
// import MyImages from "@/components/MyImages";
// import vue2Dropzone from 'vue2-dropzone'
// import VueAxios from "vue-axios";
// import axios from "axios";

// async function load(src) {
//   const config = {url: src, method: "get", responseType: "blob"}
//   const response = await HTTP.request(config)
//   return response.data
// }


export default {
  name: "StickerAccordion",
  components: {
    // MyImages,
    AccordionButton,
    AjaxImageGrid,
    // ImageGrid,
    // vue2Dropzone,
  },
  data() {
    return {
      imageData: "",
      imagesArray: null,
      blobUrl: "",
      accordions: [{
        id: 0,
        name: "",
        open: false,
        groups: [{
          id: 0,
          name: "",
          url: "",
          image_layer: 0,
          allowUpload: false,
          upload_url: "",
          download_url: "",
          show_background: false,
          allow_design: false
        }]
      }],
      activeAccordion: -1,
      reloadFolder: '',
      reloadFlag: false,
    }
  },
  methods: {
    handleDoubleClick(data) {
      this.$emit("imageDoubleClick", data);
    },
    resizeContentArea() {
      const maxHeight = parseInt(window.innerHeight) - 180; // document.body.clientHeight;
      const button = document.getElementById('accordion_bttn_1')
      const buttonHeight = button.clientHeight;

      const remainingHeight = maxHeight - (buttonHeight + 2) * this.accordions.length;

      const contentArea = document.getElementById('accordion_area_' + this.activeAccordion);

      if (contentArea) {

        contentArea.style.height = (remainingHeight + 2) + 'px';
        contentArea.style.display = null; //"inherited";

        for (let i = 1; i <= this.accordions.length; i++) {
          let hiddenContentArea = document.getElementById('accordion_area_' + i);

          // for (let g = 0; g < this.accordions[i].groups.length; g++) {
          //   console.log("AccordionGRP-Info", this.accordions[i].groups[g].url, this.accordions[i].groups[g].show_background);
          // }

          // console.log('hide area: ', i, 'accordion_area_' + i, hiddenContentArea)
          if (i != this.activeAccordion) {
            hiddenContentArea.style.display = "none";
          }
        }
      }

      // console.log('new contentArea height: ', maxHeight, buttonHeight, remainingHeight);
    }
    ,

    handleWindowResize() {
      // console.log("handleWindowResize", e);
      this.resizeContentArea();
    }
    ,

    handleClick(e) {
      // console.log("handleClick", e.target);
      const targetID = e.target.id;

      // console.log('[handleClick] targetID:', targetID);

      const accordionID = targetID.split('_')[2];
      this.setActive(accordionID);
    },

    setActive(accordionID) {
      if (accordionID == this.activeAccordion) {
        // return
        const activeContentArea = document.getElementById('accordion_area_' + this.activeAccordion);

        activeContentArea.style.height = '0px';

        for (let i = 0; i < this.accordions.length; i++) {
          this.accordions[i].open = false;
        }

        this.activeAccordion = -1;

        this.resizeContentArea();

        return;
      }

      // console.log('this.activeAccordion', this.activeAccordion, "==>", accordionID);

      const activeContentArea = document.getElementById('accordion_area_' + this.activeAccordion);

      if (activeContentArea) {
        activeContentArea.style.height = '0px';
      }

      this.accordions[accordionID - 1].open = true;
      if (this.activeAccordion > 0) {
        this.accordions[this.activeAccordion - 1].open = false;
      }

      this.activeAccordion = accordionID;

      this.resizeContentArea();

      return;
    },

    accordionButtonClick(event) {
      console.log(event);
      // this.handleClick(event);
    },

    handleScrollInFocus() {
      // console.log('got scroll in focus event');
      let contentArea = this.$refs.contentArea[2];
      contentArea.scrollTop = contentArea.scrollHeight;

      // console.log('contentArea.scrollTop', contentArea, contentArea.scrollTop, contentArea.scrollHeight);
    },

    handleDeleteImage(imageUUID, area, group) {
      // console.log('accordion deleteImage', imageUUID);
      const nam = 'imagegrid_' + area + '_' + group
      const img = Object.entries(this.$refs)
          .find(([ref,]) => (ref === nam));
      console.log(img, ' - ', img[1], img[1][0]);
      //this.$refs.imagegrid_1_2
      img[1][0].removeImage(imageUUID);
    },

    load() {
      HTTP.get(
          'restricted/accordions')
          .then((response) => {
            // console.log('fetched accordion setup', response.data);
            this.accordions = response.data.accordions;
            if (this.accordions) {
              // console.log("restricted/accordion", this.accordions);
              this.handleLoaded();
            }
          })
          .catch(() => {
                // console.log('failed loading the accordion');
              }
          )
    },

    handleLoaded() {
      for (let i = 0; i < this.accordions.length; i++) {
        if (this.accordions[i].open) {
          this.activeAccordion = this.accordions[i].id;
        }
      }
      // console.log('[mounted] this.activeAccordion:', this.activeAccordion);
      this.resizeContentArea();
    },


    reloadImages(folderID, accordionID) {
      // console.log("Reload images: ", folderID, this.$refs);
      this.reloadFolder = folderID;
      this.reloadFlag = !this.reloadFlag;
      if (accordionID > 0) this.setActive(accordionID);
    },

    handleGroupClick(accordionID, groupID) {
      // console.log('[handleGroupClick]', accordionID, groupID);
      // let imageGroupCollapse = this.$refs['accordion_group_collapse_' + accordionID + '_' + groupID];
      // console.log('[handleGroupClick]', imageGroupCollapse);
      this.$root.$emit('bv::toggle::collapse', 'accordion_group_collapse_' + accordionID + '_' + groupID);
    },

    handleStickerClick(accordionID) {
      this.$root.$emit('bv::toggle::collapse', 'accordion_collapse_' + accordionID);
    },

    handleNewDesign() {
      // this.$root.$emit('bv::toggle::collapse', 'accordion_group_collapse_' + accordionID + '_' + groupID);
      this.$emit("newHexagon",{});
    },

    handleUploadFinished() {
      // console.log('handleUploaded handleUploadFinished StickerAccordion');
      this.$emit("uploadFinished");
    },

    handleUploadStarted() {
      this.$emit("uploadStarted");
      console.log('uploadStarted handleUploadStarted StickerAccordion');
    },

  },

  created() {
    window.addEventListener("resize", this.handleWindowResize);
    this.$on('accordionButtonClick', event => {
      this.accordionButtonClick(event);
    });
  },

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  updated() {
    this.handleLoaded();
  },

  mounted() {
    this.load();
  },

  //watch: {
  //   src: {
  //     immediate: true,
  //     handler(src) {
  //       if (!src) return
  //       load(src).then(blob => {
  //         this.blobUrl = URL.createObjectURL(blob)
  //       })
  //     },
  //   },
  //},

}
</script>

<style scoped>
.stickerAccordionTile {
  padding-bottom: 2px;
}

.stickerAccordion {
  width: 400px;
  height: 100%;
  /*z-index: 40;*/
  /*z-index: 10;*/
  /*top: 0px;*/
  /*height: calc(100vh-200px);*/
  /*background-color: #4d3590;*/
}

.contentArea {
  /*width: 100%;*/
  overflow-y: scroll;
  height: 0px;
  /*z-index: 10;*/
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.gridImage {
  max-width: 166px;
  max-height: 86px;
}

.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 110px;
  /*background-color: cadetblue;*/
  padding-top: 25px;
  width: 100%;
}

.roche-logo {
  /*padding: 20px;*/
  /*margin-left: 00px;*/
  /*margin-top: 36px;*/
  /*width: 300px;*/
  height: 36px;
  /*width: 100%;*/
  /*background-image: url("../assets/roche_logo_blue.svg");*/
  background-position-y: bottom;
  background-size: contain;
  display: block;
  /*margin-left: 0px;*/
  background-position-x: 0px;
  min-width: 83px;
  /*max-width: 200px;*/
  /*width: 120px;*/
}

.image-group-header {
  font-family: "RocheSans", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0;
  margin-left: 15px;
  background-color: white;
  /*cursor: pointer;*/
  border: 0px;
  /*border-bottom: 1px solid #ddd;*/
}

.image-group-link {
  font-family: "RocheSans", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
}

.sticker-group-header {
  cursor: pointer;
}

.image-group {
  /*background-color: #4d3590;*/
  border: 0px;
}

.image-group-link-design {
  font-family: "RocheSans", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  letter-spacing: 0;
  margin-left: 50%;
  background-color: white;
  cursor: pointer;
  border: 0px;
  z-index: 20;
}

.image-group-add-icon {
  display: block;
 /*border: 1px solid #4d3590;*/
  height: 15px;
  position: absolute;
  width: 15px;
  top: 14px;
  left: 340px;
  background-image: url('../assets/plus_sign.svg');
  /*background-position: right;*/
  /*background-position: ;*/
  cursor: pointer;
}

</style>
