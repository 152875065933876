<template>
  <b-container fluid class="full-height">

    <div class="left-container" ref="leftContainer">
      <div class="logo-area">
        <b-img class="roche-logo" alt="Roche Logo" :src="require('@/assets/roche_logo_blue.svg')" @click="handleExit"/>
      </div>

      <div class="menu-area">

        <button @click="handleShowUsers" class="RocheSansMedium accordionButton">
          <div class="bttn-text">
            <slot>Benutzer</slot>
          </div>
        </button>

        <button @click="handleUpload" class="RocheSansMedium accordionButton">
          <div class="bttn-text">
            <slot>Allgemeine Einzelsticker verwalten</slot>
          </div>
        </button>

<!--        <button @click="handleAccordionAdmin" class="RocheSansMedium accordionButton">-->
<!--          <div class="bttn-text">-->
<!--            <slot>Akkordion</slot>-->
<!--          </div>-->
<!--        </button>-->

<!--        -->

<!--        <button @click="handleConfirmation" class="RocheSansMedium accordionButton">-->
<!--          <div class="bttn-text">-->
<!--            <slot>Datenschutz</slot>-->
<!--          </div>-->
<!--        </button>-->

      </div>
    </div>

    <div class="topbar" ref="topbar">
      <b-row>
        <div ref="burger" class="burgerPlacement">
          <b-dropdown ref="settings" right no-caret variant="outline-light" text-decoration-none class="dropdown-icon">
            <template v-slot:button-content>
              <b-img class="roche-settings" alt="settings" :src="require('@/assets/settings.svg')"/>
            </template>

            <!--            <b-dropdown-text>-->
            <!--              <b-link class="roche-dd-link" @click="handleShowUsers">-->
            <!--                Benutzer-->
            <!--              </b-link>-->
            <!--            </b-dropdown-text>-->

            <!--            <b-dropdown-text link>-->
            <!--              <b-link @click="handleUpload" class="roche-dd-link">-->
            <!--                Hochladen-->
            <!--              </b-link>-->
            <!--            </b-dropdown-text>-->

            <!--            <b-dropdown-divider class="dropdown-divider"></b-dropdown-divider>-->
            <b-dropdown-text>
              <b-link logout @click="handleExit" class="roche-dd-link">Editor</b-link>
            </b-dropdown-text>

            <b-dropdown-divider class="dropdown-divider"></b-dropdown-divider>
            <b-dropdown-text>
              <b-link logout @click="handleLogout" class="roche-dd-link">Abmelden</b-link>
            </b-dropdown-text>
          </b-dropdown>

        </div>
      </b-row>
      <b-row>
        <b-img class="roche-greetys" alt="RocheGreetys" :src="require('@/assets/lg_rochegreetys_1130_h36.png')"/>
      </b-row>

      <!--      <b-row>-->
      <!--        <div class="tool_area" ref="toolArea">-->
      <!--          <div class="tool_bar" ref="toolBar">-->
      <!--            <div class="spacer"></div>-->
      <!--            <b-link class="roche-btn" @click="handleShowUsers">-->
      <!--              Benutzer-->
      <!--            </b-link>-->
      <!--            <div class="spacer"></div>-->
      <!--            <b-link class="roche-btn" @click="handleUpload">-->
      <!--              Hochladen-->
      <!--            </b-link>-->
      <!--            <div class="spacer"></div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-row>-->

    </div>

    <b-container fluid class="content-area">
      <user-table v-if="showUserTable"></user-table>
      <admin-upload v-if="showUpload"></admin-upload>
      <accordion-admin v-if="showAccordionAdmin"></accordion-admin>
      <confirm-log-view v-if="showConfirmationLog" :email="logEmail"></confirm-log-view>
    </b-container>

  </b-container>
</template>

<script>
import UserTable from "@/components/UserTable";
import AdminUpload from "@/components/AdminImages";
import AccordionAdmin from "@/components/AccordionAdmin";
import ConfirmLogView from "@/components/ConfirmLogView";

export default {
  name: "Administration",

  components: {ConfirmLogView, AccordionAdmin, UserTable, AdminUpload},

  data() {
    return {
      logEmail: "",
      showUserTable: true,
      showUpload: false,
      showAccordionAdmin: false,
      showConfirmationLog: false,
    }
  },

  methods: {
    handleLogout() {
      this.$refs.settings.hide();
      this.$router.replace("/logout");
    },

    handleExit() {
      this.$refs.settings.hide();
      this.$router.replace("/main");
    },

    clearViews() {
      this.showUserTable = false;
      this.showAccordionAdmin = false;
      this.showUpload = false;
      this.showConfirmationLog = false;
    },

    handleUpload() {
      this.clearViews();
      this.showUpload = true;
    },

    handleShowUsers() {
      this.clearViews();
      this.showUserTable = true;
    },

    handleAccordionAdmin() {
      this.clearViews();
      this.showAccordionAdmin = true;
    },

    handleConfirmation() {
      this.clearViews();
      this.showConfirmationLog = true;
    },

  },

  mounted() {
    if (!this.$store.getters.getUserAdmin) {
      this.$router.replace("/main");
    }
    this.handleShowUsers();
  }

}
</script>

<style scoped>
.full-height {
  height: 100vh;
  /*background-color: #4d3590;*/
}

.left-container {
  position: absolute;
  left: 60px;
  top: 0px;
  padding: 0px;
  margin: 0px;
  width: 400px;
}

.topbar {
  position: absolute;
  left: 460px;
  top: 0px;
  padding: 0px;
  margin: 0px;
  height: 120px;
  width: calc(100vw - 520px);
}

.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 110px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 26px;
}

.roche-logo {
  height: 36px;
  background-position-y: bottom;
  background-size: contain;
  display: block;
  background-position-x: 0px;
  min-width: 83px;
}

.roche-logo:hover {
  cursor: pointer;
}

.menu-area {
  margin-top: 110px;
}

.burgerPlacement {
  position: absolute;
  height: 50px;
  top: 23px;
  width: 50px;
  left: calc(100vw - 576px);
}

.roche-settings {
  margin-top: -6px;
  height: 36px
}

.dropdown-icon {
  background-color: white;
  background-image: none;
}

.dropdown-icon:focus {
  background-color: #4d3590;
  border: 2px solid green;
}

.roche-dd-link {
  font-size: 18px;
  text-decoration: none;
  height: 40px;
  white-space: nowrap;
  color: rgba(84, 79, 79, 1);
}

.roche-dd-link:hover {
  color: black;
  /*background-color: #BDE3FF;*/
}

.dropdown-divider {
  border-bottom: 1px solid #2d2d2d;
  margin-left: 5px;
  margin-right: 5px;
}

.roche-greetys {
  margin-top: 26px;
  height: 36px;
  position: absolute;
  left: calc(100vw - 830px);
  float: right;
}

/*.tool_area {*/
/*  !*background-color: orange;*!*/
/*  width: calc(100% - 337px);*/
/*  content: "";*/
/*  height: 100%;*/
/*  padding-bottom: 25px;*/
/*  padding-left: 15px;*/
/*  padding-right: 15px;*/
/*  padding-top: 0px;*/
/*}*/

/*.tool_bar {*/
/*  !*background-color: green;*!*/
/*  content: "";*/
/*  position: absolute;*/
/*  display: flex;*/
/*  bottom: 60px;*/
/*}*/

/*.spacer {*/
/*  content: "";*/
/*  width: 10px;*/
/*  height: 20px;*/
/*}*/

/*.roche-btn {*/
/*  margin: 0px;*/
/*  padding: 10px 20px 10px 20px;*/
/*  font-size: 18px;*/
/*  display: flex;*/
/*  !*color: #2d3232;*!*/
/*  text-align: center;*/
/*  text-decoration: none;*/
/*  height: 40px;*/
/*  !*color:rgba(84, 79, 79,0.8);*!*/
/*  color: rgba(84, 79, 79, 1);*/
/*  white-space: nowrap;*/
/*}*/

/*.roche-btn:hover {*/
/*  !*background-color: #022366;*!*/
/*  color: black;*/
/*}*/

/*.roche-btn:visited {*/
/*  border: 0px;*/
/*}*/

/*.roche-btn:focus {*/
/*  outline: none;*/
/*}*/

.bttn-text {
  position: relative;
  top: 27px;
  left: 20px;
  font-size: 18px;
  font-family: "RocheSans", sans-serif;
  line-height: 1.3;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0;
}

.accordionButton {
  display: flex;
  color: #2d3232;
  border-bottom: 1px solid #ddd;
  text-align: center;
  text-decoration: none;
  height: 54px;
  width: 400px;
}

.accordionButton:hover {
  color: black;
}

.content-area {
  /*position: absolute;*/
  /*left: 0px;*/
  margin-top: 110px;
  /*background-color: #ece3df;*/
  /*border: 1px dotted red;*/
  margin-left: 500px;
  height: calc(100vh - 180px);
  padding: 0px;
  width: calc(100vw - 570px);
}


@media screen and (max-width: 1700px) {
  .tool_area {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .roche-greetys {
    display: none;
  }

  .burgerPlacement {
    left: 50px;
    /*background-color: #4d3590;*/
    position: absolute;
    z-index: 10;
  }
}

</style>