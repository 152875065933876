<template>
  <section/>
</template>

<script>
export default {
  name: 'Logout',
  created() {
    this.$gapi.logout().then((success) => {
          // console.log('logout', success);
          delete localStorage.token
          delete localStorage.confirmed;
          this.$store.dispatch('logout')
          this.$router.push('/')
        }
    ).catch(() => {
      // console.log("logout failed")
    })
  },
}
</script>