<template>
  <popup-modal ref="popup">
    <b-card class="confirm-card">
    <div class="dialog-head roche-blue font-rochesans-bold">{{ title }}</div>

    <p class="dialog-message font-rochesans-light">
<!--      <b-img class="dialog-icon" alt="Roche Logo" :src="require('@/assets/exclamationmark.svg')"/>-->
      {{ message }}</p>
    <div class="btns">
      <b-button class="bttn-cancel roche-btn roche-blue-bg font-rochesans-medium" v-show="showCancel" @click="cancel">{{ cancelButton }}</b-button>
      <b-button class="bttn-confirm roche-btn roche-blue-bg font-rochesans" v-show="showOk" @click="confirm">{{ okButton }}</b-button>
    </div>
    </b-card>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/PopupModal.vue";

export default {
  name: "ConfirmDialog",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialog
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Go Back", // text for cancel button
    showCancel: true,
    showOk: true,
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.showOk) this.showOk = opts.showOk == "true";
      if (opts.showCancel) this.showCancel = opts.showCancel == "true";
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialog'))
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  padding: 0.5em 1em;
  background-color: #c6daf1;
  color: #4d3590;
  border: 2px solid #0e29c5c5;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #c6daf1;
  color: #4d3590;
  border: 2px solid #0e29c5c5;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.roche-btn {
  /*padding: 5px;*/
  padding: 8px 20px 10px 20px;
  margin: 0px;
  font-size: 1em;
  display: flex;
  text-align: center;
  text-decoration: none;
  height: 40px;
  color: white;
  white-space: nowrap;
}

.roche-btn:hover {
  /*color: black;*/
  background-color: #BDE3FF;
  color: #2d3232;
}

.roche-btn:visited {
  border: 0px;
}

.roche-btn:focus {
  outline: none;
}
.dialog-head {
  font-size: 1.5em;
}

.dialog-message {
  font-size: 1em;
}

.bttn-cancel {
  background-color: #0b41cd;
}

.bttn-confirm {
  /*background-color: #022366;*/
  background-color: #0b41cd;
}

.dialog-icon {
  width: 25px;
}

</style>