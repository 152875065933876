<template>
  <div id="app">
    <!--    <template v-if="currentUser">-->
    <!--      <Navbar></Navbar>-->
    <!--    </template>-->
    <!--    <div class="container-fluid">-->
    <router-view></router-view>
    <!--      <template v-if="currentUser">-->
    <!--        <Foot></Foot>-->
    <!--      </template>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import Navbar from '@/components/Navbar'
// import Foot from '@/components/Foot'

export default {
  name: 'app',
  computed: {
    ...mapGetters({currentUser: 'currentUser'})
  },
  created() {
    if (this.$route.path !== '/test') {
      this.checkCurrentLogin()
    }
  },
  updated() {
    if (this.$route.path !== '/test') {
      this.checkCurrentLogin()
    }
  },
  methods: {
    checkCurrentLogin() {
      // console.log('[App] checkCurrentLogin', this.currentUser, this.$route.path);
      if (!this.currentUser && this.$route.path !== '/') {
        // console.log('[App] checkCurrentLogin ==> redirect', this.$route.path);
        this.$router.push('/');
      }
      if (this.currentUser) {
        if (localStorage.confirmed) {

          if (this.$route.path !== '/main' && (!this.$store.getters.getUserAdmin && this.$route.path !== '/admin')) {
            this.$router.push('/main');
          }
        } else {
          if (this.$route.path !== '/confirm') {
            this.$router.push('/confirm');
          }
        }
      }

      // else {
      //   if (localStorage.confirmed) {
      //     console.log('[App] checkCurrentLogin(confirmed) ==> redirect', this.$route.path)
      //     if (this.$route.path.indexOf('confirm') != -1) {
      //       this.$router.push('/main');
      //     } else {
      //       console.log('[App] checkCurrentLogin(confirmed) ==> confirm!');
      //       this.$router.push('/confirm');
      //     }
      //   }
      // }
    }
  },
  components: {
    // Navbar,
    // Foot
  }
}
</script>

<style>
#app {
  /*background-color: goldenrod;*/
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  marging: 0;
  font-family: "RocheSans", sans-serif;
}

.container-fluid {
  padding-top: 20px;
}

/*.font-minion-italic {*/
/*  font-family: minion-pro-italic;*/
/*}*/

/*.font-minion-condensed {*/
/*  font-family: minion-pro-condensed;*/
/*}*/

/*.font-imago {*/
/*  !*font-family: "Gayathri", sans-serif;*!*/
/*  font-family: "Imago-Book", sans-serif;*/
/*  !*src: url("/9C9D2C9D-2600-4F0C-9293-DAAAC563A3A0.woff") format('woff');*!*/
/*}*/

.color-blue {
  color: rgba(0, 102, 204, 1);
}

.roche-blue {
  color: #0b41cd;
}

.roche-blue-extra-light {
  color: #BDE3FF;
}

.roche-blue-dark {
  color: #022366;
}

.roche-blue-bg {
  background-color: #0b41cd;
}

.roche-blue-extra-light-bg {
  background-color: #BDE3FF;
}

.roche-blue-dark-bg {
  background-color: #022366;
}

@font-face {
  font-family: "RocheSansLight";
  src: url("assets/sanslight.woff") format('woff');
}

@font-face {
  font-family: "RocheSansCondensedLight";
  src: url("assets/sanscondensedlight.woff") format('woff');
}

@font-face {
  font-family: "RocheSansCondensedLightItalic";
  src: url("assets/sanscondensedlightitalic.woff") format('woff');
}

@font-face {
  font-family: "RocheSansCondensedItalic";
  src: url("assets/sanscondenseditalic.woff") format('woff');
}

@font-face {
  font-family: "RocheSans";
  src: url("assets/sans.woff") format('woff');
}

@font-face {
  font-family: "RocheSansCondensed";
  src: url("assets/sanscondensed.woff") format('woff');
}

@font-face {
  font-family: "RocheSansMedium";
  src: url("assets/sansmedium.woff") format('woff');
}

@font-face {
  font-family: "RocheSansBold";
  src: url("assets/sansbold.woff") format('woff');
}

@font-face {
  font-family: "RocheSerifLight";
  src: url("assets/seriflight.woff") format('woff');
}

@font-face {
  font-family: "RocheSerif";
  src: url("assets/serif.woff") format('woff');
}

@font-face {
  font-family: "RocheSerifItalic";
  src: url("assets/serifitalic.woff") format('woff');
}

@font-face {
  font-family: "RocheSerifBold";
  src: url("assets/serifbold.woff") format('woff');
}


.font-rochesans {
  font-family: "RocheSans", sans-serif;
}

.font-rochesans-bold {
  font-family: "RocheSansBold", sans-serif;
}

.font-rochesans-light {
  font-family: "RocheSansLight", sans-serif;
}

.font-rochesans-medium {
  font-family: "RocheSansMedium", sans-serif;
}

.font-rochesans-condensed {
  font-family: "RocheSansCondensed", sans-serif;
}

.font-rocheserif {
  font-family: "RocheSerif", serif;
}

.font-rocheserif-italic {
  font-family: "RocheSerifItalic", serif;
}


</style>
