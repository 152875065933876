<template>
  <div class="image-upload">
    <!--    <input id="fileupload" type="file" name="file" @change="onChange">-->

    <input
        type="file"
        style="display: none"
        ref="fileInput"
        accept="image/*"
        id="fileupload"
        name="file"
        class="file-input"
        @change="onChange"/>

    <button variant="outline-light" :class="{'fileupload-link': styled}" class="RocheSansMedium accordionButton" @click="onPickFile">
      <div class="bttn-text">
        <slot>Bild&nbsp;hochladen...</slot>
      </div>
    </button>

  </div>
</template>

<script>
import {HTTP} from "@/main";

export default {
  name: "ImageUpload",
  props: {
    url: {
      default: '',
      type: String,
      required: true,
    },
    styled: {
      default: true,
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isStyled: true,
    }
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click()
    },

    // onFilePicked (event) {
    //   const files = event.target.files
    //   let filename = files[0].name
    //   const fileReader = new FileReader()
    //   fileReader.addEventListener('load', () => {
    //     this.imageUrl = fileReader.result
    //   })
    //   fileReader.readAsDataURL(files[0])
    //   this.image = files[0]
    // },

    onChange(event) {
      this.imagesArray = event.target.files[0];
      this.$emit("uploadStarted");
      console.log('uploadStarted onChange ImageUpload');
      this.onUpload();
    },

    onUpload() {
      // console.log("upload: ", this.imagesArray);
      let formData = new FormData();
      // formData.append('file', this.imageData);
      const filename = this.imagesArray.name
      formData.append('file', this.imagesArray, filename);
      document.getElementById('fileupload').value = null;
      HTTP.post(this.url
          , formData
          , {headers: {"Content-Type": "multipart/form-data"}}
      )
          .then(response => {
            console.log('upload:', response)

            console.log(filename, response.data);

            // const fileUUID = JSON.parse(response.data)["fileUUID"];

            const fileUUID = response.data.fileUUID;

            // console.log("new uploaded file UUID: ", fileUUID)

            // const myImages =  this.$refs.myImages[0];
            // myImages.addImage(fileUUID);
            this.$emit("upload-uuid", fileUUID);
            console.log('upload-uuid onUpload ImageUpload');

          }).catch((error) => {
        console.log('upload failed', error);
      })
    },
  }
}
</script>

<style scoped>
.image-upload {
  /*border: 2px solid orange;*/
  /*background-color: #BDE3FF;*/
  /*position: absolute;*/
  /*right: 60px;*/
}
.file-input {
  background-color: #4d3590;
  width: 1px;
  height: 0px;
  padding: 0px;
  margin: 0px;
  content: none;
}

.fileupload-link {
  font-family: "RocheSans", sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 100;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  padding-left: 20px;
}

.bttn-text {
  padding-top: 10px;
}

/*.bttn-text {*/
/*  position: relative;*/
/*  top: 27px;*/
/*  left: 20px;*/
/*  font-size: 18px;*/
/*  font-family: "RocheSans", sans-serif;*/
/*  line-height: 1.3;*/
/*  font-weight: 400;*/
/*  text-transform: none;*/
/*  text-decoration: none;*/
/*  letter-spacing: 0;*/
/*}*/

/*.accordionButton {*/
/*  display: flex;*/
/*  color: #2d3232;*/
/*  border-bottom: 1px solid #ddd;*/
/*  text-align: center;*/
/*  text-decoration: none;*/
/*  height: 54px;*/
/*  width: 400px;*/
/*}*/

/*.accordionButton:hover {*/
/*  color: black;*/
/*}*/

</style>