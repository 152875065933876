<template>
  <div class="confirm-log-view">
    <b-row>
      <b-dropdown id="user-dd" right variant="outline-light" text-decoration-none :text="selectedEmail"
                  class="user-dd">
        <b-dropdown-item v-for="user in users" :key="user.email">
          {{ user.email }}
        </b-dropdown-item>
      </b-dropdown>
    </b-row>
    <b-row>
      <confirmation-log :email="selectedEmail"></confirmation-log>
    </b-row>
  </div>
</template>

<script>
import {HTTP} from "@/main";
import ConfirmationLog from "@/components/ConfirmationLog";

export default {
  name: "ConfirmLogView",

  components: {ConfirmationLog},

  data() {
    return {
      selectedEmail: "",
      users: [],
    }
  },

  methods: {
    loadUsers() {
      // console.log("[ConfirmLogView] Load users");
      HTTP.get("restricted/users", {}, {responseType: "json"})
          .then((response) => {
            // console.log("[ConfirmLogView] got users", response.data);

            this.users = [];

            for (let i = 0; i < response.data.length; i++) {
              let row = {
                'email': response.data[i].email,
              }
              this.users.push(row)
            }

            if (this.users.length > 0) {
              this.selectedEmail = this.users[0].email;
            }

          })
          .catch(() => {
            // console.log("[ConfirmLogView] Error loading users");
          });
    },
  },

  mounted() {
    this.loadUsers();
  }

}
</script>

<style scoped>

</style>