<template>
  <div class="confirmation-table">
    <b-table striped hover :items="items" :fields="fields">
    </b-table>
  </div>
</template>

<script>
import {HTTP} from "@/main";

export default {
  name: "ConfirmationLog",

  props: {
    email: {
      default: "",
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        // {
        //   key: 'email',
        //   label: 'E-Mail',
        //   sortable: true,
        // },
        {
          key: 'timestamp',
          label: 'Datenschutzhinweis bestätigt',
          sortable: true,
        },
        {
          key: 'session',
          label: 'Session',
          sortable: true,
        },
      ],
      items: [],
    }
  },

  methods: {
    loadConfirmLog() {
      // console.log("[loadConfirmLog]", this.email);

      if ("" !== this.email) {
        HTTP.get("restricted/confirm/list/"+this.email, {}, {responseType: "json"})
            .then((response) => {
              // console.log("got confirmLog", response.data);

              const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

              this.items = [];

              for (let i = 0; i < response.data.length; i++) {


                let timestamp = new Date(response.data[i].timestamp);
                // console.log("Load confirmLog timestamp:", timestamp);
                let timestampStr = timestamp.toLocaleTimeString("de-DE", dateOptions);
                // console.log("Load confirmLog timestampStr:", timestampStr);

                let row = {
                  'email': this.email,
                  'timestamp': timestampStr,
                  'session': response.data[i].session,
                }
                this.items.push(row)
              }
            })
            .catch(() => {
              // console.log("Error loading confirmLog");
            });
      }

    }
  },

  watch: {
    email: function (newEmail, oldEmail) {
      if (newEmail !== oldEmail) {
        this.loadConfirmLog();
      }
    }
  },

  mounted() {
    this.loadConfirmLog();
  },

}
</script>

<style scoped>

</style>