<template>
  <!--  <b-container flex class="login-overlay bg-cover">-->
  <b-container fluid class="full-height">
    <b-row cols="12" class="main-row">
      <b-col cols="2" class="left-col">
<!--        <b-image class="roche-logo" alt="Roche Logo"/>-->
        <div class="logo-area">
          <b-img class="roche-logo" alt="Roche Logo" :src="require('@/assets/roche_logo_blue.svg')"/>
        </div>
      </b-col>
      <b-col cols="4">

        <div class="login d-flex align-items-center py-5">
          <b-container fluid>
            <b-row cols="12">
              <b-img class="roche-greetys" :src="require('@/assets/lg_rochegreetys_1130.png')" alt="Roche Greetys"/>
              <!--              <span class="roche-greetys font-rochesans-bold">Roche</span><span class="roche-greetys font-rocheserif-italic">Greetys</span>-->
            </b-row>
            <b-row cols="12">
              <p class="slogan font-rochesans-light">Kreiere mit dem neuen Sticker-Online-Tool ganz persönliche
                Grußbotschaften an Deine Kunden.</p>
            </b-row>
            <b-row cols="12">
              <b-button class="font-rochesans-medium google-signin-button roche-blue-bg " @click="login()">per&nbsp;SSO&nbsp;anmelden</b-button>
            </b-row>
          </b-container>
        </div>
      </b-col>
      <b-col cols="4">
        <!--        <div class="right-hexagon-top">&nbsp;</div>-->
        <!--        <div class="right-hexagon-bottom">&nbsp;</div>-->
      </b-col>
      <b-col cols="2" class="right-col roche-blue">
        <div class="right-hexagon-top roche-blue-bg">&nbsp;</div>
        <div class="right-hexagon-bottom roche-blue-bg">&nbsp;</div>
      </b-col>
    </b-row>
  </b-container>
  <!--  </b-container>-->
</template>

<script>
import {mapGetters} from 'vuex'
import {HTTP} from '@/main';

export default {
  name: 'GoogleLogin',
  data() {
    return {
      isSignedIn: null,
    }
  },
  computed: {
    ...mapGetters({currentUser: 'currentUser'}, {isConfirmed: 'confirmed'}),
  },
  created() {
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {

    backendlogin() {

      // console.log('backendlogin');

      const user = this.$gapi.getUserData();

      // console.log('backendlogin', user);

      if (!user) {
        this.isSignedIn = null;
        return
      }

      // console.log('calling googlelogin', user.accessToken);

      HTTP.post('googlelogin', {token: user.accessToken})
          .then(request => {
            this.loginSuccessful(request)
            this.isSignedIn = true;
          }).catch((err) => {
        console.log('googlelogin::ERR', err);
        this.isSignedIn = null;
        this.loginFailed()
      })
    },

    login() {
      this.$gapi.login().then((success) => {
            this.isSignedIn = success;
            // console.log("google-login-successful");
            this.backendlogin(success);
          }
      ).catch(() => {
        this.isSignedIn = null;
      })
    },

    checkCurrentLogin() {
      // console.log('[Login] checkCurrentLogin', this.currentUser, this.$route.path);
      if (this.currentUser) {
        // console.log("confirmed:", this.isConfirmed, this.confirmed, localStorage.confirmed);
        if (localStorage.confirmed) {
          this.$router.replace(this.$route.query.redirect || '/main')
        } else {
          this.$router.replace(this.$route.query.redirect || '/confirm')
        }
      }
    },

    loginSuccessful(req) {
      // console.log('data: ', req.data)
      if (!req.data.access_token) {
        this.loginFailed()
        return
      }
      this.error = false
      localStorage.token = req.data.access_token

      // console.log('localStorage.token', localStorage.token);
      this.$store.dispatch('login')
      this.$router.replace(this.$route.query.redirect || '/confirm')
    },

    loginFailed() {
      // console.log('login-failed');
      this.error = 'Login failed!'
      this.$store.dispatch('logout')
      delete localStorage.token
    },
  },

  mounted() {
    this.checkCurrentLogin();
  },
}
</script>

<style lang="css" scoped>
.login-overlay {
  background: #FFFFFF;
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login {
  min-height: 100vh;
}

.right-col {
  margin: 0px;
  padding: 0px;
  border-left: 3px solid blue;
  /*height:100vh;*/
}

.left-col {
  margin: 0px;
  padding: 0px;
  background-color: white;
  /*height:100vh;*/
}

.roche-greetys {
  width: 100%;
  font-size: 88pt;
  /*background-image: url("../assets/lg_rochegreetys_1130.png");*/
  background-position: center;
  background-size: contain;
  display: block;
}

.right-hexagon-top {
  /*width: 2px;*/
  /*transform: rotate(-49deg);*/
  /*background-color: greenyellow;*/
  /*height: 75%;*/
  /*top: -200px;*/
  /*position: relative;*/
  /*left: -85px;*/
  /*background-color: greenyellow;*/
  margin: 0px;
  padding: 0px;
  height: 100vh;
  position: absolute;
  top: 50vh;
  left: -1px;
  width: 2px;
  transform: rotate(135deg);
  transform-origin: 0px 0px;
}

.right-hexagon-bottom {
  /*width: 5px;*/
  transform: rotate(45deg);
  /*background-color: red;*/
  z-index: 99;
  /*top: 50%;*/
  /*height: 25%;*/
  width: 2px;
  /*background-color: purple;*/
  margin: 0px;
  padding: 0px;
  height: 100vh;
  position: absolute;
  /*top: 50vh;*/
  left: -2px;
  transform-origin: 0px 0px;
  /*top: calc(50vh-2px);*/
  top: 50vh;
}

.full-height {
  /*min-height: 100%;*/
  /*height:auto !important; !* cross-browser *!*/
  /*height: 100%; !* cross-browser *!*/
  background-color: white;
  /*display: block;*/
  /*position: relative;*/
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.main-row {
  background-color: white;
  margin: 0px;
  padding: 0px;
  height: 100%;
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
}

/*.roche-logo {*/
/*  !*padding: 20px;*!*/
/*  margin-left: 36px;*/
/*  margin-top: 36px;*/
/*  !*width: 300px;*!*/
/*  height: 57px;*/
/*  !*width: 100%;*!*/
/*  background-image: url("../assets/roche_logo_blue.svg");*/
/*  background-position-y: center;*/
/*  background-size: contain;*/
/*  display: block;*/
/*  !*margin-left: 0px;*!*/
/*  background-position-x: 0px;*/
/*  min-width: 200px;*/
/*}*/

.logo-area {
  display: block;
  margin: 0px;
  padding: 0px;
  height: 110px;
  padding-top: 25px;
  width: 100%;
  position: absolute;
  left: 60px;
  top: 36px;
}

.roche-logo {
  height: 57px;
  background-position-y: bottom;
  background-size: contain;
  display: block;
  background-position-x: 0px;
  min-width: 83px;
}

.google-signin-button {
  color: white;
  margin: 0px;
  background-color: #0b41cd;
}

@media screen and (max-width: 800px) {
  .google-signin-button {
    font-size: 12px;
    padding: 10px 20px 10px 20px;
  }
}

@media screen and (min-width: 801px) {
  .google-signin-button {
    /*color: white;*/
    /*margin: 0px;*/
    padding: 20px 40px 20px 40px;
    /*font-weight: bolder;*/
    /*background-color: #0b41cd;*/
    /*font-size: 22pt;*/
    font-size: 1.5vw;
    /*width: 280px;*/
  }
}

.google-signin-button:hover {
  background-color: #022366;
}

@media screen and (min-width: 801px) {
  .slogan {
    /*font-size: 20pt;*/
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 600px) {
  .slogan {
    /*font-size: 20pt;*/
    font-size: 8px;
  }
}
</style>
