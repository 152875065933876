<template>
<b-container>Akkordion Administration</b-container>
</template>

<script>
export default {
  name: "AccordionAdmin"
}
</script>

<style scoped>

</style>