<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import VueKeypress from "vue-keypress";
import Html2Canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
//import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import {Editor, EditorContent} from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import {FontColor} from "./font-color.js";
// import {TextDecorationColor} from "./decoration-color_underline.js";
// import {TextDecoration} from "./decoration.js";
import FontFamily from "@tiptap/extension-font-family";
import ToolButton from "@/components/ToolButton";
import VueSlider from "vue-slider-component";
import "./slider.css";
// import {EnterHandler} from "./prevent-enter.js";
import {HTTP} from "@/main";

Vue.use(VueKonva);
// Vue.use(Html2Canvas);

export default {
  components: {
    VueKeypress,
    EditorContent,
    ToolButton,
    VueSlider,
  },
  // props: ["showBackground", "showPortrait", "showText"],
  props: {
    showBackground: {
      type: Boolean,
      required: false,
    },
    showPortrait: {
      type: Boolean,
      required: false,
    },
    showText: {
      type: Boolean,
      required: false,
    },
    // onSave: {
    //   type: Function,
    //   required: false,
    // },
  },
  // showBackground: Boolean,
  // showPortrait: Boolean,
  data() {
    const hexagonPoints = [
      // 0, 250, 200, 0, 700, 0, 900, 250, 700, 500, 200, 500,
      // 0, 200, 160, 0, 560, 0, 720, 200, 560, 400, 160, 400,
      // roche branding
      // 0, 281, 243, 0, 826, 0, 1069, 281, 826, 562, 243, 562,
      // 0, 281, 243, 0, 826, 0, 1069, 281, 826, 562, 243, 562,
      // 0, 140, 121, 0, 413, 0, 534, 140, 413, 281, 121, 281,
      0, 207, 180, 0, 620, 0, 800, 207, 620, 414, 182, 414,
    ];

    return {
      pixelRatio: 1,
      hexagonHeight: 414,
      hexagonWidth: 800,
      hexagonUUID: "",
      textMode: 0,
      imgBlobUrl: "",
      editorView: null,
      trashcan: null,
      toolbox: null,
      editorCanvas: null,
      editorStage: null,
      editorStageNode: null,
      portraitImage: null,
      editor1: null,
      editor2: null,
      editor3: null,
      editor4: null,
      textbox1Frame: null,
      textbox2Frame: null,
      textbox3Frame: null,
      textbox4Frame: null,
      minEditorCanvasWidth: 300,
      minEditorCanvasHeight: 300,
      backgroundImageOpacity: 100,
      configEditorStage: {
        width: 920,
        height: 800,
      },
      configHexagonEditor: {
        points: hexagonPoints, //[0, 250, 250, 0, 750, 0, 950, 250, 500, 750, 500, 250],
        fill: "#CC2244",
        stroke: "red",
        strokeWidth: 1,
        closed: true,
      },
      configPortraitImage: {
        // opacity: 1,
        visible: false,
        // image: null,
      },
      configEditorBackgroundImage: {
        // opacity: 0.5,
        visible: false,
        // image: null,
      },
      configFullImage: {
        // opacity: 0,
        visible: false,
        // image: null,
      },
      configGroupClip: {
        clipFunc: this.getClipShape,
      },
      configClipShape: {
        points: hexagonPoints,
        closed: true,
      },
      configTransformer: {
        keepRatio: true,
        rotateEnabled: false,
        enabledAnchors: [
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ],
        flipEnabled: false,
        borderDash: [2, 2],
      },
      configTextSelect: {
        listening: false,
        visible: false,
        dash: [3, 3],
        stroke: "lightblue",
        strokeWidth: 2,
      },
      // configText1: {
      //   x: 500,
      //   y: 500,
      //   text: "rendered",
      //   fontSize: 96,
      //   fontFamily: "Calibri",
      //   // align: "center",
      //   fill: "orange",
      // },
      configTextBox1: {
        x: 0,
        y: 280,
        image: null,
        visible: false,
      },
      configTextBox2: {
        x: 0,
        y: 410,
        image: null,
        visible: false,
      },
      configTextBox3: {
        x: 0,
        y: 690,
        image: null,
        visible: false,
      },
      configTextBox4: {
        x: 0,
        y: 280,
        image: null,
        visible: false,
      },
      configBackgroundLayer: {
        listening: false,
      },
      configPortraitLayer: {
        listening: false,
      },
      configTextLayer: {
        listening: false,
      },
      configTextbox1Edit: {},
      configTextbox2Edit: {},
      configTextbox3Edit: {},
      configTextbox4Edit: {},
      fcolor1: "white",
      fcolor2: "white",
      fcolor3: "white",
      fcolor4: "white",
      selectedColorScheme: "Roche",
      colorOne: "#0b41cd",
      colorTwo: "#bde3ff",
      colorThree: "#022366",
      withPortrait: false,
      withBackground: false,
      activeImage: null,
    };
  },
  methods: {
    canvasDrop(e) {
      var imgindex = 0;
      var image = "";
      const id = e.dataTransfer.getData("uuid");
      // console.log("Canvas Drop (", id, ")");
      // const imageLayer = parseInt(e.dataTransfer.getData("imageLayer"));
      // console.log("Canvas Drop for image layer:", imageLayer);
      const typ = id.split("/")[1];
      if (typ === "textfield") {
        const mode = parseInt(id.split("_")[1]);
        // console.log("Textmode = ", mode);
        this.$store.commit("setTextmode", mode);
        this.textMode = mode;
      } else {
        if (typ === "userimage") {
          imgindex = 1;
          image = this.portraitImage;
          this.withPortrait = true;
        } else {
          imgindex = 0;
          image = this.backgroundImage;
          this.withBackground = true;
        }
        this.$store.commit("setImageData", {
          which: imgindex + 1,
          visible: true,
          src: id,
        });
        this.loadImage(id, image, this.drawImage, true, 100);
      }
    },

    canvasDragenter(e) {
      const id = window.whatIsDragged;
      // console.log("[HexagonEditor] Canvas Dragenter ", e, window.whatIsDragged, "layer: ", window.imageLayer);
      if (id === "theme" || id === "userimage" || id === "textfield" || (window.imageLayer > 0 && window.imageLayer < 3)) {
        // console.log("allow drop");
        e.preventDefault();
      }
    },

    canvasDragover(e) {
      const id = window.whatIsDragged;
      if (id === "theme" || id === "userimage" || id === "textfield" || (window.imageLayer > 0 && window.imageLayer < 3)) {
        e.preventDefault();
      }
    },

    handleDragStart() {
      this.isDragging = true;
    },

    // handleDragEnd(e) {
    handleDragEnd() {
      // console.log("handleDragEnd", e,
      this.trashcan.getBoundingClientRect();
    },
    getCenter() {
      let r = document.getElementById("editorstage").getBoundingClientRect();
      return {x: r.width / 2, y: r.height / 2};
    },

    doApply() {
      const attr = this.activeImage.getAttrs();
      const c = this.getCenter();
      var which = 0;
      if (this.activeImage) {
        if (this.activeImage === this.portraitImage) which = 2;
        if (this.activeImage === this.backgroundImage) which = 1;
      }
      // console.log(
      //     "apply changes",
      //     which,
      //     attr.x - c.x,
      //     attr.y - c.y,
      //     attr.scaleX,
      //     attr.scaleY,
      //     attr.image.src
      // );
      this.$store.commit("setImageData", {
        which: which,
        x: attr.x - c.x,
        y: attr.y - c.y,
        scaleX: attr.scaleX,
        scaleY: attr.scaleY,
        visible: attr.visible,
        //src: attr.image.src,
      });
      this.stopTransform();
      if (this.isEditing) {
        this.stopEditing();
      }
    },

    doCancel() {
      // console.log("cancel changes");

      if (this.activeImage) {
        this.activeImage.setAttrs({
          x: this.TransformStartX,
          y: this.TransformStartY,
          scaleX: this.TransformStartScaleX,
          scaleY: this.TransformStartScaleY,
        });
      }

      this.stopTransform();

      if (this.isEditing) {
        this.stopEditing();
      }
    },

    doDelete() {
      if (this.isEditing) {
        return
      }

      // console.log("delete image");

      this.doCancel();
      if (this.activeImage) {
        if (this.activeImage == this.portraitImage) {
          this.withPortrait = false;
          this.portraitImage.opacity(0);
        }

        if (this.activeImage == this.backgroundImage) {
          this.withBackground = false;
          this.backgroundImage.opacity(0);
        }
      }
    },

    // handleTransformEnd(e) {
    handleTransformEnd() {
      // console.log("transform Done." + e);
    },

    populateFullImage(vImage) {
      if (this.fullImage) {
        this.fullImage.setAttrs({
          image: vImage.image(),
          x: vImage.x(),
          y: vImage.y(),
          width: vImage.width(),
          height: vImage.height(),
          scaleX: vImage.scaleX(),
          scaleY: vImage.scaleY(),
          opacity: 0.25,
          visible: true,
        });
      } else {
        // console.log("no fullImage");
      }
    },
    startPortraitTransform() {
      this.backgroundImage.listening(false);
      this.portraitImage.listening(true);

      this.backgroundLayer.listening(false);
      this.portraitLayer.listening(true);

      // console.log("P", this.portraitImage.listening(), "bg", this.backgroundImage.listening());
    },
    startBackgroundTransform() {
      this.portraitImage.listening(false);
      this.backgroundImage.listening(true);

      this.backgroundLayer.listening(true);
      this.portraitLayer.listening(false);

      // console.log("P", this.portraitImage.listening(), "bg", this.backgroundImage.listening());
    },
    handleDragMove() {
      // console.log("image drag and move");
      if (!this.activeImage || !this.fullImage) {
        return;
      }
      this.fullImage.setAttrs({
        x: this.activeImage.x(),
        y: this.activeImage.y(),
      });
    },
    handleFullImageDragMove() {
      // console.log("fullImage drag and move");
      if (!this.activeImage || !this.fullImage) {
        return;
      }
      this.activeImage.setAttrs({
        x: this.fullImage.x(),
        y: this.fullImage.y(),
      });
    },
    handleTransform() {
      if (!this.activeImage || !this.fullImage) {
        return;
      }
      this.fullImage.setAttrs({
        x: this.activeImage.x(),
        y: this.activeImage.y(),
        scaleY: this.activeImage.scaleY(),
        scaleX: this.activeImage.scaleX(),
      });
    },
    startTransform(vImage) {
      this.TransformStartX = vImage.x();
      this.TransformStartY = vImage.y();
      this.TransformStartScaleX = vImage.scaleX();
      this.TransformStartScaleY = vImage.scaleY();

      vImage.draggable(true);
      vImage.listening(true);
      this.fullImage.draggable(true);
      this.activeImage = vImage;
      this.editorTransformer.nodes([vImage]);
    },
    stopTransform() {
      this.fullImage.setAttrs({visible: false, draggable: false});
      this.editorTransformer.nodes([]);
      if (this.activeImage) {
        this.activeImage.draggable(false);
        this.activeImage = null;
      }
      this.backgroundImage.listening(true);
      this.portraitImage.listening(true);
      // this.textbox1Image.listening(true);
      // this.textbox2Image.listening(true);
      // this.textbox3Image.listening(true);
      // this.textbox4Image.listening(true);
    },
    getClipShape(ctx) {
      if (this.clipShapes && this.clipShapes.length > 0) {
        this.clipShapes[0].drawScene(ctx.canvas);
      }
    },
    handleStageDblClick(event) {
      // const stage = event.target;
      // console.log("stage clicked on: ", stage.getPointerPosition());

      for (var i = 0; i < this.hitOrder.length; i++) {
        if (this.doHitCheck(event, this.hitOrder[i])) {
          switch (i) {
            case 0:
              // console.log("dbl hitlayer: text");
              this.getTextHit(this.editorStageNode, true);
              break;
            case 1:
              // console.log("activate: portrait");
              this.textSelect.visible(false);
              if (this.activeImage) {
                if (this.activeImage == this.portraitImage) {
                  this.doApply();
                  return;
                } else {
                  this.doCancel();
                }
              }
              this.stopTransform();
              this.startPortraitTransform();
              this.populateFullImage(this.portraitImage);
              this.startTransform(this.portraitImage);
              break;
            case 2:
              // console.log("hitlayer: bg");
              this.textSelect.visible(false);
              if (this.withBackground) {
                if (this.activeImage) {
                  if (this.activeImage == this.backgroundImage) {
                    this.doApply();
                    return;
                  } else {
                    this.doCancel();
                  }
                }
              } else {
                // console.log("hitlayer: bg ==> portrait");
                if (this.activeImage) {
                  if (this.activeImage == this.portraitImage) {
                    this.doApply();
                    return;
                  } else {
                    this.doCancel();
                  }
                }
              }
              this.stopTransform();
              if (this.withBackground) {
                this.startBackgroundTransform();
                this.populateFullImage(this.backgroundImage);
                this.startTransform(this.backgroundImage);
              } else {
                this.startPortraitTransform();
                this.populateFullImage(this.portraitImage);
                this.startTransform(this.portraitImage);
              }
              break;
          }
          break;
        }
      }
    },

    // handleStageClick(event) {
    handleStageClick() {
      let hadHit = false;

      // const shape = event.target;
      // console.log("handleStageClick", shape);
      // console.log(
      //     "stage clicked on: ",
      //     this.editorStageNode.getPointerPosition()
      // );
      this.editorStageNode.getPointerPosition();

      for (let i = 0; i < this.hitOrder.length; i++) {
        if (this.doHitCheck(event, this.hitOrder[i])) {
          switch (i) {
            case 0:
              hadHit = true;
              // console.log("hitlayer: text");
              this.getTextHit(this.editorStageNode, false);
              // this.drawTextSelectBox(shape);
              break;
            case 1:
              hadHit = true;
              // console.log("hitlayer: portrait");
              this.textSelect.visible(false);

              this.activeEditor = null;
              // this.handleTextBlur();
              break;
            case 2:
              hadHit = true;
              // console.log("hitlayer: bg");
              this.textSelect.visible(false);
              this.activeEditor = null;
              // this.handleTextBlur();
              break;
          }
          break;
        }
      }
      if (!hadHit) {
        if (!this.activeImage) {
          // console.log("no hit!");
          this.textSelect.visible(false);
          this.handleTextBlur();
        }
      }
    },

    handleBGOpacity(inOpacity) {
      // console.log("handleBGOpacity", inOpacity);
      this.$store.commit("setBackground", {bgOpacity: inOpacity});

      this.backgroundImage.setAttrs({
        // visible: true,
        opacity: inOpacity / 100,
      });
    },

    getTextHit(stage, doEdit) {
      // console.log(this.textLayer);
      // console.log(this.textGroup);

      var selected = false;
      const hitX = stage.getPointerPosition().x;
      const hitY = stage.getPointerPosition().y;

      const textImages = this.textGroup.children; //find("Image");

      // console.log("Images: ", textImages.length, textImages, hitX, hitY);

      for (var i = 0; i < textImages.length; i++) {
        if (
            hitX >= textImages[i].x() &&
            hitX <= textImages[i].x() + textImages[i].width() &&
            hitY >= textImages[i].y() &&
            hitY <= textImages[i].y() + textImages[i].height()
        ) {
          // console.log("found textImage: ", textImages[i]);
          this.drawTextSelectBox(textImages[i]);
          this.selectedText = textImages[i];
          selected = true;
          break;
        }
      }
      if (!selected) {
        this.textSelect.visible(false);
        this.selectedText = null;
      }
      if (selected && doEdit) {
        this.startEditing(textImages[i]);
      }
    },

    drawTextSelectBox(textImageShape) {
      // console.log(
      //     this.textSelect.visible(),
      //     textImageShape.x(),
      //     textImageShape.y(),
      //     textImageShape.width(),
      //     textImageShape.height()
      // );

      this.textSelect.setAttrs({
        visible: true,
        x: textImageShape.x(),
        y: textImageShape.y(),
        width: textImageShape.width(),
        height: textImageShape.height(),
        scaleY: textImageShape.scaleY(),
        scaleX: textImageShape.scaleX(),
      });

      // console.log(
      //     this.textSelect.visible(),
      //     this.textSelect.x(),
      //     this.textSelect.y(),
      //     this.textSelect.width(),
      //     this.textSelect.height()
      // );
    },

    startEditing(textImage) {
      this.doCancel();

      if (this.isEditing) {
        this.stopEditing();
      }

      // console.log("startEditing", textImage, textImage.refName);

      textImage.visible(false);

      this.domEdit.style.visibility = "unset";

      this.domEdit.innerHTML = textImage.innerHTML;
      this.domEdit.className = "textboxedit " + textImage.className;

      var rectObject = this.$el.getBoundingClientRect();

      this.domEdit.style.position = "absolute";
      this.domEdit.style.top =
          textImage.y() + this.editorStageNode.y() + rectObject.top + "px";
      this.domEdit.style.left = textImage.x() + "px";
      // this.domEdit.style.left = this.editorStageNode.y() + "px";
      // this.domEdit.style.width = this.editorStageNode.width() + "px";

      this.domEdit.contentEditable = true;

      this.domEdit.focus();

      var rangeobj = document.createRange();
      var selectobj = window.getSelection();

      rangeobj.setStart(this.domEdit, 0);
      rangeobj.setEnd(this.domEdit, 0);
      rangeobj.collapse(true);

      selectobj.removeAllRanges();
      selectobj.addRange(rangeobj);

      this.isEditing = true;
      this.activeTextImageRef = textImage.refName;
    },
    /*
    stopEditing() {
      const innerHTML = this.domEdit.innerHTML;

      this.textSelect.visible(false);

      console.log("stopEditing: ", this.activeTextImageRef, innerHTML);

      var activeTextBox = this.$refs[this.activeTextImageRef];
      if (!activeTextBox) {
        return;
      }
      var activeTextBoxShape = activeTextBox.getNode();

      console.log("render text ", innerHTML, " to ", activeTextBoxShape);

      this.renderText(
        [activeTextBoxShape],
        innerHTML,
        activeTextBoxShape.className
      );

      // this.activeTextBoxShape.innerHTML = innerHTML;

      this.domEdit.style.position = "unset";
      this.domEdit.style.visibility = "hidden";
      this.domEdit.contentEditable = false;

      // activeTextBoxShape.opacity(1);
      activeTextBoxShape.setAttrs({
        visible: true,
      });

      this.isEditing = false;
      // this.activeTextImagRef = "";
    },
    */
    handleBackgroundLayerClick(event) {
      let shape = event.target;
      // console.log("background clicked on ", shape);

      this.doHitCheck(event, shape);

      // const shapeHit = this.doHitCheck(event, shape);
      // if (shapeHit) {
      //   console.log("bg has pixel hit", shape);
      // } else {
      //   console.log("blank bg hit");
      // }
    },

    // handleTextLayerClick(event) {
    //   var shape = event.target;
    //   // console.log("text clicked on ", shape);
    //
    //   const shapeHit = this.doHitCheck(event, shape);
    //   if (shapeHit) {
    //     console.log("text has pixel hit", shape);
    //   } else {
    //     console.log("text bg hit");
    //   }
    //
    //   // if (shape.hitShadowLayer) {
    //   //   const shapeHit = this.doShadowHitCheck(event, shape);
    //   //   if (shapeHit) {
    //   //     console.log("text has pixel hit", shape);
    //   //   } else {
    //   //     console.log("text bg hit");
    //   //   }
    //   // }
    // },

    // handlePortraitLayerClick(event) {
    //   var shape = event.target;
    //   console.log("portrait clicked on ", shape);
    //
    //   // if (shape.hitShadowLayer) {
    //   //   const shapeHit = this.doShadowHitCheck(event, shape);
    //   //   if (shapeHit) {
    //   //     console.log("images has pixel hit", shape);
    //   //   } else {
    //   //     console.log("image bg hit");
    //   //   }
    //   // }
    //   const shapeHit = this.doHitCheck(event, shape);
    //   if (shapeHit) {
    //     console.log("portrait has pixel hit", shape);
    //   } else {
    //     console.log("portrait bg hit");
    //   }
    // },

    doHitCheck(event, shape) {
      const shapeCanvas = shape.getCanvas().getContext("2d");

      const dpr = shape.getCanvas().pixelRatio;

      // console.log(
      //     "doHitCheck",
      //     "dpr:",
      //     dpr,
      //     "x",
      //     event.evt.layerX,
      //     "y",
      //     event.evt.layerY,
      //     "color:",
      //     shapeCanvas.getImageData(
      //         event.evt.layerX * dpr,
      //         event.evt.layerY * dpr,
      //         1,
      //         1
      //     ).data
      // );

      let layerX = 0;
      let layerY = 0;

      if (event && event.evt) {
        layerX = event.evt.layerX ? event.evt.layerX : 0;
        layerY = event.evt.layerY ? event.evt.layerY : 0;
      }

      let colorRGBA = shapeCanvas.getImageData(
          layerX * dpr,
          layerY * dpr,
          1,
          1
      ).data;

      let shapeHit = false;

      for (let i = 0; i < 4; i++) {
        shapeHit = colorRGBA[i] > 0;
        if (shapeHit) {
          break;
        }
      }
      return shapeHit;
    },

    // doShadowHitCheck(event, vImage) {
    //   var srcCanvas = vImage.getCanvas().getContext("2d");
    //   var shadowCanvas = vImage.hitShadowLayer.getCanvas().getContext("2d");
    //
    //   // console.log("c:", shadowCanvas);
    //
    //   // console.log(
    //   //     "color:",
    //   //     event.evt.layerX,
    //   //     event.evt.layerY,
    //   //     srcCanvas.getImageData(event.evt.layerX, event.evt.layerY, 1, 1).data,
    //   //     shadowCanvas.getImageData(event.evt.layerX, event.evt.layerY, 1, 1).data
    //   // );
    //
    //   var colorRGBA = shadowCanvas.getImageData(
    //       event.evt.layerX,
    //       event.evt.layerY,
    //       1,
    //       1
    //   ).data;
    //
    //   var shapeHit = false;
    //
    //   for (var i = 0; i < 4; i++) {
    //     shapeHit = colorRGBA[i] > 0;
    //     if (shapeHit) {
    //       break;
    //     }
    //   }
    //   return shapeHit;
    // },

    async serverLoad(src) {
      const config = {url: src, method: "get", responseType: "blob"};
      const response = await HTTP.request(config);
      // console.log("blob: ", response.data);
      return response.data;
    },

    // loadImage(url, vImage, callback, isNew, opacity) {
    loadImage(url, vImage, callback, isNew) {
      // console.log("[hexagonEditor] Load Image ", url, opacity);
      url += '?full=1'
      //domImage.src = 'restricted/userimage/'+url;
      this.serverLoad(url)
          .then((blob) => {
            this.imgBlobUrl = URL.createObjectURL(blob);
            const domImage = new window.Image();
            domImage.src = this.imgBlobUrl;
            // console.log("img: ", domImage.src);
            domImage.onload = () => {
              // console.log(
              //     "image " + url + " loaded.",
              //     domImage.width,
              //     domImage.height
              // );
              vImage.setAttrs({
                image: domImage,
                // opacity: opacity,
              });
              callback(vImage, isNew);
              if (this.imgBlobUrl) URL.revokeObjectURL(this.imgBlobUrl);
            };
          })
          .catch((err) => {
            console.log("load error: ", err);
          });
    },

    drawImage(vImage, isNew) {
      // console.log("draw image");
      var bgOpacity = 100;
      let which = 2;
      const c = this.getCenter();
      if (vImage === this.backgroundImage) {
        which = 1;
        bgOpacity = this.$store.getters.getData.bgOpacity;
      }
      // console.log("Draw Image ", this.$store.getters.getData.images[which - 1]);
      if (isNew || (this.$store.getters.getData.images[which - 1].scaleX == 0)) {
        this.centerAndScaleToFit(vImage);
        const attr = vImage.getAttrs();
        this.$store.commit("setImageData", {
          which: which,
          x: attr.x - c.x,
          y: attr.y - c.y,
          scaleX: attr.scaleX,
          scaleY: attr.scaleY,
          visible: true,
        });
      } else {
        var d = null;
        d = this.$store.getters.getData.images[which - 1];
        if (d) {
          vImage.setAttrs({
            scaleX: d.scaleX,
            scaleY: d.scaleY,
            x: d.x + c.x,
            y: d.y + c.y,
          });
        }
      }

      // console.log("render-image show with opacity", vImage.getAttrs(), this.getCenter(), bgOpacity);
      vImage.setAttrs({
        visible: true,
        opacity: bgOpacity / 100,
      });
    },

    centerImage(vImage) {
      // console.log();

      const imgWidth = vImage.width();
      const hexagonWidth = this.hexagon.width();

      const newX =
          this.hexagon.x() +
          parseInt((hexagonWidth / 2).toFixed()) -
          parseInt((imgWidth / 2).toFixed());

      vImage.x(newX);
    },

    centerAndScaleToFit(vImage) {
      if (!this.hexagon) {
        return;
      }
      let imgScaleX = this.hexagon.width() / vImage.width();
      let imgScaleY = this.hexagon.height() / vImage.height();

      if (imgScaleX > imgScaleY) {
        imgScaleY = imgScaleX;
      } else {
        imgScaleX = imgScaleY;
      }

      let newX = this.hexagon.x();
      let newY = this.hexagon.y();

      const newWidth = parseInt((vImage.width() * imgScaleX).toFixed());
      const newHeight = parseInt((vImage.height() * imgScaleY).toFixed());

      if (newWidth > this.hexagon.width()) {
        newX =
            parseInt(((this.hexagon.width() - newWidth) / 2).toFixed()) + newX;
      }

      if (newHeight > this.hexagon.height()) {
        newY =
            parseInt(((this.hexagon.height() - newHeight) / 2).toFixed()) + newY;
      }

      vImage.setAttrs({
        scaleX: imgScaleX,
        scaleY: imgScaleY,
        x: newX,
        y: newY,
      });
    },

    // getSelection(src) {
    //   const start = src.selectionStart;
    //   const end = src.selectionEnd;
    //   // console.log("getSelection", start, end);
    //   return window.getSelection().toString();
    //   // return src.value.substring(start, end);
    // },

    handleEditBlur(e) {
      // console.log("handleEditBlur", e, e.editor);
      let content = "";
      let field = 0;

      const id = e.editor.options.element.id;
      if (this.activeEditor) {
        if (id === "textbox1Edit") field = 1;
        if (id === "textbox2Edit") field = 2;
        if (id === "textbox3Edit") field = 3;
        if (id === "textbox4Edit") field = 4;
        content = this.activeEditor.getHTML();
        this.$store.commit("setEditData", {which: field, content: content});
      }
      // console.log("JSON: ", content);
      // const name = e.target.value;
      // console.log("hanldeEditBlur", name, window.getSelection().toString());
      // this.selectedText = window.getSelection().toString();
      // console.log("sel-range", window.getSelection().getRangeAt(0));
    },

    // handleTextboxClick(e) {
    handleTextboxClick() {
      // console.log("Textbox Click", e);
      //e.srcElement.childNodes[0].setContent("xxx");
    },

    handleEditFocus(e) {
      // console.log("handleEditFocus", e, e.editor);
      // const name = e.target.value;
      // console.log("hanldeEditBlur", name, window.getSelection().toString());
      // this.selectedText = window.getSelection().toString();
      // console.log("sel-range", window.getSelection().getRangeAt(0));

      this.activeEditor = e.editor;
      this.doCancel();

      let edit1 = this.$refs.textbox1Edit;
      let edit2 = this.$refs.textbox2Edit;
      let edit3 = this.$refs.textbox3Edit;
      let edit4 = this.$refs.textbox4Edit;

      if (edit1.editor !== this.activeEditor) {
        edit1.editor.commands.setTextSelection(1);
      }

      if (edit2.editor !== this.activeEditor) {
        edit2.editor.commands.setTextSelection(1);
      }

      if (edit3.editor !== this.activeEditor) {
        edit3.editor.commands.setTextSelection(1);
      }

      if (edit4.editor !== this.activeEditor) {
        edit4.editor.commands.setTextSelection(1);
      }
    },

    // handleColorScheme(scheme) {
    //   console.log("[handleColorScheme]", scheme);
    //   this.$store.commit("setColorScheme", {colorScheme: scheme});
    //   // this.$store.commit("setBackground", {colorScheme: scheme});
    // },

    handleColor(color) {
      let id;
      // console.log("handleColor", color);

      if (!this.activeEditor) {
        return;
      }
      // console.log("editor: ", this.activeEditor);
      //this.activeEditor.chain().focus();

      this.activeEditor
          .chain()
          .focus()
          .setFontColor(this.translateColor(color))
          .run();

      id = this.activeEditor.contentComponent.$el.id;

      if (id === "textbox1Edit") {
        this.$store.commit("changeTextField", {which: 1, color: color});
      }
      if (id === "textbox2Edit") {
        this.$store.commit("changeTextField", {which: 2, color: color});
      }
      if (id === "textbox3Edit") {
        this.$store.commit("changeTextField", {which: 3, color: color});
      }

      if (id === "textbox4Edit") {
        this.$store.commit("changeTextField", {which: 4, color: color});
      }

      // console.log('setTextDecorationColor', this.translateColor(color));
      //
      // this.activeEditor.chain().focus().setTextDecorationColor(this.translateColor(color)).run();

      // console.log("handleColor", color);
      return;
      // console.log(
      //   "handleColor",
      //   this.selectedText,
      //   this.isEditing,
      //   "sel",
      //   this.getSelection(this.domEdit)
      // );
      /*
      if (this.selectedText) {
        if (!this.isEditing) {
          var innerHTML = this.selectedText.innerHTML;

          console.log("handleColor", innerHTML, this.selectedText);

          this.selectedText.visible(false);

          this.domEdit.style.color = color;

          this.renderText(
            [this.selectedText],
            innerHTML,
            this.selectedText.className
          );
          return;
        }
      } else {
        console.log("change edit color");
      }
      // console.log(window.getSelection().toString());
      console.log("change edit color", color);
      // document.execCommand('forecolor', false, color);
      var oldInner = this.domEdit.innerHTML;
      const selection = this.selectedText;
      var newInner = oldInner.replace(
        selection,
        "<span style='color:" + color + "'>" + selection + "</span>"
      );
      this.domEdit.innerHTML = newInner;
      this.domEdit.focus();
      */
    },

    handleStartBackgroundTransformBttn() {
      // console.log("handleStartBackgroundTransformBttn", this.withBackground);
      if (this.withBackground) {
        this.textSelect.visible(false);
        if (this.activeImage) {
          if (this.activeImage !== this.backgroundImage) {
            this.doCancel();
          }
        }
        this.stopTransform();
        this.startBackgroundTransform();
        this.populateFullImage(this.backgroundImage);
        this.startTransform(this.backgroundImage);
      }
    },

    // handleOKBackgroundTransformBttn() {
    //   console.log("handleStartPortraitTransformBttn", this.withBackground);
    //   if (this.withBackground) {
    //     this.textSelect.visible(false);
    //     if (this.activeImage) {
    //       if (this.activeImage == this.backgroundImage) {
    //         this.doApply();
    //       }
    //     }
    //   }
    // },

    handleCancelBackgroundTransformBttn() {
      // console.log("handleCancelPortraitTransformBttn", this.withBackground);
      if (this.withBackground) {
        this.textSelect.visible(false);
        if (this.activeImage) {
          if (this.activeImage === this.backgroundImage) {
            this.doCancel();
          }
        }
      }
    },

    handleBackgroundDeleteBttn() {
      this.handleCancelBackgroundTransformBttn();
      this.handleDeleteBackgroundBttn();
    },

    handleDeleteBackgroundBttn() {
      // console.log("handleDeleteBackgroundBttn (enter)", this.withBackground);
      this.withBackground = false;
      this.backgroundImage.opacity(0);
      // console.log("handleDeleteBackgroundBttn (exit)", this.withBackground);
    },

    handleStartPortraitTransformBttn() {
      // console.log("handleStartPortraitTransformBttn", this.withPortrait);
      if (this.withPortrait) {
        this.textSelect.visible(false);
        if (this.activeImage) {
          if (this.activeImage !== this.portraitImage) {
            this.doCancel();
          }
        }
        this.stopTransform();
        this.startPortraitTransform();
        this.populateFullImage(this.portraitImage);
        this.startTransform(this.portraitImage);
      }
    },

    handleOKPortraitTransformBttn() {
      // console.log("handleStartPortraitTransformBttn", this.withPortrait);
      if (this.withPortrait) {
        this.textSelect.visible(false);
        if (this.activeImage) {
          if (this.activeImage === this.portraitImage) {
            this.doApply();
          }
        }
      }
    },

    handleCancelPortraitTransformBttn() {
      // console.log("handleCancelPortraitTransformBttn", this.withPortrait);
      if (this.withPortrait) {
        this.textSelect.visible(false);
        if (this.activeImage) {
          if (this.activeImage === this.portraitImage) {
            this.doCancel();
          }
        }
      }
    },

    handlePortraitDeleteBttn() {
      this.handleCancelPortraitTransformBttn();
      this.handleDeletePortraitBttn();
    },

    handleDeletePortraitBttn() {
      // console.log("handleDeletePortraitBttn (enter)", this.withPortrait);
      this.withPortrait = false;
      this.portraitImage.opacity(0);
      this.$store.commit("setImageData", {
        which: 2,
        visible: false,
        src: null,
      });
      // console.log("handleDeletePortraitBttn (exit)", this.withPortrait);
    },

    showTextBorder(doShow) {
      // console.log("showTextBorder", doShow);

      const editorNames = [
        "textbox1Edit",
        "textbox2Edit",
        "textbox3Edit",
        "textbox4Edit",
      ];

      for (let i = 0; i < editorNames.length; i++) {
        let editorDIV = document.getElementById(editorNames[i]);
        if (editorDIV) {
          if (doShow) {
            editorDIV.style.borderWidth = "2px";
            editorDIV.style.borderColor = "lightblue";
            editorDIV.style.borderStyle = "dashed";
          } else {
            editorDIV.style.borderWidth = "2px";
            editorDIV.style.borderColor = "rgba(0,0,0,0)";
          }
        }
      }

      // if (this.editor1) {
      //   // document.getElementById("textbox1Edit").style.border = '10px solid yellow;';

      //   var editorDIV = document.getElementById("textbox1Edit");
      //   if
      //   editorDIV.style.borderWidth = '2px';
      //   editorDIV.style.borderColor = 'lightblue';
      //   editorDIV.style.borderStyle = 'dashed';

      //   // console.log("showTextBorder", document.getElementById("textbox1Edit"), ">>", document.getElementById("textbox1Edit").style.border);
      // }

      // if (this.editor2) {
      //   document.getElementById("textbox2Edit").style.border = borderStyle;
      // }

      // if (this.editor3) {
      //   document.getElementById("textbox3Edit").style.border = borderStyle;
      // }
    },

    // handleEditClick(e) {
    handleEditClick() {
      // console.log("handleEditClick", e);
    },

    handleBGColor(color) {
      // console.log("handleBGColor", this.hexagon.fill(), color, this.translateColor(color));
      this.$store.commit("setBackground", {bgColor: color});
      this.hexagon.fill(this.translateColor(color));
      this.hexagon.stroke(this.translateColor(color));
    },

    // handleColorScheme(scheme) {
    //   console.log("[handleColorScheme]", scheme);
    //   this.$store.commit("setColorScheme", {colorScheme: scheme});
    //
    //   // this.toggleColorScheme(scheme);
    //
    //   const color = this.$store.getters.getData.bgColor;
    //   const storeScheme = this.$store.getters.getData.colorScheme;
    //
    //   console.log("[handleColorScheme]", storeScheme, " with bgcolor", color, this.translateColor(color));
    //
    //   this.hexagon.fill(this.translateColor(color));
    //   this.hexagon.stroke(this.translateColor(color));
    // },

    translateColor(inColor) {
      let outColor = "white";
      // switch (inColor) {
      //   case "petrol":
      //     outColor = "rgba(84,199,217,1)";
      //     break;
      //   case "darkgray":
      //     outColor = "rgba(70,93,110,1)";
      //     break;
      //   case "darkred":
      //     outColor = "rgba(164,0,35,1)";
      //     break;
      // }

      switch (inColor) {
        case 1:
          outColor = this.colorOne;
          break;
        case 2:
          outColor = this.colorTwo;
          break;
        case 3:
          outColor = this.colorThree;
          break;
        default:
          outColor = "white";
      }

      return outColor;
    },

    renderTextManual(vImages, innerHTML, className, x, y, width) {
      this.domEdit.innerHTML = innerHTML;
      this.domEdit.className = "textboxedit " + className;
      this.domEdit.style.width = width + "px";
      // this.domEdit.style.left = x + "px";
      // this.domEdit.style.top = y + "px";

      Html2Canvas(this.domEdit, {
        backgroundColor: "rgba(0,0,0,0)",
        onclone: function (clonedDoc) {
          let renderDIV = clonedDoc.getElementById("domEdit");
          renderDIV.style.visibility = "unset";
        },
      }).then((canvas) => {
        for (let i = 0; i < vImages.length; i++) {
          vImages[i].setAttrs({
            image: canvas,
            opacity: 1,
            visible: true,
          });
          // this.centerImage(vImages[i]);
          vImages[i].innerHTML = innerHTML;
          vImages[i].className = className;
          vImages[i].setAttrs({
            x: x,
            y: y,
            width: width,
          });
        }
      });
    },
    /*
        async renderText(vImage, source) {
          //const src = document.createElement("h1");
          //const txt = document.createTextNode("Test");
          //src.appendChild(txt);
          //document.body.appendChild(src);
          let canvas = await Html2Canvas(source, {
            backgroundColor: "rgba(0,0,0,0)",
          });
          console.log("textbox canvas",vImage, canvas,source)
          return canvas;
          //     .then((canvas) => {
          //   // vImage.setAttrs({
          //   //   image: canvas,
          //   //   opacity: 1,
          //   //   visible: true,
          //   // });
          //
          // });
        },
    */
    async renderText(vImage, source) {
      //const src = document.createElement("h1");
      //const txt = document.createTextNode("Test");
      //src.appendChild(txt);
      //document.body.appendChild(src);
      //const fontEmbedCSS = await htmlToImage.getFontEmbedCSS(source);
      const fontEmbedCss = await htmlToImage.getFontEmbedCSS(source);
      let canvas = await htmlToImage.toCanvas(source, {
        backgroundColor: "rgba(0,0,0,0)", fontEmbedCSS: fontEmbedCss,
      });
      // console.log("textbox canvas", vImage, canvas, source)
      return canvas;
      //     .then((canvas) => {
      //   // vImage.setAttrs({
      //   //   image: canvas,
      //   //   opacity: 1,
      //   //   visible: true,
      //   // });
      //
      // });
    },

    appendClipShape(kNode) {
      if (!this.clipShapes) {
        this.clipShapes = new Array();
      }
      this.clipShapes.push(kNode);
    },

    handleTextBlur() {
      // console.log("handleTextBlur", "from stage!");

      this.activeEditor = null;
      // if (!this.activeImage) {
      //   this.doCancel();
      // }

      if (this.editor1) {
        this.editor1.commands.setTextSelection(0);
        this.editor1.commands.focus(0);
        this.editor1.commands.blur();
      }

      if (this.editor2) {
        this.editor2.commands.setTextSelection(0);
        this.editor2.commands.focus(0);
        this.editor2.commands.blur();
      }

      if (this.editor3) {
        this.editor3.commands.setTextSelection(0);
        this.editor3.commands.focus(0);
        this.editor3.commands.blur();
      }
    },

    handleTextToggle(ops) {
      // console.log("handleTextToggle", ops);

      if (!this.activeEditor) {
        return;
      }

      // var edit1 = this.$refs.textbox1Edit;
      // console.log(edit1.editor.isFocused);

      // var edit2 = this.$refs.textbox2Edit;
      // console.log(edit2.editor.isFocused);

      // var edit3 = this.$refs.textbox3Edit;
      // console.log(edit3.editor.isFocused);

      switch (ops) {
        case "clear":
          this.activeEditor.chain().focus().unsetBold().run();
          this.activeEditor.chain().focus().unsetItalic().run();
          this.activeEditor.chain().focus().unsetUnderline().run();
          break;
        case "bold":
          this.activeEditor.chain().focus().toggleBold().run();
          break;
        case "italic":
          this.activeEditor.chain().focus().toggleItalic().run();
          break;
        case "underline":
          this.activeEditor.chain().focus().toggleUnderline().run();
          // console.log(this.activeEditor.chain().focus().style.color);
          // this.activeEditor.chain().focus().setTextDecorationColor('underline').run();
          break;
        default:
          this.activeEditor
              .chain()
              .focus()
              .setFontFamily(this.translateFont(ops))
              .run();
          break;
      }

      // this.highlightToolButton(ops);
    },

    // highlightToolButton(ops) {
    //   console.log("highlightToolButton", ops);
    //
    //
    //   // this.$refs.toolboxBttnNormal.classList.toggle("toolbox-btn-selected");
    //   // this.$refs.toolboxBttnBold.classList.toggle("toolbox-btn-selected");
    //   // this.$refs.toolboxBttnItalic.classList.toggle("toolbox-btn-selected");
    //   // this.$refs.toolboxBttnUnderline.classList.toggle("toolbox-btn-selected");
    //   switch (ops) {
    //     case "clear":
    //       // this.$refs.toolboxBttnNormal.classList.add("toolbox-btn-selected");
    //       this.$refs.toolboxBttnNormal.classList.toggle("toolbox-btn-selected");
    //       break;
    //     case "bold":
    //       // this.$refs.toolboxBttnBold.classList.add("toolbox-btn-selected");
    //       this.$refs.toolboxBttnBold.classList.toggle("toolbox-btn-selected");
    //       break;
    //     case "italic":
    //       // this.$refs.toolboxBttnItalic.classList.add("toolbox-btn-selected");
    //       this.$refs.toolboxBttnItalic.classList.toggle("toolbox-btn-selected");
    //       break;
    //     case "underline":
    //       // this.$refs.toolboxBttnUnderline.classList.add("toolbox-btn-selected");
    //       this.$refs.toolboxBttnUnderline.classList.toggle("toolbox-btn-selected");
    //       break;
    //   }
    // },

    translateFont(inFont) {
      let outFont = "RocheSansCondensed";

      switch (inFont) {
        case "sans":
          outFont = "RocheSansCondensed";
          break;
        case "serif":
          outFont = "RocheSerif";
          break;
      }

      // console.log("[outFont]", outFont)

      return outFont;
    },

    doResizeEditor() {
      // console.log('doResizeEditor', this.editorView, this.trashcan);

      if (!this.editorView) {
        return;
      }

      const editorWidth = this.editorView.clientWidth;
      const editorHeight = this.editorView.clientHeight;

      // console.log('doResizeEditor', editorWidth, editorHeight);

      // let trachcanLeft = editorWidth - 450;
      // if (trachcanLeft < 0) {
      //   trachcanLeft = 0;
      // }
      //
      // let trachcanTop = editorHeight - 124;
      // if (trachcanTop < 0) {
      //   trachcanTop = 0;
      // }
      //
      // this.trashcan.style.top = trachcanTop + "px";
      // this.trashcan.style.left = trachcanLeft + "px";

      let rightSpace = 380;

      let toolboxLeft = editorWidth - rightSpace;

      if (toolboxLeft <= this.minEditorCanvasWidth) {
        toolboxLeft = this.minEditorCanvasWidth + 20;
      }

      // console.log('doResizeEditor', 'toolboxLeft', toolboxLeft);

      this.toolbox.style.left = toolboxLeft + "px";
      this.toolbox.style.height = editorHeight + "px";
      // this.toolbox.style.top = toolBoxTop + 'px';

      let editorCanvasWidth = editorWidth - rightSpace;
      if (editorCanvasWidth < this.minEditorCanvasWidth) {
        editorCanvasWidth = this.minEditorCanvasWidth;
      }

      this.editorCanvas.style.left = 0 + 'px';
      this.editorCanvas.style.width = editorCanvasWidth - 20 + "px";
      this.editorCanvas.style.height = editorHeight - 64 + "px";

      this.editorStageNode.width(editorCanvasWidth);
      this.editorStageNode.height(editorHeight);

      // this.$refs.portraittoolbar.style.width = editorCanvasWidth - 40 + 'px';
      // this.$refs.backgroundtoolbar.style.width = editorCanvasWidth - 40 + 'px';
      if (this.$refs.backgroundtoolbar) {
        // this.$refs.backgroundtoolbar.style.width = editorWidth + 'px';
        // this.$refs.backgroundtoolbar.style.right = toolboxLeft - 20 +'px';
        // this.$refs.backgroundtoolbar.style.top = editorHeight - 90+ 'px';
        this.$refs.backgroundtoolbar.style.left = 0 + 'px';
        this.$refs.backgroundtoolbar.style.width = this.editorCanvas.style.width; //this.editorCanvas.style.width;
        this.$refs.backgroundtoolbar.style.top = editorHeight - 90 + 'px';
      }

      this.positionHexagon();
    },

    positionHexagon() {
      // console.log('positionHexagon', 'deltas:', deltaWidth, deltaHeight);

      const curHexagonLeft = this.hexagon.x();
      const curHexagonTop = this.hexagon.y();

      const hexagonTop = parseInt(
          ((this.editorStageNode.height() - this.hexagon.height()) / 2).toFixed()
      );

      const hexagonLeft = parseInt(
          ((this.editorStageNode.width() - this.hexagon.width()) / 2).toFixed()
      );

      this.hexagon.setAttrs({
        x: hexagonLeft,
        y: hexagonTop,
      });

      for (let i = 0; i < this.clipShapes.length; i++) {
        this.clipShapes[i].setAttrs({
          x: hexagonLeft,
          y: hexagonTop,
        });
      }

      const deltaWidth = hexagonLeft - curHexagonLeft;
      const deltaHeight = hexagonTop - curHexagonTop;

      if (this.withPortrait) {
        this.portraitImage.x(this.portraitImage.x() + deltaWidth);
        this.portraitImage.y(this.portraitImage.y() + deltaHeight);
      }

      if (this.withBackground) {
        this.backgroundImage.x(this.backgroundImage.x() + deltaWidth);
        this.backgroundImage.y(this.backgroundImage.y() + deltaHeight);
      }

      this.positionText(hexagonLeft, hexagonTop, deltaWidth, deltaHeight);
      this.positionHexagonBorder(hexagonLeft, hexagonTop);
    },

    positionHexagonBorder(hexagonLeft, hexagonTop) {
      this.$refs.hexagonborder.style.left = hexagonLeft - 1 + 'px';
      this.$refs.hexagonborder.style.top = hexagonTop - 1 + 'px';

    },

    // positionText(hexagonLeft, hexagonTop, deltaWidth, deltaHeight) {
    positionText(hexagonLeft, hexagonTop) {
      // console.log(
      //     "positionText",
      //     hexagonLeft,
      //     hexagonTop,
      //     deltaWidth,
      //     deltaHeight
      // );

      // const hexagonHeight = this.hexagon.height();

      let newLeft = hexagonLeft + 160;
      // if (newLeft < 0) {
      //   newLeft = 0
      // }

      // let hexagonWidth = this.$store.state.hexagonWidth;
      let hexagonHeight = 414; //this.$store.state.hexagonHeight;
      let topOffset = parseInt(hexagonHeight * 0.1);

      // console.log("positionText::newLeft 1", newLeft);

      this.textbox1Frame.style.left = newLeft + "px";
      this.textbox1Frame.style.top = hexagonTop + topOffset + "px";

      newLeft = hexagonLeft + 65;
      // if (newLeft < 0) {
      //   newLeft = 0
      // }

      // console.log("positionText::newLeft 2", newLeft);

      this.textbox2Frame.style.left = newLeft + "px";
      this.textbox2Frame.style.top = hexagonTop + 141 + "px";

      newLeft = hexagonLeft + 160;
      // if (newLeft < 0) {
      //   newLeft = 0
      // }

      // console.log("positionText::newLeft 3", newLeft);

      this.textbox3Frame.style.left = newLeft + "px";
      this.textbox3Frame.style.top = hexagonTop + hexagonHeight - topOffset - 40 + "px";

      newLeft = hexagonLeft + 180;
      // if (newLeft < 0) {
      //   newLeft = 0
      // }

      // console.log("positionText::newLeft 4", newLeft);

      this.textbox4Frame.style.left = newLeft + "px";
      this.textbox4Frame.style.top = hexagonTop + 27 + "px";
    },

    // setupCanvas() {
    //   const dpr = window.devicePixelRatio || 1;
    //   console.log('devicePixelRatio', dpr);
    //
    //   console.log(this.editorStageNode);
    //   // let canvas = this.editorStageNode.ca
    //   // let rect = canvas.getBoundingClientRect();
    //   // canvas.width = rect.width * dpr;
    //   // canvas.height = rect.height * dpr;
    //   // let ctx = canvas.getContext('2d');
    //   // ctx.scale(dpr, dpr);
    //   // return ctx;
    // },

    handleWindowResize() {
      // console.log("[Editor] handleWindowResize");
      this.doResizeEditor();
    },

    onEnter() {
      // console.log("Editor Enter pressed");
    },

    exportImage() {
      // this.textLayer.setAttrs({
      //     opacity: 1,
      //     visible: true,
      //   })
      // Promise.all([
      // const image1 =

      this.pixelRatio = window.devicePixelRatio;

      const f = this.$store.getters.getHexagonFactor;
      const dpr = this.pixelRatio;
      const scaleFactor = 1 / dpr;
      // console.log('Hexagon factor = ', f, "dpr", dpr, 'scaleFactor', scaleFactor);

      return new Promise((resolve) => {
        let image1 = null;
        let image2 = null;
        let image3 = null;
        let image4 = null;

        const image1Promise = this.renderText(
            this.textbox1Image,
            document.getElementById("textbox1Edit")
        ); //.then((canvas) => image1 = canvas);
        image1 = image1Promise.canvas;
        // const image2 =
        const image2Promise = this.renderText(
            this.textbox2Image,
            document.getElementById("textbox2Edit")
        ); //.then((canvas) => image2 = canvas);
        // const image3 =

        const image3Promise = this.renderText(
            this.textbox3Image,
            document.getElementById("textbox3Edit")
        ); //.then((canvas) => image3 = canvas);

        const image4Promise = this.renderText(
            this.textbox4Image,
            document.getElementById("textbox4Edit")
        ); //.then((canvas) => image4 = canvas);
        // console.log("Textbox4 ", document.getElementById("textbox4Edit"));
        // console.log("Image ", this.textbox4Image)

        Promise.all([
          image1Promise,
          image2Promise,
          image3Promise,
          image4Promise,
        ]).then((images) => {
          // for (let i=0; i < images.length; i++) {
          image1 = images[0];
          image2 = images[1];
          image3 = images[2];
          image4 = images[3];
          // }

          // ].then((images) => {
          // console.log("image1", image1);
          // console.log("image2", image2);
          // console.log("image3", image3);
          // console.log("image4", image4);

          this.textbox1Image.setAttrs({
            x: this.textbox1Frame.offsetLeft,
            y: this.textbox1Frame.offsetTop,
            image: image1,
            scaleX: scaleFactor,
            scaleY: scaleFactor,
            opacity: 1,
            visible: true,
          });

          this.textbox2Image.setAttrs({
            x: this.textbox2Frame.offsetLeft,
            y: this.textbox2Frame.offsetTop,
            image: image2,
            scaleX: scaleFactor,
            scaleY: scaleFactor,
            opacity: 1,
            visible: true,
          });

          this.textbox3Image.setAttrs({
            x: this.textbox3Frame.offsetLeft,
            y: this.textbox3Frame.offsetTop,
            image: image3,
            opacity: 1,
            scaleX: scaleFactor,
            scaleY: scaleFactor,
            visible: true,
          });

          this.textbox4Image.setAttrs({
            x: this.textbox4Frame.offsetLeft,
            y: this.textbox4Frame.offsetTop,
            image: image4,
            opacity: 1,
            scaleX: scaleFactor,
            scaleY: scaleFactor,
            visible: true,
          });

          const data = this.editorStageNode.toDataURL({pixelRatio: f});

          // console.log("png generated!");

          this.textbox1Image.setAttrs({
            opacity: 0,
            visible: false,
          });

          this.textbox2Image.setAttrs({
            opacity: 0,
            visible: false,
          });

          this.textbox3Image.setAttrs({
            opacity: 0,
            visible: false,
          });

          this.textbox4Image.setAttrs({
            opacity: 0,
            visible: false,
          });


          resolve(data);
        });
      });
      // });
    },

    toggleColorScheme(schemeName) {
      // console.log("[toggleColorScheme]", schemeName);

      this.$store.commit("setColorScheme", {colorScheme: schemeName});

      this.$refs.colorprofile.hide();

      switch (schemeName) {
        case "corporate":
          this.selectedColorScheme = "Roche";
          this.colorOne = "#0b41cd";
          this.colorTwo = "#bde3ff";
          this.colorThree = "#022366";
          break;
        case "oneheme":
          this.selectedColorScheme = "OneHeme";
          this.colorOne = "#b22b0d";
          this.colorTwo = "#ffe8de";
          this.colorThree = "#fff7f5";
          break;
        case "gazyvaro":
          this.selectedColorScheme = "Gazyvaro<sup>&copy;</sup>";
          this.colorOne = "#a40023";
          this.colorTwo = "#43566d";
          this.colorThree = "#00cdd7";
          break;
        case "hemlibra":
          this.selectedColorScheme = "Hemlibra<sup>&copy;</sup>";
          this.colorOne = "#f04e2a";
          this.colorTwo = "#009bd3";
          this.colorThree = "#55565a";
          break;
        case "mabthera":
          this.selectedColorScheme = "Mabthera<sup>&copy;</sup>";
          this.colorOne = "#e94d23";
          this.colorTwo = "#004e93";
          this.colorThree = "#868686";
          break;
        case "polivy":
          this.selectedColorScheme = "Polivy<sup>&copy;</sup>";
          this.colorOne = "#d31c5a";
          this.colorTwo = "#263b80";
          this.colorThree = "#a0cf67";
          break;
        case "lunsumio":
          this.selectedColorScheme = "Lunsumio<sup>&copy;</sup>";
          this.colorOne = "#ec455e";
          this.colorTwo = "#31aaa2";
          this.colorThree = "#fed75f";
          break;
      }

      this.$refs.textcolorone.style.backgroundColor = this.colorOne;
      this.$refs.bgcolorone.style.backgroundColor = this.colorOne;

      this.$refs.textcolortwo.style.backgroundColor = this.colorTwo;
      this.$refs.bgcolortwo.style.backgroundColor = this.colorTwo;

      this.$refs.textcolorthree.style.backgroundColor = this.colorThree;
      this.$refs.bgcolorthree.style.backgroundColor = this.colorThree;

      // this.handleColorScheme(schemeName);
      // this.selectedColorScheme = schemeName;

      this.handleBGColor(this.$store.getters.getData.bgColor);
    },

    reloadHexagon() {

      // console.log('[reloadHexagon] hexagonData', JSON.stringify(this.$store.getters.getData));

      // console.log('[reloadHexagon] colorScheme', this.$store.getters.getData.colorScheme);

      this.toggleColorScheme(this.$store.getters.getData.colorScheme);

      this.withPortrait = this.$store.getters.getData.images[1].visible;
      // console.log('[reloadHexagon] with Portrait', this.withPortrait);

      if (this.withPortrait) {
        this.loadImage(
            this.$store.getters.getData.images[1].src,
            this.portraitImage,
            this.drawImage,
            false,
            100,
        );
      } else {
        this.portraitImage.opacity(0);
      }

      this.withBackground = this.$store.getters.getData.images[0].visible;
      // console.log('[reloadHexagon] with backgroundImage', this.withBackground);

      if (this.withBackground) {

        this.backgroundImageOpacity = this.$store.getters.getData.bgOpacity;
        this.handleBGOpacity(this.backgroundImageOpacity);

        this.loadImage(
            this.$store.getters.getData.images[0].src,
            this.backgroundImage,
            this.drawImage,
            false,
            this.backgroundImageOpacity,
        );
      } else {
        this.backgroundImage.opacity(0);
      }

      this.textMode = this.$store.getters.getData.textmode;
      // console.log('[reloadHexagon] with textMode', this.textMode);

      this.isEditing = false;

      this.editor1.commands.setContent(
          this.$store.getters.getData.textfields[0].content
      );
      this.editor2.commands.setContent(
          this.$store.getters.getData.textfields[1].content
      );
      this.editor3.commands.setContent(
          this.$store.getters.getData.textfields[2].content
      );
      this.editor4.commands.setContent(
          this.$store.getters.getData.textfields[3].content
      );

    },

  },

  created() {
    // console.log("created()");
    window.addEventListener("resize", this.handleWindowResize);
    //this.textMode = this.$store.getters.getData.textmode;
  }
  ,

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  ,

  beforeDestroy() {
    if (this.editor1) {
      this.editor1.destroy();
    }
    if (this.editor2) {
      this.editor2.destroy();
    }
    if (this.editor3) {
      this.editor3.destroy();
    }
    if (this.editor4) {
      this.editor4.destroy();
    }
  }
  ,
  updated() {
    //  this.$emit("loadJson");
  }
  ,

  mounted() {
    // this.domEdit = this.$refs.domEdit;
    this.editorView = this.$refs.editorView;
    this.trashcan = this.$refs.trashcan;
    this.toolbox = this.$refs.toolbox;
    this.editorCanvas = this.$refs.editorCanvas;
    this.editorStage = this.$refs.editorstage;
    this.textbox1Frame = this.$refs.textbox1Frame;
    this.textbox2Frame = this.$refs.textbox2Frame;
    this.textbox3Frame = this.$refs.textbox3Frame;
    this.textbox4Frame = this.$refs.textbox4Frame;


    // console.log('Editor::mounted', 'editorView', this.editorView, 'trashcan', this.trashcan);

    this.editorStageNode = this.editorStage.getNode(); //this.$refs.editorstage.getNode();
    this.hexagon = this.$refs.editorhexagon.getNode();

    this.pixelRatio = this.hexagon.getCanvas().pixelRatio;

    this.textLayer = this.$refs.textlayer.getNode();
    this.textGroup = this.$refs.textgroup.getNode();
    this.textSelect = this.$refs.textselect.getNode();

    this.portraitLayer = this.$refs.portraitlayer.getNode();
    this.backgroundLayer = this.$refs.backgroundlayer.getNode();

    if (this.$refs.backgroundclip) {
      this.appendClipShape(this.$refs.backgroundclip.getNode());
    }

    if (this.$refs.portraitclip) {
      this.appendClipShape(this.$refs.portraitclip.getNode());
    }

    // console.log('this.$refs.textlayer.getCanvas()', this.textLayer.getCanvas());
    // console.log('this.$refs.textlayer.getCanvas()', this.textLayer.getCanvas().pixelRatio);
    // this.textLayer.getCanvas().pixelRatio = 1;

    // this.$refs.portraitlayer.getCanvas().pixelRatio(1);
    // this.$refs.backgroundlayer.getCanvas().pixelRatio(1);

    // if (this.$refs.textclip) {
    //   this.appendClipShape(this.$refs.textclip.getNode());
    // }

    this.hitOrder = new Array();
    this.hitOrder.push(this.textLayer);
    this.hitOrder.push(this.portraitLayer);
    // this.hitOrder.push(this.backgroundLayer);

    if (this.$refs.editorbackgroundimage) {
      this.backgroundImage = this.$refs.editorbackgroundimage.getNode();
    }

    if (this.$refs.portraitimage) {
      this.portraitImage = this.$refs.portraitimage.getNode();
    }

    if (this.$refs.textbox1image) {
      this.textbox1Image = this.$refs.textbox1image.getNode();
      this.textbox1Image.refName = "textbox1image";
    }

    this.textbox2Image = this.$refs.textbox2image.getNode();
    this.textbox2Image.refName = "textbox2image";
    this.textbox3Image = this.$refs.textbox3image.getNode();
    this.textbox3Image.refName = "textbox3image";
    // console.log("Textbox4 ref ", this.$refs.textbox4image);
    this.textbox4Image = this.$refs.textbox4image.getNode();
    this.textbox4Image.refName = "textbox4image";
    // console.log("Textbox4 ref ", this.textbox4Image);

    // var hexagonTop = parseInt(
    //     ((this.editorStageNode.height() - this.hexagon.height()) / 2).toFixed()
    // );
    //
    // var hexagonLeft = parseInt(
    //     ((this.editorStageNode.width() - this.hexagon.width()) / 2).toFixed()
    // );
    //
    // this.hexagon.setAttrs({
    //   x: hexagonLeft,
    //   y: hexagonTop,
    // });
    //
    // for (var i = 0; i < this.clipShapes.length; i++) {
    //   this.clipShapes[i].setAttrs({
    //     x: hexagonLeft,
    //     y: hexagonTop,
    //   });
    // }

    // let colorScheme = this.$store.getters.getData.colorScheme;

    // console.log('incoming color scheme:', this.$store.getters.getData.colorScheme, "and bg-color:", this.$store.getters.getData.bgColor);

    // this.selectedColorScheme = this.$store.getters.getData.colorScheme;

    this.toggleColorScheme(this.$store.getters.getData.colorScheme);
    // this.selectedColorScheme = schemeName;

    // this.$refs.colorprofile

    this.handleBGColor(this.$store.getters.getData.bgColor);

    this.fullImage = this.$refs.fullimage.getNode();
    this.editorTransformer = this.$refs.editorimagetransformer.getNode();

    this.withPortrait = this.$store.getters.getData.images[1].visible;
    //    this.showPortrait === true || this.showPortrait === "true";

    // console.log("showPortrait: ", this.showPortrait, this.withPortrait);

    if (this.withPortrait) {
      this.loadImage(
          this.$store.getters.getData.images[1].src,
          this.portraitImage,
          this.drawImage,
          false,
          100,
      );
    }

    this.withBackground = this.$store.getters.getData.images[0].visible;
    //      this.showBackground === true || this.showBackground === "true";

    // console.log("showBG: ", this.showBackground, this.withBackground);


    if (this.withBackground) {
      // console.log("bg load triggered...");

      this.backgroundImageOpacity = this.$store.getters.getData.bgOpacity;
      this.handleBGOpacity(this.backgroundImageOpacity);

      // console.log("bg load image with opacity", this.backgroundImageOpacity);

      this.loadImage(
          this.$store.getters.getData.images[0].src,
          this.backgroundImage,
          this.drawImage,
          false,
          this.backgroundImageOpacity,
      );
    }


    // this.renderText([this.textbox1Image], "Einladung", "medium", 450, 280, 500);

    // this.renderText([this.textbox2Image], "We go meet!", "large", 50, 410, 650);

    // this.renderText(
    //   [this.textbox3Image],
    //   "15. August 2021 - Messa Stand 39B",
    //   "small", 430, 690, 550
    // );

    // this.$refs.textbox1Edit.contentEditable = true;
    // this.$refs.textbox2Edit.contentEditable = true;
    // this.$refs.textbox3Edit.contentEditable = true;

    //this.withText = this.showText === true || this.showText === "true";
    this.textMode = this.$store.getters.getData.textmode;
    this.withText = true; //this.$store.getters.getData.textmode > 0;
    // console.log("showTEXT: ", this.showText, this.withText);

    if (this.withText) {
      this.isEditing = false;
      this.activeTextBoxRef = "";

      this.editor1 = new Editor({
        content: "<p>TEST</p>",
        disablePasteRules: true,
        disableInputRules: true,
        injectCSS: false,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          // TextDecoration,
          TextStyle,
          FontColor,
          FontFamily,
          // TextDecorationColor,
          Underline,
          // EnterHandler,
        ],
      });

      // console.log(
      //     "Content for text1: ",
      //     this.$store.getters.getData.textfields[0].content
      // );

      this.editor1.commands.setContent(
          this.$store.getters.getData.textfields[0].content
      );

      this.editor1.on("blur", this.handleEditBlur);
      this.editor1.on("focus", this.handleEditFocus);
      this.editor1.on("update", () => {
        const text = this.editor1.getHTML();
        // console.log("Content = ", text);
        if (text === "<p></p>") {
          this.editor1.commands.setContent("<p>&nbsp;</p>");
          this.editor1.commands.selectAll();
          // const text1 = this.editor1.getHTML();
          // console.log("Content = ", text1);
        }
      });

      this.editor2 = new Editor({
        content: this.$store.getters.getData.textfields[1].content,
        disablePasteRules: true,
        disableInputRules: true,
        injectCSS: false,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          // TextDecoration,
          TextStyle,
          FontColor,
          FontFamily,
          // TextDecorationColor,
          Underline,
          // EnterHandler,
        ],
      });
      this.editor2.commands.setContent(
          this.$store.getters.getData.textfields[1].content
      );

      this.editor2.on("blur", this.handleEditBlur);
      this.editor2.on("focus", this.handleEditFocus);
      this.editor2.on("update", () => {
        const text = this.editor2.getHTML();
        if (text === "<p></p>") {
          this.editor2.commands.setContent("<p>&nbsp;</p>");
          this.editor2.commands.selectAll();
        }
      });

      this.editor3 = new Editor({
        content: this.$store.getters.getData.textfields[2].content,
        disablePasteRules: true,
        disableInputRules: true,
        injectCSS: false,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          // TextDecoration,
          TextStyle,
          FontColor,
          FontFamily,
          // TextDecorationColor,
          Underline,
          // EnterHandler,
        ],
      });
      this.editor3.commands.setContent(
          this.$store.getters.getData.textfields[2].content
      );

      this.editor3.on("blur", this.handleEditBlur);
      this.editor3.on("focus", this.handleEditFocus);
      this.editor3.on("update", () => {
        const text = this.editor3.getHTML();
        if (text === "<p></p>") {
          this.editor3.commands.setContent("<p>&nbsp;</p>");
          this.editor3.commands.selectAll();
        }
      });

      this.editor4 = new Editor({
        content: this.$store.getters.getData.textfields[3].content,
        disablePasteRules: true,
        disableInputRules: true,
        injectCSS: false,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          // TextDecoration,
          TextStyle,
          FontColor,
          FontFamily,
          // TextDecorationColor,
          Underline,
          // EnterHandler,
        ],
      });
      this.editor4.commands.setContent(
          this.$store.getters.getData.textfields[3].content
      );

      this.editor4.on("blur", this.handleEditBlur);
      this.editor4.on("focus", this.handleEditFocus);
      this.editor4.on("update", () => {
        const text = this.editor4.getHTML();
        if (text === "<p></p>") {
          this.editor4.commands.setContent("<p>&nbsp;</p>");
          this.editor4.commands.selectAll();
        }
      });

      // this.toggleColorScheme('corporate');

      // this.setupCanvas();
      this.doResizeEditor();
    }
  }
  ,
  computed: {
    visText1() {
      const m = this.textMode;
      if (m === 2 || m === 3) {
        return {visibility: "visible"};
      } else {
        return {visibility: "hidden"};
      }
    }
    ,
    visText2() {
      const m = this.textMode;
      if (m === 2 || m === 3 || m === 4) {
        return {visibility: "visible"};
      } else {
        return {visibility: "hidden"};
      }
    }
    ,
    visText3() {
      const m = this.textMode;
      // console.log("textmodestyle: ", m);
      if (m === 3 || m === 4) {
        return {visibility: "visible"};
      } else {
        return {visibility: "hidden"};
      }
    }
    ,
    visText4() {
      const m = this.textMode;
      // console.log("textmodestyle: ", m);
      if (m === 1) {
        return {visibility: "visible"};
      } else {
        return {visibility: "hidden"};
      }
    }
    ,
  }
  ,
}
;
</script>

<template src="./HexagonEditor.html"/>
<style scoped src="./HexagonEditor.css"/>
