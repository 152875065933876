import Confirmed from '@/models/Confirmed'
import * as MutationTypes from './mutation-types'

const state = {
    confirmed: Confirmed.from(localStorage.confirmed)
}

const mutations = {
    [MutationTypes.CONFIRMED] (state) {
        state.confirmed = Confirmed.from(localStorage.token)
    },

    [MutationTypes.LOGOUT] (state) {
        state.confirmed = null
    }
}

const getters = {
    isConfirmed (state) {
        return state.confirmed
    }
}

const actions = {
    logout ({ commit }) {
        commit(MutationTypes.LOGOUT)
    },

    confirm ({ commit }) {
        commit(MutationTypes.CONFIRMED)
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}
