export default class Confirmed {

    static from (confirmed) {
        try {
            return new Confirmed(confirmed)
        } catch (_) {
            return null
        }
    }

    constructor (confirmed ) {
        this.confirmed = confirmed
    }

}