<template>
  <button ref="bttn" :id="id" @click="onClick" class="RocheSansMedium accordionButton">
<!--    <div ref="indicator" :id="imgID" class="tabindicator_div">&gt;</div>-->
<!--&lt;!&ndash;    <img ref="indicator" :id="imgID" class="tabindicator" src="../assets/inactive_tab.png"/>&ndash;&gt;-->
    <div :id="textID" class="bttn-text"><slot>Button</slot></div>
  </button>
</template>

<script>
export default {
  name: "AccordionButton",
  data(){
    return{
      imgID:"",
      textID: "",
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    onClick: {
          type: Function,
          required: false,
        },
    isOpen: {
      type: Boolean,
      required: true,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val, oldVal) {
        this.syncIDs(val, oldVal);
      }
    },
    isOpen: {
      immediate: true,
      handler(val, oldVal) {
        this.updateIcon(val, oldVal);
      }
    }
  },
  methods: {
    syncIDs(newValue) {
      // console.log('syncIDs', newValue);
      const accordionID = newValue.split('_')[2]
      this.imgID = 'accordion_bttn-img_' + accordionID;
      this.textID = 'accordion_bttn-txt_' + accordionID;
    },

    updateIcon(newValue) {
      // console.log('updateIcon', newValue);
      // let element = document.getElementById(this.imgID);
      let element = this.$refs.indicator;
      let bttn = this.$refs.bttn;

      // console.log('updateIcon', newValue, "for", element);

      if (element) {
        if (newValue) {
          // element.style.trarotate = '90deg';
          element.style.transform = 'rotate(90deg)';
          bttn.classList.add('accordionButton-active');
        } else {
          // element.style.rotate = '0deg';
          element.style.transform = 'rotate(0deg)';
          bttn.classList.remove('accordionButton-active');
        }
      }
    }
  },
  mounted() {
    // let element = this.$refs.indicator;
    // console.log('mounted ICON? ', element);
    // if (this.isOpen) {
    //   this.updateIcon(true);
    // }
  }
}
</script>

<style scoped>
.accordionButton {
  /*color: white;*/
  display: flex;
  /*background-color: rgba(0, 102, 204, 1);*/
  /*background-color:#0b41cd;*/
  color: #2d3232;
  border-bottom: 1px solid #ddd;
  text-align: center;
  text-decoration: none;
  height: 54px;
  width: 400px;
  /*color: black;*/
}

.tabindicator {
  position: relative;
  top: 22px;
  left: 35px;
  max-width: 9px;
  max-height: 16px;
}

.tabindicator_div {
  position: relative;
  top: 5px;
  left: 45px;
  width: 32px;
  height: 32px;
  font-size: larger;
  font-weight: 900;
  /*background-image: url("../assets/inactive_tab.svg");*/
  /*border: 1px solid red;*/
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /*padding-top: 0px;*/
  margin: 0;
}
.bttn-text {
  position: relative;
  top: 27px;
  left: 20px;
  font-size: 18px;
  font-family: "RocheSans", sans-serif;
  line-height: 1.3;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 0;
  /*font-family: "Roche Sans", "Mier B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
  /*color: rgb(84, 79, 79);*/
  /*font-size: 18px;*/
  /*line-height: 1.3;*/
  /*font-weight: 400;*/
  /*text-transform: none;*/
  /*font-style: normal;*/
  /*text-decoration: none;*/
  /*letter-spacing: 0;*/

}
.accordionButton:hover {
  /*font-weight: 900;*/
  /*text-shadow: 1px 1px black;*/
  /*color: yellow;*/
  color: black;
}

.accordionButton-active {
  /*font-weight: 900;*/
  /*text-shadow: 1px 1px black;*/
  color: rgb(11, 65, 205);
}

</style>