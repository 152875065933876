const state = {
    hexagonFactor: 5,
    hexagonWidth: 800,
    rectangleWidth: 440,
    hexagonHeight: 414,
    hexagonGap: 40,
    stickerDPI: 300,
    stickerMaxWidthMM: 279,
    stickerMaxHeightMM: 210,
    stickerHexagonHeight: 414,
    stickerHexagonWidth: 800,
    colOffsets: [3, 2, 1, 0, 1, 2, 3],
}

const getters = {

    getStickerSize: (state) => (scale) => {
        // const right = Math.round((state.hexagonWidth - state.rectangleWidth) / 2 * scale);
        // const left = Math.round(state.rectangleWidth * scale) + right;
        // const height = Math.round(state.hexagonHeight * scale);
        // let gap = Math.round(state.hexagonGap * scale);
        // if (gap < 1) gap = 1;
        const hexagonDimensions = scaleHexagon(state, scale);
        const right = hexagonDimensions.right;
        const left = hexagonDimensions.left;
        const height = hexagonDimensions.height;
        const gap = hexagonDimensions.gap;

        const w = (left + gap) * 7 + right;
        const h = height * 7 + gap * 6;
        // console.log('canvas size = ', w, ' x ', h);
        return ({ width: w, height: h });
    },

    getUsedSize: (state,getters) => (scale, extra) => {
        // console.log(state);
        let x = 0,
            y = 0,
            w = 0,
            h = 0;
        let hx = {};
        let id = "";
        let isNew = true;
        for (let c = 0; c < 7; c++) {
            for (let r = 0; r < 7; r++) {
                if (extra) {
                    id = getters.getExtraTile(c + 1, r + 1);
                } else {
                    id = getters.getTile(c + 1, r + 1);
                }
                if (id !== "") {
                    hx = getters.getHexagonPos(c + 1, r + 1, scale);
                    if (isNew) {
                        x = hx.x;
                        y = hx.y;
                        w = hx.width;
                        h = hx.height;
                        isNew = false;
                    } else {
                        if (hx.x < x) {
                            w = w + x - hx.x;
                            x = hx.x;
                        }
                        if (hx.x + hx.width > x + w) w = hx.x + hx.width - x;
                        if (hx.y < y) {
                            h = h + y - hx.y;
                            y = hx.y;
                        }
                        if (hx.y + hx.height > y + h) h = hx.y + hx.height - y;
                    }
                }
                // console.log("x:", x, " y:", y, " width:", w, " height:", h);
            }
        }
        return { x: x, y: y, width: w, height: h }
    },

    getExportDefinition(state){
        return {dpi: state.stickerDPI, maxWidthMM: state.stickerMaxWidthMM, maxHeightMM : state.stickerMaxHeightMM}
    },

    getHexagonPos: (state) => (col, row, scale) => {
        const hexagonDimensions = scaleHexagon(state, scale);
        const right = hexagonDimensions.right;
        const left = hexagonDimensions.left;
        const height = hexagonDimensions.height;
        const gap = hexagonDimensions.gap;
        const x = (col - 1) * (left + gap);
        const yo = Math.round(state.colOffsets[col - 1] * (height + gap) / 2);
        const y = yo + (row - 1) * (height + gap);
        const w = right + left;
        // console.log('Hexagon position = ', x, ', ', y, ' ', w, ' ', height,'[',state,']');
        return ({ x: x, y: y, width: w, height: height, yOffset: yo});
    },

    // getClippingPolygon: (state) => (scale) => {
    //     const right = Math.round((state.hexagonWidth - state.rectangleWidth) / 2 * scale);
    //     const left = Math.round(state.rectangleWidth * scale) + right;
    //     const height = Math.round(state.hexagonHeight * scale);
    //     const half = Math.round(height / 2);
    //     return [0, half, right, 0, left, 0, left + right, half, left, height, right, height];
    // },
    getColumnOffsets(state) {
        return state.colOffsets;
    },

    getHexagonFactor(state) {
        return state.hexagonFactor;
    },

    getExportFormats(state) {
        return state.exportFormats;
    },

    getDefaultExportFormat(state) {
        return state.defaultExportFormat;
    },

}

function scaleHexagon(state, scale) {
    const right = Math.round((state.hexagonWidth - state.rectangleWidth) / 2 * scale);
    const left = Math.round(state.rectangleWidth * scale) + right;
    const height = Math.round(state.hexagonHeight * scale);
    let gap = Math.round(state.hexagonGap * scale);
    if (gap < 1) gap = 1;
    return {'right':right, 'left':left, 'height':height, 'gap':gap};
}

const mutations = {
    setConfiguration (state,data) {

        // console.log('Set configuration ',data);
        state.hexagonGap = parseInt(data.hexagonGap);
        state.hexagonWidth = parseInt(data.hexagonWidth);
        state.hexagonHeight = parseInt(data.hexagonHeight);
        state.rectangleWidth = parseInt(data.rectangleWidth);
        state.hexagonFactor = parseInt(data.hexagonFactor);
        state.stickerDPI = parseInt(data.stickerDPI);
        state.stickerMaxHeightMM = parseInt(data.stickerMaxHeightMM);
        state.stickerMaxWidthMM = parseInt(data.stickerMaxWidthMM);
        state.stickerHexagonHeight = parseInt(data.stickerHexagonHeight);
        state.stickerHexagonWidth = parseInt(data.stickerHexagonWidth);
        state.exportFormats = data.exportFormats;
        state.defaultExportFormat = data.defaultExportFormat;
    },
}

export default {
    state,
    getters,
    mutations,
}
