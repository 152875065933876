<template>
  <b-container class="image-admin">
    <p class="admin-hl">Allgemeine&nbsp;Einzelsticker&nbsp;verwalten</p>
    <b-row class="toolbox-bttn-row">
      <b-row>
        <b-dropdown id="accordion-dd" right variant="outline-light" text-decoration-none :text="selectedAccordion.name"
                    class="accordion-dd">
          <b-dropdown-item v-for="accordion in accordions" :key="accordion.id" @click="toggleAccordion(accordion.id)">
            {{ accordion.name }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown v-if="showAccordionGroupDD" id="accordion-group-dd" right variant="outline-light"
                    text-decoration-none :text="selectedAccordionGroup.name" class="accordion-dd">
          <b-dropdown-item v-for="accordionGroup in activeAccordionGroups" :key="accordionGroup.id"
                           @click="toggleAccordionGroup(accordionGroup.id)">{{ accordionGroup.name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-row>

      <b-row>
        <button v-if="showDelete" @click="handleDelete" class="RocheSansMedium accordionButton">
          <div class="bttn-text">
            <slot>Auswahl&nbsp;l&ouml;schen</slot>
          </div>
        </button>

        <image-upload v-if="showImageGrid"
                      class="image-upload"
                      @upload-uuid="handleUploaded"
                      :url="selectedAccordionGroup.upload_url"
                      :styled=true
        ></image-upload>

      </b-row>

    </b-row>
    <b-row class="image-grid-row">
      <ajax-image-grid v-if="showImageGrid"
                       ref="imageGrid"
                       class="image-grid"
                       :area=selectedAccordion.id
                       :group=selectedAccordionGroup.id
                       :id="'accordion_image_grid_'+selectedAccordion.id+'_'+selectedAccordionGroup.id"
                       :url=selectedAccordionGroup.url
                       :upload=false
                       :uploadurl=selectedAccordionGroup.upload_url
                       :downloadurl=selectedAccordionGroup.download_url
                       :reloadfolder="reloadFolder"
                       :reloadflag="reloadFlag"
                       :withbackground=selectedAccordionGroup.show_background
                       :imagelayer=0
                       :imagesdraggable=false
                       :imagesselectable=true
                       @imageSelected="handleImageSelected"
      ></ajax-image-grid>
    </b-row>
  </b-container>
</template>

<script>
import {HTTP} from "@/main";
import AjaxImageGrid from '@/components/AjaxImageGrid';
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "AdminUpload",
  components: {AjaxImageGrid, ImageUpload},
  data() {
    return {
      showDelete: false,

      reloadFolder: '',
      reloadFlag: false,

      allAccordions: [],
      allAccordionGroups: [],

      accordions: [{id: 5, name: "Icon-Sticker"}, {id: 2, name: "Produkt-/Kampagnen-Sticker"}, {id: 4, name: "Themen"}],
      selectedAccordion: {id: 0, name: "Akkordeon auswählen"},

      showAccordionGroupDD: false,
      showImageGrid: false,

      accordionGroups: {
        2: [{id: 1, name: "Roche Corporate"}, {id: 2, name: "One Heme"}],
        5: [{id: 1, name: "Symbole"}, {id: 2, name: "Fertige"}]
      },

      activeAccordionGroups: [{id: 0, name: ""}],

      selectedAccordionGroup: {id: 0, name: "Akkordeongruppe auswählen"},

      filenames: [],

      selectedImages: [],
    }
  },

  methods: {
    loadAccordion() {
      HTTP.get(
          'restricted/accordions')
          .then((response) => {
            // console.log('fetched accordion setup', response.data);
            this.allAccordions = response.data.accordions;
            if (this.allAccordions) {
              // console.log("restricted/accordion", this.allAccordions);
              this.handleLoaded();
            }
          })
          .catch(() => {
                // console.log('failed loading the accordion');
              }
          )
    },

    handleLoaded() {
      this.accordions = [];
      for (let i = 0; i < this.allAccordions.length; i++) {
        let item = this.allAccordions[i];
        if (item.admin_upload) {
          this.accordions.push(item);
          // if (item.groups.length > 1) {
          this.accordionGroups[item.id] = item.groups;
          // }
        }
      }
    },

    toggleAccordion(id) {
      this.showDelete = false;
      this.selectedImages = [];

      this.showImageGrid = false;
      for (let i = 0; i < this.accordions.length; i++) {
        let item = this.accordions[i];
        if (item.id === id) {
          this.populateAccordionGroup(id);
          this.selectedAccordion = item;

          if (!this.showAccordionGroupDD) {
            // console.log('[populateAccordionGroup]', this.activeAccordionGroups[0].url);
            this.selectedAccordionGroup = this.activeAccordionGroups[0];
            this.reloadFolder = this.activeAccordionGroups[0].url;
            this.reloadFlag = !this.reloadFlag;
            // console.log('[populateAccordionGroup:post]', this.activeAccordionGroups[0].url);
            this.showImageGrid = true;
          }
          break;
        }
      }
    },

    populateAccordionGroup(id) {
      this.activeAccordionGroups = this.accordionGroups[id];
      this.showAccordionGroupDD = this.activeAccordionGroups && this.activeAccordionGroups.length > 1;
      this.selectedAccordionGroup = {id: 0, name: "Akkordeongruppe auswählen"};
    },

    toggleAccordionGroup(id) {

      this.showDelete = false;
      this.selectedImages = [];

      for (let i = 0; i < this.activeAccordionGroups.length; i++) {
        let item = this.activeAccordionGroups[i];
        if (item.id === id) {
          this.selectedAccordionGroup = item;
          // console.log('[toggleAccordionGroup]', item.url);
          this.reloadFolder = item.url;
          this.reloadFlag = !this.reloadFlag;
          this.showImageGrid = true;
          break;
        }
      }
    },

    // this.reloadFolder = folderID;
    // this.reloadFlag = !this.reloadFlag;

    handleDelete() {
      // console.log('[handleDelete]', this.selectedAccordionGroup.upload_url);


      for (let i = 0; i < this.selectedImages.length; i++) {
        // let deleteURL = this.selectedAccordionGroup.upload_url + '/' + this.selectedImages[i];
        let imageUUID = this.selectedImages[i];
        // console.log('[handleDelete]', i, imageUUID);
        HTTP.delete(this.selectedImages[i]).then((resp) => {
          if (resp.status === 204) {
            // console.log('[handleDelete] remove image from grid', imageUUID);
            this.$refs.imageGrid.removeImage(imageUUID);
          }
        }).catch((e) => {
          console.log("error on delete image", e);
        });
      }

      this.selectedImages = [];
      this.showDelete = false;
    },

    handleImageSelected(imageUUID) {
      // console.log('[handleImageSelected]', imageUUID);

      if (!this.selectedImages) {
        this.selectedImages = new Array(imageUUID);
      } else {

        let imageIdx = -1;

        for (let i = 0; i < this.selectedImages.length; i++) {
          if (this.selectedImages[i] === imageUUID) {
            imageIdx = i;
            break
          }
        }

        if (imageIdx > -1) {
          this.selectedImages.splice(imageIdx, 1);
        } else {
          this.selectedImages.push(imageUUID);
        }
      }

      this.showDelete = this.selectedImages.length > 0;
    },

    handleUploaded(imageUUID) {

      // console.log('ImageAdmin::handleUploaded', this.selectedAccordionGroup.download_url + '/' + imageUUID);
      this.$refs.imageGrid.addImage(this.selectedAccordionGroup.download_url + '/' + imageUUID);
    }

  },

  mounted() {
    this.loadAccordion();
  },

}
</script>

<style scoped>

.image-admin {
  /*border: 1px solid blue;*/
  margin: 0px;
  padding: 0px;
}

.admin-hl {
  font-family: "RocheSansMedium", sans-serif;
  font-size: 1.5em;
}

.accordion-dd {
  width: 250px;
}

.image-grid-row {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  /*background-color: #4d3590;*/
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.image-grid {
  margin: 0px;
  padding: 0px;
  /*background-color: #fad6c7;*/
  /*border: 1px solid green;*/
  /*display: flex;*/
  /*height: calc(100vh - 300px);*/
  /*overflow-scrolling: auto;*/
}

.toolbox-bttn-row {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*background-color: #007bff;*/
}

.image-upload {
  /*background-color: #fad6c7;*/
  width: 160px;
  margin: 0px;
  padding: 0px;
}

</style>