<template>
  <div class="user-table">
    <b-table striped hover :items="items" :fields="fields">
      <template #cell(isAdmin)="data">
        <b-check :checked="data.item.isAdmin"
                 @change="handleIsAdmin($event, data.item.email, data.item.isAdmin)"></b-check>
      </template>
      <template #cell(lastLogin)="data">
        <b-link class="table-link" @click="handleLoadConfirmLog(data.item.email)">{{data.item.lastLogin}}</b-link>
        <confirmation-log v-if="showConfirmationLog" :email="data.item.email"></confirmation-log>
      </template>
    </b-table>
  </div>
</template>

<script>
import {HTTP} from "@/main";
import ConfirmationLog from "@/components/ConfirmationLog";

export default {
  name: "UserTable",

  components: {ConfirmationLog},

  data() {
    return {
      showConfirmationLog: false,
      fields: [
        {
          key: 'email',
          label: 'E-Mail',
          sortable: true,
        },
        // {
        //   key: 'lastLogin',
        //   label: 'Letzte Anmeldung',
        //   sortable: true,
        // },
        // {
        //   key: 'numSticker',
        //   label: 'Sticker-Kompositionen',
        //   sortable: true,
        // },
        // {
        //   key: 'numHexagon',
        //   label: 'Einzelsticker',
        //   sortable: true,
        // },
        // {
        //   key: 'numImages',
        //   label: 'Eigene Bilder',
        //   sortable: true,
        // },
        {
          key: 'isAdmin',
          label: 'Administrator',
          sortable: true,
        },
      ],
      items: [],
    }
  }
  ,

  methods: {
    loadUsers() {
      // console.log("Load users");
      HTTP.get("restricted/users", {}, {responseType: "json"})
          .then((response) => {
            // console.log("got users", response.data);

            const dateOptions = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

            this.items = [];

            for (let i = 0; i < response.data.length; i++) {


              let lastLogin = new Date(response.data[i].lastLogin);
              // console.log("Load users lastLogin:", lastLogin);
              let lastLoginStr = lastLogin.toLocaleTimeString("de-DE", dateOptions);
              // console.log("Load users lastLoginStr:", lastLoginStr);

              let row = {
                'email': response.data[i].email,
                'lastLogin': lastLoginStr,
                'numSticker': response.data[i].numberOfSticker,
                'numHexagon': response.data[i].numberOfHexagons,
                'numImages': response.data[i].numberOfImages,
                'isAdmin': response.data[i].isAdmin,
              }
              this.items.push(row)
            }
          })
          .catch(() => {
            // console.log("Error loading users");
          });
    },

    handleIsAdmin(event, email, dbState) {
      // console.log("[handleIsAdmin]", event, email, dbState);

      if (dbState === "disabled") {
        return;
      }

      if (event === true) {
        // console.log("[handleIsAdmin] set admin for:", email);

        // HTTP.patch('restricted/user/admin/' + email).then((response) => {
        HTTP.patch('restricted/user/admin/' + email).then(() => {
          // console.log('[handleIsAdmin] set admin ', email, response.status)
        }).catch(() => {
          console.log("Error setting admin flag");
        });
      } else {
        // console.log("[handleIsAdmin] clear admin for:", email);

        // HTTP.delete('restricted/user/admin/' + email).then((response) => {
        HTTP.delete('restricted/user/admin/' + email).then(() => {
          // console.log('[handleIsAdmin] delete admin ', email, response.status)
        }).catch(() => {
          console.log("Error deleting admin flag");
        });
      }
    },

    // handleLoadConfirmLog(email) {
      // console.log("[handleLoadConfirmLog]", email);

      // if (email) { return; }

    // andleLoadConfirmLog() {
    //   return;
    //
    //   // this.showConfirmationLog = !this.showConfirmationLog;
    // }

  },

  mounted() {
    this.loadUsers();
  },

}
</script>

<style scoped>

.user-table {
  margin: 0px;
  padding: 0px;
  /*background-color: #4d3590;*/
}

.table-link {
  text-transform: none;
  text-decoration: none;
  color: black;
}

</style>
