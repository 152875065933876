<template>

  <div ref="sticker" class="sticker" :style="titleStyle">
    <div class="sticker-container" id="stickerContainer">
      <div ref="col1" class="sticker-col left-three">
        <div class="tileColumn" v-for="(r) in 4" :key="r">
          <my-tile :ref="'tile_1_'+r" col="1" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col2" class="sticker-col left-two">
        <div class="tileColumn" v-for="(r) in 5" :key="r">
          <my-tile :ref="'tile_2_'+r" col="2" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col3" class="sticker-col left-one">
        <div class="tileColumn" v-for="(r) in 6
    " :key="r">
          <my-tile :ref="'tile_3_'+r" col="3" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col4" class="sticker-col center">
        <div class="tileColumn" v-for="(r) in 7
    " :key="r">
          <my-tile :ref="'tile_4_'+r" col="4" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col5" class="sticker-col right-one">
        <div class="tileColumn" v-for="(r) in 6
    " :key="r">
          <my-tile :ref="'tile_5_'+r" col="5" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col6" class="sticker-col right-two">
        <div class="tileColumn" v-for="(r) in 5
    " :key="r">
          <my-tile :ref="'tile_6_'+r" col="6" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
      <div ref="col7" class="sticker-col right-three">
        <div class="tileColumn" v-for="(r) in 4
    " :key="r">
          <my-tile :ref="'tile_7_'+r" col="7" :row=r :xOffset="xOffset" :yOffset="yOffset" :scaleFactor="scaleFactor"
                   :tileVisible="tileVisible" :refresh="refresh" :stickerFits="stickerFits"
                   @showEditor="handleShowEditor" @loadSticker="handleLoadSticker" @dragEvent="handleDragEvent"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyTile from "@/components/MyTile";

export default {
  name: "MyTiles",
  components: {MyTile},
  data() {
    return {
      xOffset: 0,
      yOffset: 0,
      scaleFactor: 1,
      isPreview: false,
      tileVisible: true,
      refresh: false,
      stickerFits: false,
      stickerWidth: 1000,
      stickerHeight: 800,
      lastEnterRow: -1,
      lastEnterCol: -1,
    }
  },
  methods: {
    handleShowEditor(value) {
      // console.log("[Sticker] show Editor");
      this.$emit('showEditor', value);
    },

    handleLoadSticker(value) {
      // console.log("[Sticker] load Sticker");
      this.$emit('loadSticker', value);
    },

    handleWindowResize() {
      this.doResize();
    },

    hideUnusedTiles(hide) {
      this.tileVisible = !hide;
      this.doResize();
    },

    refreshSticker() {
      // console.log('refreshSticker');
      this.refresh = !this.refresh;
      this.doResize();
    },

    setScaleAndOffset(usedOnly) {
      // console.log('Scale and Offset', this.$refs);
      const parentWidth = this.$refs.sticker.parentNode.clientWidth;
      const parentHeight = this.$refs.sticker.parentNode.clientHeight;
      this.stickerWidth = parentWidth;
      this.stickerHeight = parentHeight;
      let sz = null;
      if (usedOnly) {
        sz = this.$store.getters.getUsedSize(1, false);
      } else {
        sz = this.$store.getters.getStickerSize(1);
      }
      let xFactor = parentWidth / (sz.width + 10);
      let yFactor = parentHeight / (sz.height + 10);
      if (xFactor < yFactor) {
        this.scaleFactor = xFactor;
      } else {
        this.scaleFactor = yFactor;
      }
      if (usedOnly) {
        sz = this.$store.getters.getUsedSize(this.scaleFactor, false);
        this.xOffset = parseInt((parentWidth - sz.width) / 2) - sz.x;
        this.yOffset = parseInt((parentHeight - sz.height) / 2) - sz.y;
      } else {
        sz = this.$store.getters.getStickerSize(this.scaleFactor);
        this.xOffset = parseInt((parentWidth - sz.width) / 2);
        this.yOffset = parseInt((parentHeight - sz.height) / 2);
      }

    },

    doResize() {
      // console.log('doResize::setScaleAndOffset', this.isPreview);
      this.setScaleAndOffset(this.isPreview);
      // console.log('doResize::setScaleAndOffset --> DONE', this.isPreview);
    },

    handleDragEvent(data) {
      // console.log("DDE Drag Event: ", data);
      if (data.start) {
        this.lastEnterRow = data.row;
        this.lastEnterCol = data.col;
        this.stickerFits = this.$store.getters.testExtraFits(data.col, data.row);
        // console.log("DDE fit: ", this.stickerFits);
      } else {
        //stickerFits should not be set to false if we already have a start event for another tile
        if ((this.lastEnterCol === data.col) && (this.lastEnterRow === data.row)) this.stickerFits = false;
      }
      this.refreshSticker();
    }
  },

  created() {
    window.addEventListener("resize", this.handleWindowResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  mounted() {
    this.doResize();
  },

  computed: {
    titleStyle() {
      return 'width:' + this.stickerWidth + 'px;height:' + this.stickerHeight + 'px;';
    },
  },
}
</script>

<style scoped>
.sticker {
  /*overflow: hidden;*/
  background-color: white;
  position: absolute;
  /*left: 0px;*/
  /*top: 0px;*/
  /*width: 958px;*/
  /*height: 618px;*/

  display: block;
  /*background-color: cornflowerblue;*/
}

.sticker-container {
  font-size: 0;
}

.sticker-col {
  /*position: absolute;*/
  display: block;
  /*width: 131px;*/
}

.tileColumn {
  padding: 0;
  margin: 0;
}

</style>