<template>
  <div @dragover="handleDragOver" @dragleave="handleDragLeave" @dragenter="handleDragOver">
    <!--    <drop @dragleave="handleDragLeave"-->
    <!--          @dragover="handleDragOver"-->
    <!--          @drop="handleDrop">-->
    <!--      <div ref="trash" class="trashcan" @dragover="handleDragOver" @dragleave="handleDragLeave" @dragenter="handleDragOver" @drop="handleDrop"></div>-->
    <!--  <div ref="trash" class="trashcan" @dragenter.prevent @dragover.prevent @drop="handleDrop"></div>-->
    <div ref="trash" class="trashcan" @dragenter.prevent @dragover.prevent @drop="handleDrop"></div>
    <!--    </drop>-->
  </div>
</template>

<script>
//import {HTTP} from '@/main'

export default {
  name: "Trash",
  data() {
    return {}
  },
  methods: {
    handleDragLeave(event) {

      const uuid = event.dataTransfer.getData('uuid');

      if (!uuid) {
        return
      }
      this.$refs.trash.style.borderWidth = '0px';
    },

    handleDragOver(event) {
      //console.log('trash drag-over', event);

      const uuid = event.dataTransfer.getData('uuid');

      if (!uuid) {
        return
      }

      this.$refs.trash.style.borderStyle = 'solid';
      this.$refs.trash.style.borderWidth = '2px';
      this.$refs.trash.style.borderColor = 'red';
    },

    handleDrop(event) {
      // console.log('trash drop', event);

      const uuid = event.dataTransfer.getData('uuid');
      const col = event.dataTransfer.getData('col')
      const row = event.dataTransfer.getData('row')

      if (!uuid) {
        return
      }
      if (col) {
        // console.log("remove ", col, '/', row);
        this.$emit("removeFromSticker", {row: row, col: col})
        //this.$emit("deleteImage",uuid.split('.')[0]);
      } else {
        const type = uuid.split('/')[1];
        if ((type == 'userimage') ||
            (type == 'userhexagon') ||
            (type == 'usersticker')) {
          // console.log('Delete image ', uuid);
          this.$emit("deleteImage", uuid.split('.')[0]);
        }
      }
      this.$refs.trash.style.borderWidth = '0px';

      // console.log('trash drag-drop', uuid);
    },

  }
}
</script>

<style scoped>
.trashcan {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 44px;
  width: 40px;
  margin: 0px;
  padding: 0px;
  /*background-image: url('../assets/trashcan.svg');*/
  background-image: url('../assets/delete.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}
</style>