<template>
  <!--  <div class="hexagon-tile"></div>-->
  <!--  <div class="main">-->
  <div
      ref="tile"
      :style="style"
      :draggable="drag"
      class="hexagon"
      @dblclick="handleTileDblClick"
      @dragstart="startDrag"
      @dragend="endDrag"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
  >
    <div
        :style="style_c"
        class="hexagon-child"
        :class="{ hexagonChildDrop : dropTarget }"
        @dragenter.prevent
        @dragover.prevent
        @drop="handleDrop"
    ></div>
  </div>
  <!--  </div>-->
</template>

<script>
import {HTTP} from "@/main";
//import Konva from "konva";

export default {
  name: "MyTile",
  data() {
    return {
      bgimage: "",
      showimage: false,
      col: 0,
      row: 0,
      //canvasImage: null,
      //canvasX: 0,
      //canvasY: 0,
      currentImageUrl: "",
      resp_data: null,
      do_skip: false,
      dropTarget: false,
    };
  },
  props: {
    /*
    tileWidth: {
      type: Number,
      required: true,
    },
    tileHeight: {
      type: Number,
      required: true,
    },
    tileTop: {
      type: Number,
      required: true,
    },
    */
    xOffset: {
      type: Number,
      required: true,
    },
    yOffset: {
      type: Number,
      required: true,
    },
    scaleFactor: {
      type: Number,
      required: true,
    },
    tileVisible: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Boolean,
    },
    stickerFits: {
      type: Boolean,
    },
  },
  methods: {
    handleTileDblClick() {
      // console.log("[Tile] show Editor");
      const url = this.$store.getters.getTile(this.col, this.row);
      this.$emit("showEditor", {
        show: true,
        uuid: url,
        col: this.col,
        row: this.row,
      });
    },

    handleDragLeave(event) {
      const uuid = event.dataTransfer.getData("uuid");

      if (!uuid) {
        return;
      }
      this.$refs.tile.style.borderWidth = "0px";
    },

    handleDragOver(event) {
      // console.log('trash drag-over', event);

      const uuid = event.dataTransfer.getData("uuid");

      if (!uuid) {
        return;
      }

      this.$refs.tile.style.borderStyle = "solid";
      this.$refs.tile.style.borderWidth = "2px";
      this.$refs.tile.style.borderColor = "red";
    },

    async serverLoad(src) {
      if (this.currentImageUrl === src) {
        // console.log("SKIP loading", src);
        this.do_skip = true;
        return this.resp_data;
      }
      const config = {url: src, method: "get", responseType: "blob"};
      const response = await HTTP.request(config);
      // console.log("blob: ", response.data);
      this.currentImageUrl = src;
      this.resp_data = response.data;
      return response.data;
    },

    loadImage(url) {
      //domImage.src = 'restricted/userimage/'+url;
      this.serverLoad(url)
          .then((blob) => {
            if (!this.do_skip) {
              this.bgimage = URL.createObjectURL(blob);
              //const layer = this.$store.getters.getLayer;
              //const x = this.canvasX;
              //const y = this.canvasY;
              //let theTile = this;
              //var self = this;
              //var imageObj = new Image();
              //imageObj.onload = function () {
              //console.log('canvas image loaded');
              //self.canvasImage = new Konva.Image({
              //  x: self.canvasX,
              //  y: self.canvasY,
              //  image: imageObj,
              //  width: 720,
              //  height: 400,
              //});
              // fle ... removed canvas for perf reasons
              // self.$store.getters.getLayer.add(self.canvasImage);
              //};
              //imageObj.src = this.bgimage;
              if (blob) URL.revokeObjectURL(blob);
            }
            this.do_skip = false;
          })
          .catch((err) => {
            console.debug("load error ", url, " :", err);
          });
    },

    handleDrop(event) {
      const data = event.dataTransfer.getData("uuid");
      const type = data.split("/")[1];
      const imageLayer = parseInt(event.dataTransfer.getData("imageLayer"));
      // console.log("[MyTile] Dropped image layer:", imageLayer);
//      this.stickerFits = false;
      this.dropTarget = false;
      // console.log("Dropped: ", data);
      if (type === "userimage") {
        this.$store.commit("setDefault");
        this.$store.commit("setImageData", {
          which: 2,
          x: 0,
          y: 0,
          scaleX: 0,
          scaleY: 0,
          visible: true,
          src: data,
        });
        this.$emit("showEditor", {
          show: true,
          uuid: "new",
          col: this.col,
          row: this.row,
        });
      } else if (type === "theme") {
        this.$store.commit("setDefault");
        this.$store.commit("setImageData", {
          which: 1,
          x: 0,
          y: 0,
          scaleX: 0.0,
          scaleY: 0.0,
          visible: true,
          src: data,
        });
        this.$emit("showEditor", {
          show: true,
          uuid: "new",
          col: this.col,
          row: this.row,
        });
      } else if ((imageLayer > 0) && (imageLayer < 3)) {
        this.$store.commit("setDefault");
        this.$store.commit("setBackground", {bgColor: 1, bgOpacity: 100});
        this.$store.commit("setImageData", {
          which: imageLayer,
          x: 0,
          y: 0,
          scaleX: 0.0,
          scaleY: 0.0,
          visible: true,
          src: data,
        });
        this.$emit("showEditor", {
          show: true,
          uuid: "new",
          col: this.col,
          row: this.row,
        });
      } else if (type === "textfield") {
        this.$store.commit("setDefault");
        const mode = parseInt(data.split("_")[1]);
        this.$store.commit("setTextmode", mode);
        this.$emit("showEditor", {
          show: true,
          uuid: "new",
          col: this.col,
          row: this.row,
        });
      } else if (type === "usersticker") {
        const uuid = data.split("/")[2].split(".")[0];
        // console.log("Load Sticker", uuid);
        this.$emit("loadSticker", {uuid: uuid, col: this.col, row: this.row});
      } else if (type === "userhexagon") {
        this.showimage = true;
        this.copyHexagon(data).then((uri) => {
          this.$store.commit("setTile", {
            row: this.row,
            col: this.col,
            src: uri,
          });
          this.$store.commit("addTempAHex", uri);
          // console.log("tile drop", data, uri, event);
          this.loadImage(data);
          window.dragsuccess = true;
        });
      } else {
        this.showimage = true;
        this.$store.commit("setTile", {
          row: this.row,
          col: this.col,
          src: data,
        });
        // console.log("tile drop", data, event);
        this.loadImage(data);
        window.dragsuccess = true;
      }
    },

    async copyHexagon(srcfile) {
      const config = {
        url: srcfile.split(".")[0] + "/userstickerhexagon",
        method: "post",
        responseType: "text",
      };
      const response = await HTTP.request(config);
      // console.log("new Id: ", response.data);
      return "restricted/userstickerhexagon/" + response.data.stickerHexagon + ".png";
    },

    startDrag(evt) {
      const url = this.$store.getters.getTile(this.col, this.row);
      // console.log("start drag", url, evt);
      //this.showimage = false;
      if (url) {
        evt.dataTransfer.setData("uuid", url);
        evt.dataTransfer.setData("row", this.row);
        evt.dataTransfer.setData("col", this.col);
        // console.log("start drag", evt.dataTransfer.getData("uuid"));
        window.dragsuccess = false;
        window.whatIsDragged = url;
      } else {
        evt.preventDefault();
      }
    },

    endDrag() {
      // console.log("Clear image: ");
      if (window.dragsuccess) {
        this.showimage = false;
        this.bgimage = "";
        this.currentImageUrl = "";
        this.$store.commit("setTile", {
          row: this.row,
          col: this.col,
          src: "",
        });
        this.dropTarget = false;
        //if (this.canvasImage) this.canvasImage.destroy();
      }
    },
    dragEnter() {
      // console.log('Drag enter');
      this.dropTarget = true;
      this.$emit("dragEvent", {col: this.col - 1, row: this.row - 1, start: true});
    },
    dragLeave() {
      // console.log('Drag leave');
      this.dropTarget = false;
      this.$emit("dragEvent", {col: this.col - 1, row: this.row - 1, start: false});
    },
  },
  mounted() {
    const a = this.$attrs;
    this.row = a.row;
    this.col = a.col;
    /*
    const pos = this.$store.getters.getHexagonPos(this.col, this.row, 1);
    this.canvasX = pos.x;
    this.canvasY = pos.y;
    */
    const src = this.$store.getters.getTile(this.col, this.row);
    if (src !== "") {
      // console.log("show");
      this.showimage = true;
      this.loadImage(src);
    }
  },
  computed: {
    style() {
      let opacity = 0.5;
      let visibility = "visible";
      if (!this.tileVisible && !this.showimage) visibility = "hidden";
      if (this.showimage) opacity = 1;
      const pos = this.$store.getters.getHexagonPos(
          this.col,
          this.row,
          this.scaleFactor
      );
      const top = pos.y + this.yOffset;
      const left = pos.x + this.xOffset;
      return (
          "width:" +
          pos.width +
          "px; height:" +
          pos.height +
          "px;top:" +
          top +
          "px;left:" +
          left +
          "px;opacity:" +
          opacity +
          ";background-image: url(" +
          this.bgimage +
          ");" +
          "visibility:" +
          visibility +
          ";"
      );
    },
    style_c() {
      let visibility = "visible";
      if (this.showimage || !this.tileVisible) visibility = "hidden";
      return "visibility:" + visibility + ";";
    },
    drag() {
      let draggable = "false";
      if (this.showimage) draggable = "true";
      return draggable;
    },
  },
  watch: {
    refresh: {
      handler: function () {
        const uuid = this.$store.getters.getTile(this.col, this.row);
        if (uuid !== "") {
          // console.log("refresh tile", uuid, " - ", this.col, "/", this.row, this.stickerFits);
          this.showimage = true;
          this.loadImage(uuid);
        } else {
          this.showimage = false;
          this.bgimage = "";
          this.currentImageUrl = "";
          this.resp_data = null;
          this.do_skip = false;
          //if (this.canvasImage) this.canvasImage.destroy();
        }
        if ((window.whatIsDragged) && (window.whatIsDragged.split('/'[1]) === "usersticker")) {
          this.dropTarget = this.stickerFits && (this.$store.getters.getMergeTile(this.col, this.row) !== '');
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.hexagon {
  /*top: 88px;*/
  position: absolute;
  opacity: 0.5;

  /*box-shadow: 10px 10px 10px 10px rgba(0,0,0,0.9);*/
  content: "";
  width: 168px;
  height: 86px;
  display: inline-block;
  font-size: initial;
  /*clip-path: polygon(23% 0%, 78% 0%, 100% 50%, 78% 100%, 23% 100%, 1% 50%);*/
  clip-path: polygon(22.5% 0%, 77.5% 0%, 100% 50%, 77.5% 100%, 22.5% 100%, 0% 50%);
  background-size: cover;
  /*border: 5px dot-dot-dash greenyellow;*/
  /*background-color: forestgreen;*/
}

.hexagon-child {
  content: "hexgon-child";
  background-color: #c2bab5;
  width: 100%;
  height: 100%;
  position: absolute;
  /*border: 5px dot-dot-dash greenyellow;*/
}

/*.hexagon-child::before {*/
/*  content: "";*/
/*  box-shadow: inset 3px 0px 4px 6px rgba(0, 0, 0, 1);*/
/*  background-color: gray;*/
/*  width: 130%;*/
/*  !*height: 103%;*!*/
/*  !*left: ;*!*/
/*  !*top: 0px;*!*/
/*  !*position: relative;*!*/
/*  transform: skewX(321deg);*/
/*  !*opacity: .3;*!*/
/*  height: 130%;*/
/*  left: -6%;*/
/*  position: absolute;*/
/*}*/

.hexagonChildDrop::before {
  background-color: blue;
}

.hexagonChildDrop {
  background-color: #fad6c7;
}

/*.hexagon-child::after {*/
/*  content: "";*/
/*  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 1);*/
/*  !*background-color: orange;*!*/
/*  !*width: 100%;*!*/
/*  !*height: 103%;*!*/
/*  !*left: ;*!*/
/*  !*top: 0px;*!*/
/*  !*position: relative;*!*/
/*  height: 50%;*/
/*  left: 89%;*/
/*  transform: skewX(39deg);*/
/*  z-index: 2;*/
/*  position: absolute;*/
/*}*/
</style>